import React from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import Navbar from '../CommonComponents/Navs/Navbar'
import SideNav from '../CommonComponents/Navs/SideNav'
import DashboardHead from './DashboardHead'
import DashboardContent from './DashboardContent'
import DashboardAbout from './DashboardAbout'
import Webinar from './Webinar'
import Footer from '../CommonComponents/Footer/Footer'

const Dashboard = () => { 
    return (
        <>
            <TitleBar title="Dashboard" />  
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">
                        <DashboardHead />
                        <DashboardContent />
                        <DashboardAbout />
                        <Webinar />
                    </div>
                </div>


            </section>

            <Footer />
        </>
    )
}

export default Dashboard