import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { FiSearch } from 'react-icons/fi'
import { FiCrop } from 'react-icons/fi'
import { useDispatch } from 'react-redux';
import { onFetchMedia } from '../../Redux/Actions/CommonActions';
import { useState } from 'react';
import HoverVideoPlayer from "react-hover-video-player";
import { GiCheckMark } from 'react-icons/gi';
import CropModal from './CropModal';
import { BiSearch } from 'react-icons/bi';
import { FaSpinner } from 'react-icons/fa';

const Library = ({ handleBackground, sType, type, checkMedia, setCheckMedia }) => {
    const dispatch = useDispatch()
    const [images, setImages] = useState([])
    const [error, setError] = useState("")
    const [cropData, setCropData] = useState({
        current: false,
        cropped: false
    })
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: false,
    })
    const [search, setSearch] = useState({
        key: "",
        page_number: 0,
    })
    const handleChange = (e) => {
        setSearch({
            ...search,
            key: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setImages([])
        setError("")
        fetchImages()
    }

    const handleSelectImage = (data) => {
        handleBackground(data, sType)
        setCheckMedia(data.url)
    }
    const fetchImages = (page = 1) => {
        let data = {
            keyword: search.key,
            page_number: page,
            search_type: sType
        }
        setSearch({
            ...search,
            page_number: page
        })
        setLoader({
            ...loader,
            fetch: true
        })
        dispatch(onFetchMedia("load-library-images", data, images, setImages, loader, setLoader, setError))
    }

    const selectCrop = (e, url) => {
        e.stopPropagation()
        handleShow();
        setCropData({
            ...cropData,
            current: url,
            cropped: false
        })
    }


    return (
        <div className="innertab">
            <form className="tabSearch" onSubmit={(e) => handleSubmit(e)}>
                <input
                    type="text"
                    placeholder="Enter keyword to search media"
                    onChange={handleChange}
                    value={search.key}
                    required
                />
                <span
                    className="inp-icon"
                ><i class="fa fa-search" aria-hidden="true"></i></span>
            </form>
            <div className="innertab-scroll myinnertab-scroll" id={`bgImagesScroll-${type}-${sType}`}>
                <div className="row">
                    <div className="mediaList">
                        <ul>
                            <InfiniteScroll
                                dataLength={images.length}
                                hasMore={true}
                                next={() => fetchImages(search.page_number + 1)}
                                scrollableTarget={`bgImagesScroll-${type}-${sType}`}
                            >
                                <div className="media-ul-list" style={{ height: "auto" }}>
                                    {images.length > 0 ?
                                        images.map((curElem, index) => {
                                            return (

                                                <li className='col-md-6 myImage-section cursor-pointer' key={index} onClick={() => handleSelectImage(curElem, index)}>
                                                    <div className="mediaList-single myImage-respo m-1">
                                                        {sType === "images" ?
                                                            <img className="mediaImg" src={curElem.thumbnail} style={{ objectFit: "contain" }} />
                                                            :
                                                            <>
                                                                <HoverVideoPlayer
                                                                    style={{ height: '100%' }}
                                                                    videoSrc={curElem.url}
                                                                    // pausedOverlay={
                                                                    //     <img
                                                                    //         src={curElem.thumbnail}
                                                                    //         alt={`${search.keyword + ' ' + index}`}
                                                                    //         style={{ padding: 2 }}
                                                                    //     />
                                                                    // }
                                                                    loadingOverlay={
                                                                        <div style={{ color: "#00c4a6", display: 'flex', alignItems: "center", justifyContent: "center", height: "100%" }}>
                                                                            <i className="fa fa-spinner fa-spin" style={{ fontSize: 20 }} />
                                                                        </div>
                                                                    }
                                                                />
                                                            </>
                                                        }
                                                        {/* <img className="mediaImg" src={curElem.thumbnail} alt={`${search.keyword + " " + index}`} /> */}
                                                    </div>
                                                    {checkMedia === curElem.url ?
                                                        <div className="slide-active">
                                                            <i className="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        : ''
                                                    }
                                                    <div className="crop-icon" onClick={(e) => selectCrop(e, curElem.url)}>
                                                        <i className="fa fa-crop"></i>
                                                    </div>
                                                </li>


                                            )
                                        })
                                        :
                                        ""

                                    }
                                </div>
                                {
                                    loader.fetch ?
                                        <div className="col-md-12">
                                            <h4 className='text-center'>
                                                <i className="fa fa-spinner fa-spin" />
                                            </h4>
                                        </div>
                                        : ''
                                }
                                {
                                    error ?
                                        <div className="col-md-12">
                                            <h6 className='text-center'>{error}</h6>
                                        </div>
                                        : ''
                                }
                            </InfiniteScroll>
                        </ul>
                    </div>
                </div>
            </div>
            <CropModal
                show={show}
                handleClose={handleClose}
                type={sType}
                cropData={cropData}
                setCropData={setCropData}
                handleSelectImage={handleSelectImage}
            />
        </div >
    )
}

export default Library