import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addSlideDuration, muteVideoAudio, unMuteVideoAudio } from "../../../Redux/Actions/VideoActions";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import LogoWatermark from "./LogoWatermark";
import VolumeControl from "./VolumeControl";
import Prompt from "./Prompt";

const Setting = () => {

  const dispatch = useDispatch()
  const campaign = useSelector(state => state.video.data)
  const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
  const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
  const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)

  const logo = campaign.logo
  const watermark = campaign.watermark

  const addDuration = (e) => {
    let num = e.target.value
    if (num > 300) {
      dispatch(setAlert("You can't set slide duration more than 300 sec.", "warning"))
      dispatch(addSlideDuration(300))
    } else {
      dispatch(addSlideDuration(num))
    }
  }

  const handleMuteVideoLayer = (data) => {
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
    if (data.mute === false) {
      dispatch(muteVideoAudio(selectedSlideIndex, selectedLayerIndex))
    }
  }

  function hasAudio(video) {
    return video.mozHasAudio ||
      Boolean(video.webkitAudioDecodedByteCount) ||
      Boolean(video.audioTracks && video.audioTracks.length);
  }

  const handleUnMuteVideoLayer = (selectedLayer) => {
    let video = document.querySelector('#checkAudioData');
    video.play()
    video.muted = true
    setTimeout(() => {
      let data = hasAudio(video)
      if (data) {
        const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)
        if (selectedLayer.mute) {
          dispatch(unMuteVideoAudio(selectedSlideIndex, selectedLayerIndex))
        }
      } else {
        dispatch(setAlert("This video does not have an audio", "danger"))
      }
      video.pause()
    }, 1000)
  }

  return (
    <>
      <div className="tabInner">
        <div className="media-block">
          <LogoWatermark
            type={"logo"}
            data={logo}
          />

          <LogoWatermark
            type={"watermark"}
            data={watermark}
          />
          <div className="add-block-single">
            <div className="add-block-main">
              <div className="add-block-title mb-4">
                <h2>Duration (Sec)</h2>
              </div>
              <input
                className="inPut"
                type="number"
                name="duration"
                min={1}
                value={selectedSlide.duration}
                onChange={(e) => addDuration(e)}
              />
            </div>
          </div>

          <div className="add-block-single">
            <VolumeControl />
          </div>


          <Prompt
            promo={campaign.promo}
          />
        </div>
      </div>
    </>
  );
};


export default Setting;
