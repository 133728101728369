import React, { useState, useEffect } from 'react'
import { MdOutlineChevronLeft, MdOutlineChevronRight } from 'react-icons/md';

const Pagination = ({ listData, pagination, setPagination, currentTodo, loader, listArr }) => {
  const [pageNumber, setPageNumber] = useState([])

  // let len = Math.ceil(listData.length/pagination.totalItemOnPage);

  const handleClick = (num) => {
    setPagination({
      ...pagination,
      currentPage: num,
    });
  };
  const handleBackward = (num) => {
    if (pageNumber[0] < num) {
      setPagination({
        ...pagination,
        currentPage: num - 1,
      });
    }
  };
  const handleForward = (num) => {
    if (pageNumber[pageNumber.length - 1] > num) {
      setPagination({
        ...pagination,
        currentPage: num + 1,
      });
    }
  };
  useEffect(() => {
    if (listData.length >= 0) {
      let maxLength = listData.length;
      let data = [];
      if (maxLength > 10) {
        let val = maxLength / 10;
        if (val > parseInt(val)) {
          val = val + 1;
        }
        for (let i = 1; i <= val; i++) {
          data.push(i);
        }
        setPageNumber(data);
      } else {
        setPageNumber([1]);
      }
    }
  }, [listData])

  return (
    <>
      {loader === true ? "" :
        listArr.length === 0 ? "" : currentTodo.length !== 0 ? ""
          :
          <div className="text-center mt-4 text-capitalize text-dark" >
            No Data Found
          </div>
      }

      <div className='d-flex align-items-center justify-content-end mt-2'>
        <div className="table-nav">
          <ul>
            <li
              onClick={() => handleBackward(pagination.currentPage)}
              className={pageNumber.length <= 1 ? "not-allowed" : ""}
              style={{ cursor: "pointer" }}
            >
              <a>
                <MdOutlineChevronLeft className='nxt'
                />
              </a>
            </li>

            {pageNumber.length > 0 ?
              pageNumber.map((curElem, index) => {
                return (
                  <li
                    key={index}
                    id={curElem}
                    className={pagination.currentPage === curElem ? 'active' : ""}
                    onClick={() => handleClick(curElem)}
                  >
                    <a
                      style={curElem === pagination.currentPage ? { color: "#fff" } : {}}
                    >
                      {curElem}
                    </a>
                  </li>
                );
              })
              : ""}
            <li
              onClick={() => handleForward(pagination.currentPage)}
              className={pageNumber.length <= 1 ? "not-allowed" : ""}
              style={{ cursor: "pointer" }}
            >
              <a>
                <MdOutlineChevronRight className='nxt' style={{ color: pageNumber.length <= 1 ? "gray" : "white" }} />
              </a>
            </li>
          </ul>
        </div>
        </div>
      </>
  )
}

export default Pagination