import { combineReducers } from "redux";
import { AlertReducer } from "./AlertReducer";
import { AuthReducer } from "./AuthReducer";
import { RebrandingReducer } from "./RebrandingReducer";
import { HelpReducer } from "./HelpReducer";
import { SocialReducer } from "./SocialReducer";
import { ExtraReducer } from "./ExtraReducer";
import { CourseReducer } from "./CourseReducer";
import { VideoReducer } from "./VideoReducer";
import { EditorReducer } from "./EditorReducer";
import { QuizReducer } from "./QuizReducer";
import { BonusReducer } from "./BonusReducer";

const appReducer = combineReducers({
    alert: AlertReducer,
    auth: AuthReducer,
    rebrand: RebrandingReducer,
    help: HelpReducer,
    social: SocialReducer,
    extra: ExtraReducer,
    course: CourseReducer,
    video: VideoReducer,
    editor: EditorReducer,
    quiz: QuizReducer,
    bonus: BonusReducer,

})

const rootReducers = (state, action) => {
    if (action.type === 'LOGOUT') {
        localStorage.clear();
        state = undefined
    }
    return appReducer(state, action)
}

export default rootReducers;