import React, { useState } from 'react'
import { Modal, Nav, Tab } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import HoverVideoPlayer from 'react-hover-video-player';
import { Trash } from 'iconsax-react';
import { IoNavigateOutline } from "react-icons/io5";
import CommonAlert from '../../CommonComponents/CommonAlert';
import { useSelector } from 'react-redux';
import { themeColor } from '../../../Global/Global';
import { useDispatch } from 'react-redux';
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { updateIntroOutro } from '../../../Redux/Actions/VideoActions';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { useNavigate } from 'react-router';

const VideoReelModal = ({ show, handleClose, type, introArr, videoReel, fetchDataModal }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const social = useSelector(state => state.social)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })
    const [sweet1, setSweet1] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Redirect",
        loader: false,
    })

    const onDelete = (e, id) => {
        e.stopPropagation()
        setSweet({
            ...sweet,
            enable: true,
            id: id,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
            message: ""
        })
    }

    const onCancelRedirect = () => {
        setSweet1({
            ...sweet1,
            enable: false,
            id: false,
            confirmButtonName: "Redirect",
            loader: false,
        })
    }


    const performDelete = () => {
        const formData = new FormData()
        formData.append("id", sweet.id)
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-user-uploaded-file", formData, false, false, setSweet, fetchDataModal))
    }

    const performRedirect = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        navigate('/integration')
    }


    const selectIntro = (curElem, vType) => {
        let data = {
            url: '',
            nameData: '',
            thumbData: ''
        }
        if (vType === "videoReel") {
            data.url = curElem.prview_video_url
            data.nameData = curElem.project_name
            data.thumbData = curElem.preview_image_url
        }
        else {
            data.url = curElem.url
            data.nameData = curElem.name
            data.thumbData = curElem.poster
        }

        handleClose()
        if (type === "intro") {
            dispatch(updateIntroOutro('UPDATE_INTRO', data))
        } else {
            dispatch(updateIntroOutro('UPDATE_OUTRO', data))
        }

        dispatch(setAlert(`${type === "intro" ? "Intro" : "Outro"} applied successfully`, "success"))
    }

    const connectVideoReel = () => {
        setSweet1({
            ...sweet1,
            enable: true,
            confirmButtonName: "Redirect",
        })
    }

    return (

        <Modal className="VideoModal white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="tabInner">
                            <Tab.Container id="" defaultActiveKey="intro-video">
                                <Nav variant="pills" className="inTabNav full credit-tab " style={{ width: "100%", height: "100%" }}>
                                    <Nav.Item>
                                        <Nav.Link eventKey="intro-video" style={{ fontSize: '15px' }}>Your uploaded {type} videos</Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="Videoreel-video" style={{ fontSize: '15px' }}>Videoreel {type} videos</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="intro-video">
                                        <div className="innertab credit-modal" style={{ paddingRight: '20px' }}>
                                            <div className="row">
                                                {introArr.length > 0 ?
                                                    introArr.map((curElem) => {
                                                        return (
                                                            <div
                                                                className="col-md-4 col-6"
                                                                key={curElem.id}
                                                                onClick={() => selectIntro(curElem, "intro")}
                                                            >
                                                                <div className="credit-video-card" style={{
                                                                    border: "none",
                                                                }}>
                                                                    <div>
                                                                        <>
                                                                            <HoverVideoPlayer
                                                                                className="my-1 cursor-pointer "
                                                                                videoSrc={curElem.url}
                                                                                pausedOverlay={
                                                                                    <img src={curElem.poster} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                                }
                                                                                loadingOverlay={
                                                                                    <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                                                }
                                                                            />
                                                                            <Trash
                                                                                className='remove-media'
                                                                                style={{ color: themeColor }}
                                                                                onClick={(e) => onDelete(e, curElem.id)}
                                                                            />
                                                                        </>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <div className='text-center' style={{ fontSize: 20 }}>
                                                        You don't have uploaded {type} videos
                                                    </div>}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>

                                <Tab.Content>
                                    <Tab.Pane eventKey="Videoreel-video">
                                        <div className="innertab  credit-modal" style={{ paddingRight: '20px' }}>
                                            <div className="row gy-4">
                                                {videoReel.length > 0 ?
                                                    videoReel.map((curElem, index) => {
                                                        if (curElem.category === type) {
                                                            return (
                                                                <div
                                                                    className="col-4 ps-2 pe-2"
                                                                    key={index}
                                                                    onClick={() => selectIntro(curElem, "videoReel")}
                                                                >
                                                                    <div className="credit-video-card mt-2" style={{
                                                                        border: "none",
                                                                    }}>
                                                                        <HoverVideoPlayer
                                                                            className="cursor-pointer "
                                                                            videoSrc={curElem.prview_video_url}
                                                                            pausedOverlay={
                                                                                <img src={curElem.preview_image_url} alt="" width="100%" height="100%" style={{ objectFit: "cover" }} />
                                                                            }
                                                                            loadingOverlay={
                                                                                <div><i className="fa fa-spinner fa-spin hover-loader-center" /></div>
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                    :
                                                    <div className='text-center' >
                                                        {social.videoreel.length > 0 ?
                                                            <div>
                                                                You haven't created your VideoReel {type} Videos yet
                                                            </div>
                                                            : <div className="alert alert-warning" role="alert" style={{ width: "80%", margin: "0 auto" }}>
                                                                You haven't connected your VideoReel account yet.<br />
                                                                <a className="custom-anchor" style={{color:themeColor}} onClick={() => connectVideoReel()}>Click Here</a> to connect and use those VideoReel {type} Videos
                                                            </div>}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>

                <CommonAlert
                    show={sweet.enable}
                    message={`Do you want to delete this ${type} video?`}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                    alertLoader={sweet.loader}
                    icon={<Trash />}
                />

                <CommonAlert
                    show={sweet1.enable}
                    message={"You will be redirected to the integration page. Are you sure??"}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet1.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelRedirect}
                    performDelete={performRedirect}
                    alertLoader={sweet1.loader}
                    icon={<IoNavigateOutline />}
                />
            </Modal.Body>
        </Modal>

    )
}

export default VideoReelModal