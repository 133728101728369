import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchCamapign = (data, setData, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData(res.data)
            }
            if (setLoader) {
                setLoader(false)
            }
        }).catch((err) => {
            console.log(err)
            if (setLoader) {
                setLoader(false)
            }
        })
}

export const onPlatform = (setState) => (dispatch, getState) => {
    commonAxios("fetch-platform", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}
export const onCountCampaign = (setCount) => (dispatch, getState) => {
    commonAxios("count-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            console.log(res, "jhfvd")
            if (res.status) {
                setCount(res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}


export const onCreateCampaign = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects/knowledge-brain?cid=${res.data.id}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUpdateCampaign = (data) => (dispatch, getState) => {
    commonAxios("update-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onWriteDoc = (data, fetchData, setData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("write-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData({
                    title: "",
                    description: ""
                })
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onFetchProjects = (setProejcts, limit, setLoader) => (dispatch, getState) => {
    commonAxios("get-campaign", {}, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = res.data
                if (limit) {
                    data = data.slice(0, limit)
                }
                let mergerArray = []
                data.map((curElem) => {
                    mergerArray.push(...curElem.video)
                })
                setProejcts(mergerArray)
            } else {
                setProejcts([])
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onMergingVideo = (data, navigate, setLoader) => (dispatch, getState) => {
    commonAxios("create-merge", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/projects?d=${data.ratio === "16:9" ? "hz" : "vc"}&p=${2}`)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)

        })
}

export const onDeleteKnowledgeTableRow = (data, fetchDoc, sweet, setSweet) => (dispatch, getState) => {
    commonAxios("delete-resources", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchDoc()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                ...sweet,
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            setSweet({
                ...sweet,
                enable: false,
                confirmButtonName: "Delete",
                loader: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onRowDataFetch = (ep, data, setDocData, type, loader, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let obj = {}
                obj.id = res.data[0].id
                if (type === "w") {
                    obj.text = res.data[0].description
                    obj.title = res.data[0].title
                } else if (type === "i") {
                    obj.text = res.data[0].data
                    obj.mediaData = res.data[0].mediaData ? res.data[0].mediaData : []
                    obj.error = res.data[0].errorMessage
                    obj.websiteUrl = res.data[0].websiteUrl
                    obj.type = res.data[0].type
                } else if (type === "u" || type === "m") {
                    obj.text = res.data[0].data
                }
                setDocData(obj)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUpdateDocs = (ep, data, loader, setLoader) => (dispatch, getState) => {
    commonAxios(ep, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetch: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onImportDoc = (data, fetchData, setData, loader, setLoader, interval) => (dispatch, getState) => {
    commonAxios("import-campaign", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setData({
                    url: ""
                })
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                create: false
            })
            clearInterval(interval)
        }).catch((err) => {
            setLoader({
                ...loader,
                create: false
            })
            dispatch(setAlert(err.msg, "danger"))
            clearInterval(interval)
            console.log(err)
        })
}

export const onRegenrateScript = (data, fetchRowDoc, loader, setLoader, interval) => (dispatch, getState) => {
    commonAxios("regenerate-import-url", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchRowDoc()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                regenrate: false
            })
            clearInterval(interval)
        }).catch((err) => {
            setLoader({
                ...loader,
                regenrate: false
            })
            clearInterval(interval)
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}



export const onUploadDocs = (data, cId, type, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = {
                    url: res.data.path,
                    cId: cId,
                    type: type
                }
                dispatch(onWriteUploadDoc(data, fetchData, loader, setLoader))

            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    upload: false
                })
            }

        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onWriteUploadDoc = (data, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("write-document", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onUploadCreateMedia = (data, obj, handleClose, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let data = {
                    url: res.data.path,
                    cId: obj.cId,
                    thumbnail: res.data.thumbnail,
                    type: res.data.type,
                    isExtract: obj.isExtract,
                    code: obj.code
                }
                dispatch(onCreateMedia(data, handleClose, fetchData, loader, setLoader))

            } else {
                dispatch(setAlert(res.msg, "danger"))
                setLoader({
                    ...loader,
                    upload: false
                })
            }

        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onCreateMedia = (data, handleClose, fetchData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-media", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                handleClose()
                fetchData()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                upload: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                upload: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onCheckTranscribe = (data, fetchData, interval) => (dispatch, getState) => {
    commonAxios("check-transcribe", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (+res.data.tStatus === 3 || +res.data.tStatus === 1) {
                    fetchData()
                    clearInterval(interval)
                }
            }
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onCreateNew = (url, data, fetchData, handleClose, setLoader) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fetchData()
                handleClose()
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}


export const onCheckStatus = (data, common, setCommon, interval) => (dispatch, getState) => {

    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data.status
                if (statusNum === 2 || statusNum === 3 || statusNum === 4 || statusNum === 6) {
                    clearInterval(interval)
                    let text = ""
                    let redirectUrl = ""
                    let bgColor = ""
                    switch (statusNum) {
                        case 2:
                            text = "Render Successful"
                            redirectUrl = "projects/editor"
                            bgColor = "#008000"
                            break;
                        case 3:
                            text = "Rendering Failed"
                            redirectUrl = "projects/editor"
                            bgColor = "#FF0000"
                            break;
                        case 4:
                            text = "Script Generated"
                            redirectUrl = "projects/update-script"
                            bgColor = "#008000"
                            break;
                        case 6:
                            text = "Awaiting Customization"
                            redirectUrl = "projects/editor"
                            bgColor = "#191970"
                            break;
                        default:
                            text = ""
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        showRendering: false,
                        urlToRedirect: redirectUrl,
                        showProgAnimation: false,
                        thumbUrl: res.data.thumbnailUrl,
                        bgColor: bgColor,
                        failedData: res.data.logFile ? JSON.parse(res.data.logFile) : false
                    })
                }
                else if (statusNum === 9) {
                    setCommon({
                        ...common,
                        statusText: "Rendering",
                        showRendering: true,
                        progress: res.data.renderProgress,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "projects/editor",
                        bgColor: "#DAA520"
                    })
                }
                else if (statusNum === 8) {
                    setCommon({
                        ...common,
                        statusText: res.data.renderProgress,
                        showRendering: false,
                        showProgAnimation: true,
                        isEditabel: true,
                        isDownloadable: res.data.videoUrl,
                        thumbUrl: res.data.thumbnailUrl,
                        urlToRedirect: "projects/editor",
                        bgColor: "#DAA520"
                    })
                }
                else if (statusNum === 5) {
                    if (res.data.slides.length > 0) {
                        setCommon({
                            ...common,
                            statusText: `Generating Slides ${res.data.slides.length}/${+res.data.totalSlides}`,
                            showRendering: false,
                            showProgAnimation: true,
                            isEditabel: true,
                            isDownloadable: res.data.videoUrl,
                            thumbUrl: res.data.thumbnailUrl,
                            urlToRedirect: "projects/editor",
                            bgColor: "#FF7F50",
                            progress: parseInt((res.data.slides.length / +res.data.totalSlides) * 100)

                        })
                    }

                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}

export const onUpdateScript = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-script", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (data.type === "save") {
                    dispatch(setAlert(res.msg, "success"))
                } else {
                    navigate(`/projects/assets?cid=${data.cId}`)
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false,
                save: false
            })
        })
}

export const onFetchScript = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-video", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data.savedScript ? res.data.savedScript : res.data.originalScript)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader(false)
        }).catch((err) => {
            console.log(err)
            setLoader(false)
        })
}


//Bonuses Stuff


export const onFetchLanding = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-landing-page", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_GET_LANDING", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                pageLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                pageLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateLanding = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submitLoader: false
            })
            console.log(err)
        })
}

export const onCheckedAddon = (data, setLoader, setDomain) => (dispatch, getState) => {
    commonAxios("add-domain", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
                setDomain("")
            }

            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            setDomain("")
        })
}

export const onfetchImages = (data, imageList, setImageList, setMsg, loader, setLoader) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let arr = [...imageList]
                setImageList(arr.concat(res.data))
            } else {
                setMsg(res.msg)
            }
            setLoader({
                ...loader,
                fetchLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                fetchLoader: false
            })
        })
}

export const onDeleteImage = (data, fecthData, Swal) => (dispatch, getState) => {
    commonAxios("delete-media-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                fecthData()
            }
            else {
                dispatch(setAlert(res.msg, "danger"))
            }
            Swal.close()
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
            Swal.close()
        })
}

export const onFecthUploadImage = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            } else {
                setState([])
            }
            setLoader({
                ...loader,
                fetchloader: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                fetchloader: false
            })
            console.log(err)
        })
}


export const onUploadImageLibrary = (data, fecthData, loader, setLoader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                fecthData()
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader({
                ...loader,
                imageLoader: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                imageLoader: false
            })
            console.log(err)
        })
}

export const onFetchBlog = (data, setState, setLoader,) => (dispatch, getState) => {
    commonAxios("view-blog", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data[0])
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

export const onUpdateBlog = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-blog", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false
            })
            console.log(err)
        })
}

export const onUpdateEbook = (data, loader, setLoader, isGenerate) => (dispatch, getState) => {
    commonAxios("update-ebook", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (isGenerate) {
                    window.open(data.previewUrl, "_blank")
                } else {
                    dispatch(setAlert(res.msg, "success"))
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                save: false,
                generate: false
            })
            console.log(err)
        })
}




export const onFetchEbook = (data, setState, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-ebook", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data[0])
            } else {
                dispatch(setAlert((res.msg, "danger")))
            }
            setLoader(false)
        })
        .catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader(false)
            console.log(err)
        })
}

// Opt-in Page


export const onFetchOptin = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-landing-page", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "ON_GET_OPTIN", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                pageLoader: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                pageLoader: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateOptin = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-optin", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submitLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submitLoader: false
            })
            console.log(err)
        })
}

// Banner Ads

export const onFetchAds = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-ads-by-id", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch({ type: "SET_BANNER_DATA", payload: res.data[0] })
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                pageLoader: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                pageLoader: false
            })
            console.log(err)
        })
}

export const onSubmitAds = (data, loader, setLoader) => (dispatch, getState) => {
    commonAxios("save-ads", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            dispatch(setAlert(err.msg, "danger"))
            setLoader({
                ...loader,
                submit: false
            })
            console.log(err)
        })
}



// No Axios Here


export const onChangeBenefit = (name, value, index) => (dispatch) => {
    let data = { name, value, index }
    dispatch({ type: "ON_CHANGE_BENEFIT", payload: data })
}

export const onAddBenefit = () => (dispatch) => {
    dispatch({ type: "ON_ADD_BENEFIT" })
}

export const onCancleBenefit = (index) => (dispatch) => {
    dispatch({ type: "ON_CANCLE_BENEFIT", payload: index })
}

export const onCheckedToggle = (name, checked) => (dispatch) => {
    let data = { name, checked }
    dispatch({ type: "ON_CHECKED_TOGGLE", payload: data })
}

export const onChangeBonusCoach = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_BONUS_COACH", payload: data })
}

export const onChangeFooter = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_FOOTER", payload: data })
}

export const onChangeHeader = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_HEADER", payload: data })
}

export const onUpdateHeaderbgType = (value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_HEADER_BG_TYPE", payload: value })
}

export const onUpdateHeaderbgData = (value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_HEADER_BG_DATA", payload: value })
}


export const onChangeTeam = (name, value, index) => (dispatch) => {
    let data = { name, value, index }
    dispatch({ type: "ON_CHANGE_TEAM", payload: data })
}


export const onAddMember = (data) => (dispatch) => {
    dispatch({ type: "ON_ADD_MEMBER", payload: data })
}


export const onUpdateMember = (data, index) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_MEMBER", payload: { data, index } })
}



export const onChangeVideo = (name, value) => (dispatch) => {
    let data = { name, value }
    dispatch({ type: "ON_CHANGE_VIDEO", payload: data })
}
export const onCancleMember = (index) => (dispatch) => {
    dispatch({ type: "ON_CANCEL_MEMBER", payload: index })
}

export const onUpdateFamily = (value) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_FONT_FAMILY", payload: value })
}

export const onUpdatePrimary = (val) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_PRIMARY_COLOR", payload: val })
}

export const onUpdateSecondary = (val, name) => (dispatch) => {
    let data = { val, name }
    dispatch({ type: "ON_UPDATE_SECONDARY_COLOR", payload: data })
}

export const onUpdateButton = (val, name) => (dispatch) => {
    let data = { val, name }
    dispatch({ type: "ON_UPDATE_BUTTON_COLOR", payload: data })
}

export const onChangeBonusColor = (val, name, index) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_BONUS_COLOR", payload: { val, name, index } })
}
export const onUpdateThankyou = (name, value) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_THANKYOU", payload: { name, value } })

}

export const onToggleOptins = (name, value) => (dispatch) => {
    dispatch({ type: "ON_TOGGLE_OPTINS", payload: { name, value } })
}

export const onUpdateOptinStyle = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_STYLE", payload: { name, value } })
}

export const onChangeOptinHeader = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_HEADER", payload: { name, value } })
}

export const onChangeOptinVideo = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_VIDEO", payload: { name, value } })
}

export const onAddAndRemoveHeadline = (type, index) => (dispatch) => {
    dispatch({ type: "ON_ADD_REMOVE_VIDEO_HEADLINES", payload: { index, type } })
}

export const onChangeOptinVideoHeadlines = (value, index) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_HEADLINES", payload: { value, index } })
}

export const onChangeCoach = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_COACH", payload: { name, value } })
}

export const onChangeOptinContact = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_CONTACT", payload: { name, value } })
}
export const onChangeOptinContactStyle = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_CONTACT_STYLE", payload: { name, value } })
}
export const onChangeOptinContactButton = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_CONTACT_BUTTON", payload: { name, value } })
}

export const onUpdateSocialsConatct = (type, name, value) => (dispatch) => {
    dispatch({ type: "ON_UPDATE_SOCIAL_CONTACT", payload: { type, name, value } })

}

export const onChangeOptinFooter = (name, value) => (dispatch) => {
    dispatch({ type: "ON_CHANGE_OPTIN_FOOTER", payload: { name, value } })
}