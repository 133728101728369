import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { onUpdateButton, onUpdateFamily, onUpdatePrimary, onUpdateSecondary } from '../../../../Redux/Actions/ProjectActions';
import rgbHex from 'rgb-hex';

import { FiUpload } from "react-icons/fi";
import GradientBox from '../../../CommonComponents/GradientBox';
import { onFetchFont } from '../../../../Redux/Actions/CommonActions';

const PageStyleSection = () => {
    const dispatch = useDispatch()
    const landingData = useSelector(state => state.bonus.data)

    const [fonts, setFonts] = useState(false);
    const [isGradientPrimary, setIsGradientPrimary] = useState(false);
    const [isGradientButton, setIsGradientButton] = useState(false);
    const [typePrimary, setTypePrimary] = useState(false);
    const [typeButton, setTypeButton] = useState(false);
    const [colorShow, setColorShow] = useState({
        primary: false,
        button: {
            bg: false,
            font: false
        },
        gradient: {
            primaryLeft: false,
            primaryRight: false,
            buttonLeft: false,
            buttonRight: false
        }
    });

    const handleToggleActive = (type, name) => {
        if (type === "primary") {
            setColorShow({
                ...colorShow,
                primary: true
            })
        } else if (type === "button") {
            setColorShow({
                ...colorShow,
                button: {
                    ...colorShow.button,
                    [name]: true
                }
            })
        }
    }

    const handleToggleDeactive = (type, name) => {
        if (type === "primary") {
            setColorShow({
                ...colorShow,
                primary: false
            })
        } else if (type === "button") {
            setColorShow({
                ...colorShow,
                button: {
                    ...colorShow.button,
                    [name]: false
                }
            })
        }
    }

    const handleChangeColor = (e, type, name) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        if (type === "primary") {
            dispatch(onUpdatePrimary(hex))
        } else if (type === "secondary") {
            dispatch(onUpdateSecondary(hex, name))
        } else if (type === "button") {
            dispatch(onUpdateButton(hex, name))
        }
    }

    const getFinalGradient = (type, finalColor) => {
        if (type === "primary") {
            dispatch(onUpdatePrimary(finalColor))
        } else if (type === "button") {
            dispatch(onUpdateButton(finalColor, "bgColor"))
        }

    }
    const handleFamily = (e) => {
        dispatch(onUpdateFamily(e.target.value))
    }

    const fetchFonts = () => {
        dispatch(onFetchFont(setFonts, false, true))
    }

    useEffect(() => {
        if (isGradientPrimary) {
            setTypePrimary("Gradient")
        } else {
            setTypePrimary("Color")
        }
    }, [isGradientPrimary])

    useEffect(() => {
        if (isGradientButton) {
            setTypeButton("Gradient")
        } else {
            setTypeButton("Color")
        }
    }, [isGradientButton])

    useEffect(() => {
        fetchFonts()
    }, [])


    return (
        <>
            <div className="row">

                <div className='boxStyle'>
                    <div>
                        <div className='inpLabelWrap'>
                            <span className='labelTxt'><strong>Primary Background</strong></span>
                        </div>
                        <div className='row px-4 pt-2'>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Type</span>
                                    <select
                                        className='common-input'
                                        value={typePrimary}
                                        onChange={(e) => setTypePrimary(e.target.value)}
                                    >
                                        <option value="Color">Color</option>
                                        <option value="Gradient">Gradient</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>{typePrimary}</span>
                                    {typePrimary === "Color" ?
                                        <div className='primary-background'>
                                            <div className='color-wrap'>
                                                <span
                                                    className='colorBox'
                                                    style={{ background: landingData.style.primaryBackground.mainBgColor }}
                                                    onClick={() => handleToggleActive("primary")}
                                                ></span>
                                                <span className='colorVal'>{landingData.style.primaryBackground.mainBgColor}</span>
                                            </div>
                                            {colorShow.primary ?
                                                <>
                                                    <div
                                                        style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                        onClick={() => handleToggleDeactive("primary")}
                                                    />
                                                    <span className="color-box">
                                                        <SketchPicker
                                                            color={landingData.style.primaryBackground.mainBgColor}
                                                            onChange={(e) => handleChangeColor(e, "primary")}
                                                        />
                                                    </span>
                                                </> : null}
                                        </div> :
                                        <div className='primary-gradient'>
                                            <GradientBox
                                                getFinalGradient={getFinalGradient}
                                                colorString={landingData.style.primaryBackground.mainBgColor}
                                                setIsGradient={setIsGradientPrimary}
                                                type="primary"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pt-3'>
                        <div className='inpLabelWrap'>
                            <span className='labelTxt'><strong>Buy Now Button</strong></span>
                        </div>

                        <div className='row px-4 pt-2'>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Background Type</span>
                                    <select
                                        className='common-input'
                                        value={typeButton}
                                        onChange={(e) => setTypeButton(e.target.value)}
                                    >
                                        <option value="Color">Color</option>
                                        <option value="Gradient">Gradient</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>{typeButton}</span>
                                    {typeButton === "Color" ?
                                        <div className='primary-background'>
                                            <div className='color-wrap'>
                                                <span
                                                    className='colorBox'
                                                    style={{ background: landingData.style.buttons.bgColor }}
                                                    onClick={() => handleToggleActive("button", "bg")}
                                                ></span>
                                                <span className='colorVal'>{landingData.style.buttons.bgColor}</span>
                                            </div>
                                            {colorShow.button.bg ?
                                                <>
                                                    <div
                                                        style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                        onClick={() => handleToggleDeactive("button", "bg")}
                                                    />
                                                    <span className="color-box">
                                                        <SketchPicker
                                                            color={landingData.style.buttons.bgColor}
                                                            onChange={(e) => handleChangeColor(e, "button", "bgColor")}
                                                        />
                                                    </span>
                                                </> : null}
                                        </div> :
                                        <div className='primary-gradient'>
                                            <GradientBox
                                                getFinalGradient={getFinalGradient}
                                                colorString={landingData.style.buttons.bgColor}
                                                setIsGradient={setIsGradientButton}
                                                type="button"
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-lg-12 pt-2'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Font Color</span>
                                    <div className='color-wrap'>
                                        <span
                                            className='colorBox'
                                            style={{ background: landingData.style.buttons.fontColor }}
                                            onClick={() => handleToggleActive("button", "font")}
                                        ></span>
                                        <span className='colorVal'>{landingData.style.buttons.fontColor}</span>
                                    </div>
                                    {colorShow.button.font ?
                                        <>
                                            <div
                                                style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                                                onClick={() => handleToggleDeactive("button", "font")}

                                            />
                                            <span className="color-box">
                                                <SketchPicker
                                                    color={landingData.style.buttons.fontColor}
                                                    onChange={(e) => handleChangeColor(e, "button", "fontColor")}
                                                />
                                            </span>
                                        </> : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='pt-3'>
                        <div className='inpLabelWrap'>
                            <span className='labelTxt'><strong>Font Family</strong></span>
                        </div>
                        <div className='row px-4 pt-2'>
                            <div className='col-lg-6'>
                                <div className='inpLabelWrap mt-1'>
                                    <span className='labelTxt'>Fonts</span>
                                    <select
                                        className='common-input'
                                        onChange={(e) => handleFamily(e, "font_family")}
                                        value={landingData.style.fontfamily}
                                        style={{ fontFamily: landingData.style.fontfamily }}
                                    >
                                        {fonts.length > 0 ?
                                            fonts.map((curElem, index) => {
                                                return (
                                                    <option key={index} value={curElem} style={{ fontFamily: curElem }}>{curElem}</option>
                                                )
                                            })
                                            : null}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </>
    );
}

export default PageStyleSection;
