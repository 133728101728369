import React, { useEffect, useRef, useState } from 'react'
import Footer from '../../CommonComponents/Footer/Footer'
import TitleBar from '../../CommonComponents/TitleBar'
import Navbar from '../../CommonComponents/Navs/Navbar'
import SideNav from '../../CommonComponents/Navs/SideNav'
import CourseHeader from '../Creation/CourseHeader'
import { BsStars } from 'react-icons/bs'
import { IoCopyOutline, IoReloadSharp } from 'react-icons/io5'
import { TbEdit } from 'react-icons/tb'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import queryString from 'query-string'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { onFetchAiData, onFetchCourse, onGetAiData, onSaveVideoChapter } from '../../../Redux/Actions/CourseActions'
import WriteDataModal from './WriteDataModal'

import { FiSave } from "react-icons/fi";
import { IoArrowBackSharp, IoArrowForwardOutline } from 'react-icons/io5'
import { RxReload } from 'react-icons/rx'
import { themeColor } from '../../../Global/Global'

const WriteChapters = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { id, chapId } = queryString.parse(location.search)
    const [chapterId, setChapterId] = useState(chapId)
    const course = useSelector(state => state.course.data)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [moreLoader, setMoreLoader] = useState(false)

    const [chapterName, setChapterName] = useState("")
    const [chapterData, setChapterData] = useState({
        createdFrom: {
            text: ""
        }
    })

    const [aiSuggestion, setAiSuggestion] = useState([])

    const [loader, setLoader] = useState({
        save: false,
        aiLoader: false,
        generate: false,
        fetch: true
    })

    const [loaderAutoFetch, setLoaderAutoFetch] = useState({
        aiLoader: false,
    })
    const handleChange = (e) => {
        setChapterData({
            ...chapterData,
            createdFrom: {
                ...chapterData.createdFrom,
                text: e.target.value
            }
        })
    }

    const handleGenerate = () => {
        let data = {
            courseId: id,
            chapterId: chapterId,
            text: chapterData.createdFrom.text,
            buttonType: "save",
            createdFrom: chapterData.createdFrom,
            mediaType: "stock",
            dimension: "16x9",
            faceNarration: {
                enable: false,
                media: {
                    url: "",
                    pos: "bottom-right",
                    height: 125,
                    width: 125,
                    shape: "circle"
                },
                coverage: 10,
                headMovement: false
            },
            voiceClone: {
                enable: false,
                cloneId: "",
            }
        }
        if (chapterData.createdFrom.text !== "") {
            setLoader({
                ...loader,
                save: true
            })
            dispatch(onSaveVideoChapter(data, navigate, loader, setLoader))
        } else {
            dispatch(setAlert("Please write something about this chapter to save slides", "danger"))
        }
    }

    const getAiData = () => {
        if (chapterName !== "") {
            let data = {
                text: chapterName,
                type: "article",
                limit: 0,
                courseId: id,
                chapterId: chapterData.id
            }
            setLoader({
                ...loader,
                aiLoader: true,
            })
            dispatch(onGetAiData(data, handleArticle, setMoreLoader, loader, setLoader))
        }
    }

    const copyText = (text, trueIndex) => {
        if (chapterId) {
            if (chapterData.createdFrom.text) {
                setChapterData({
                    ...chapterData,
                    createdFrom: {
                        ...chapterData.createdFrom,
                        text: chapterData.createdFrom.text + "\n" + text
                    }
                })
            } else {
                setChapterData({
                    ...chapterData,
                    createdFrom: {
                        ...chapterData.createdFrom,
                        text: text
                    }
                })
            }
        }
        // navigator.clipboard.writeText(text)
        // let data = [...aiSuggestion]
        // data.forEach((curElem, index) => {
        //     if (index === trueIndex) {
        //         curElem.copyStatus = true
        //     }
        //     else {
        //         curElem.copyStatus = false
        //     }
        // })
        // setAiSuggestion(data)
    }

    const fetchAiData = () => {
        let data = {
            courseId: id,
            type: "article"
        }
        dispatch(onFetchAiData(data, handleArticle, loadIntial))
    }


    const handleArticle = (data) => {
        let objData = []
        data.forEach((curElem) => {
            let obj = {
                text: "",
                copyStatus: false,
                chp_id: curElem.chp_id
            }
            obj.text += curElem.output_string.replace(/(?:\r\n|\r|\n)/g, "")
            objData.push(obj)
        })
        setAiSuggestion(objData)
    }

    const loadIntial = (chapterId, name) => {
        if (name || chapterName) {
            let data = {
                text: name ? name : chapterName,
                type: "article",
                limit: 0,
                courseId: id,
                chapterId: chapterId ? chapterId : chapId
            }
            setLoaderAutoFetch({
                ...loaderAutoFetch,
                aiLoader: true,
            })
            setMoreLoader(true)
            dispatch(onGetAiData(data, handleArticle, setMoreLoader, loaderAutoFetch, setLoaderAutoFetch))
        }
    }

    const handleCopyAll = () => {
        if (aiSuggestion.length > 0) {
            let text = ""
            aiSuggestion.forEach((curElem) => {
                text += curElem.text + '\n'
            })
            navigator.clipboard.writeText(text)
            dispatch(setAlert("All content copied!", "success"))
        }
    }

    const handleLoadMore = () => {
        setMoreLoader(true)
        getAiData()
    }

    const handleChapterId = (e) => {
        setChapterId(e.target.value)
        let chapD = course.chapters.find(({ id }) => +id === +e.target.value)
        if (chapD) {
            loadIntial(e.target.value, chapD.name)
        }
    }
    const handelSlideMax = () => {
        dispatch(setAlert("You can have maximum 15 slides in a chapter.", "danger"))
    }

    const fetchCourse = () => {
        let data = {
            courseId: id
        }
        dispatch(onFetchCourse(data, loader, setLoader))
    }


    useEffect(() => {
        if (chapterId && course.chapters.length > 0) {
            let chapD = course.chapters.find(({ id }) => +id === +chapterId)
            if (chapD) {
                setChapterData(chapD)
                setChapterName(chapD.name)
            }
        }
    }, [chapterId, course])

    useEffect(() => {
        if (course.chapters.length > 0) {
            fetchAiData()
        }
    }, [course.chapters])


    useEffect(() => {
        if (id) {
            fetchCourse()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Write Chapter" />
                <Navbar />
                <section className="siteWrap w1">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">
                            <div className="create-course-top">
                                <div className="row">
                                    <div className="col">
                                        <div className="research-head-left">
                                            <h2>
                                                <CourseHeader
                                                    courseName={course.projectName}
                                                    courseId={course.id}
                                                    topicName={course.topicName}
                                                />
                                                | {course.topicName} | <span>{chapterData.name}</span></h2>
                                        </div>
                                    </div>

                                    <div className='col-12 col-sm-auto'>
                                        <div className="projectTitle-right pt-3 pt-md-0 justify-content-center">
                                            <button
                                                className="demoLink grey m-0"
                                                onClick={() => handleGenerate("save")}
                                                disabled={chapterId && chapterData.createdFrom.text ? false : true}
                                                style={chapterId && chapterData.createdFrom.text ? {} : { background: "gray", cursor: "not-allowed" }}
                                            >
                                                {loader.save ? (
                                                    <>
                                                        Saving <i className="fa fa-spinner fa-spin mr-2" />
                                                    </>
                                                ) : (
                                                    <>
                                                        Save Script <FiSave />
                                                    </>
                                                )}
                                            </button>
                                            <button
                                                className="demoLink m-0"
                                                type='button'
                                                onClick={handleShow}
                                                disabled={chapterId && chapterData.createdFrom.text ? false : true}
                                                style={chapterId && chapterData.createdFrom.text ? {} : { background: "gray", cursor: "not-allowed" }}
                                            >
                                                Next <IoArrowForwardOutline />
                                            </button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="container">
                                <div className="cont-research">
                                    <div className="cont-research-body modified">

                                        <h3 className='gradientTxt mb-4'><span>Select Your Chapters</span></h3>

                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="research-body-left">

                                                    <div className="mycourseInp-wrap full m-0">
                                                        <select
                                                            className="courseInp mycourseInp"
                                                            value={chapterId}
                                                            onChange={(e) => handleChapterId(e)}
                                                        >
                                                            <option value="">Select Chapter</option>
                                                            {course.chapters.length > 0 ?
                                                                course.chapters.map((curElem, index) => {
                                                                    return (
                                                                        <option key={index} value={curElem.id}>{curElem.name.length > 80 ? curElem.name.slice(0, 80) + "..." : curElem.name}</option>
                                                                    )
                                                                })
                                                                : ""}
                                                        </select>
                                                    </div>

                                                    <div className="cardBlock mt-4">
                                                        <div className="row align-items-center justify-content-between">
                                                            <p className='mb-3' style={{ color: '#FFF' }}><BsStars /> Use AI to Generate Content for your Chapter:</p>

                                                            <div className="col">
                                                                <input
                                                                    value={chapterName}
                                                                    className="inpField-inp mt-1"
                                                                    style={{ borderColor: "#181855", borderRadius: 10 }}
                                                                    onChange={(e) => setChapterName(e.target.value)}

                                                                />
                                                                {/* <h6 className="pt-2 ps-4">Introduction to Rice: History, Varieties, and Nutritional Benefits</h6> */}
                                                            </div>
                                                            <div className="col-auto">
                                                                <button
                                                                    className="demoLink m-0"
                                                                    onClick={getAiData}
                                                                >
                                                                    <BsStars />  {loader.aiLoader && !moreLoader ? <>Generating <i className="fa fa-spinner fa-spin" /> </> : "Generate"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="title-block mt-4" style={{ minHeight: 300 }}>

                                                        <div className="title-block-top">
                                                            <p style={{ fontSize: '0.9rem', color: '#FFF' }}>AI Generated Content</p>
                                                            <button className="simple-btn" onClick={handleCopyAll}><IoCopyOutline /> Copy All Content</button>
                                                        </div>
                                                        <div className='text-center mt-2'>
                                                            {loaderAutoFetch.aiLoader && moreLoader ?
                                                                <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: themeColor }} /> : ""}
                                                        </div>
                                                        {aiSuggestion.length > 0 ?

                                                            <div className="title-block-cont">
                                                                <div className="block-cont-scroll">
                                                                    <ul>
                                                                        {aiSuggestion.filter((curData) => {
                                                                            return +curData.chp_id === +chapterId
                                                                        }).map((curElem, index) => {
                                                                            return (
                                                                                <li key={index}>
                                                                                    <div className="recomnd-singleN">
                                                                                        <h6 id="aiContent" style={{ textAlign: 'justify', paddingRight: 10 }}>{curElem.text}</h6>
                                                                                        <a
                                                                                            className="demoLink blue text-light" onClick={() => copyText(curElem.text, index)}
                                                                                        >
                                                                                            Use This
                                                                                            {/* {curElem.copyStatus ? "Copied" : "Copy"} */}
                                                                                        </a>
                                                                                    </div>
                                                                                    <hr />
                                                                                </li>
                                                                            )
                                                                        })
                                                                        }
                                                                        {aiSuggestion.filter((curData) => {
                                                                            return +curData.chp_id === +chapterId
                                                                        }).length > 0 ?
                                                                            <li className='text-center'>
                                                                                <button className='demoLink px-5' onClick={handleLoadMore}>
                                                                                    <div className="recomnd-singleN">
                                                                                        {loader.aiLoader && moreLoader ?
                                                                                            <><i className="fa fa-spinner fa-spin" /> Loading More </> :
                                                                                            <><RxReload className='me-2' /> Load More</>
                                                                                        }
                                                                                    </div>
                                                                                </button>
                                                                            </li> : ""}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            :
                                                            ""

                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="research-body-right">

                                                    <div className="title-block">
                                                        <div className="title-block-top">
                                                            <div className="col ps-0">
                                                                <h6 style={{ fontSize: '0.9rem' }}>Write, Edit & Paste Your Content Here</h6>
                                                                {/* <p className="pt-2" style={{ fontSize: '0.85rem' }}>To separate slides press <span style={{ color: '#001EFF' }}>Ctrl + Enter</span></p> */}
                                                            </div>
                                                            <div className="col-auto pe-0 text-right">
                                                                <button className="simple-btn"><TbEdit /></button>
                                                                {/* <p>Slides: {slideLength}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className="title-block-cont">
                                                            <textarea
                                                                className="txtArea sm"
                                                                type="text"
                                                                value={chapterData?.createdFrom.text}
                                                                name="text"
                                                                onChange={(e) => handleChange(e)}
                                                                placeholder="Write Your Content Here"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <WriteDataModal
                    show={show}
                    handleClose={handleClose}
                    chapterData={chapterData}
                    id={id}
                    chapId={chapterId}
                />

                <Footer />
            </>
    )
}

export default WriteChapters