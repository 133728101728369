import './App.css';
import './responsive.css'
import 'animate.css';
import { BrowserRouter, Routes, Route } from "react-router";
import Login from './Components/Authentication/Login';
import Alert from './Components/CommonComponents/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from './Redux/Actions/AuthActions';
import { removeAlert } from './Redux/Actions/AlertActions';
import { useEffect, useState } from 'react';
import ForgetPassword from './Components/Authentication/ForgetPassword';
import MasterLogin from './Components/Authentication/MasterLogin';
import ResetPassword from './Components/Authentication/ResetPassword';
import Dashboard from './Components/Dashboard/Dashboard';
import PrivateRoute from './Components/CommonComponents/PrivateRoute';
import MyAccount from './Components/MyAccount/MyAccount';
import Privacy from './Components/Privacy/Privacy';
import AccountManagement from './Components/AccountManagement/AccountManagement';
import Upgrades from './Components/Upgrades/Upgrades';
import Help from './Components/Help/Help';
import Training from './Components/Help/Training';
import TrainingArticles from './Components/Help/TrainingArticles';
import Integration from './Components/Integration/Integration';
import Courses from './Components/Courses/Courses';
import PromoVideo from './Components/PromoVideo/PromoVideo';
import Quiz from './Components/Quiz/Quiz';
import ReviewParticipantsName from './Components/Quiz/Participants/ReviewParticipantsName';
import ChapterSummaries from './Components/PromoVideo/ChapterSummaries';
// import AiTemplates from './Components/PromoVideo/AiTemplates';
import ReelMerge from './Components/ReelMerge/ReelMerge';
import CreateTopic from './Components/Courses/Creation/CreateTopic';
import Chapters from './Components/Courses/Creation/Chapters';
import CreateChapter from './Components/Courses/Creation/CreateChapter/CreateChapter';
import LandingPage from './Components/LandingPage/LandingPage';
import GenerateLandingPage from './Components/LandingPage/GenerateLandingPage';
import PreFilledLandingPage from './Components/LandingPage/PreFilledLandingPage';
import ContentExtraction from './Components/LandingPage/ContentExtraction';
import Editor from './Components/Editor/Editor';
import WriteChapters from './Components/Courses/WriteChapter/WriteChapters';
import CreateQuiz from './Components/Quiz/Create/CreateQuiz';
import ReviewScript from './Components/PromoVideo/Create/ReviewScript';
import PublicQuiz from './Components/PublicQuiz/PublicQuiz';
import YourQuiz from './Components/PublicQuiz/YourQuiz';
import Participants from './Components/Quiz/Participants/Participants';
import ScrollTop from './Components/CommonComponents/ScrollTop';
import CustomizeWebsite from './Components/LandingPage/CreateLanding/CustomizeWebsite/CustomizeWebsite';
import WebsiteEditor from './Components/LandingPage/WebsiteEditor';
import ChooseTemplate from './Components/LandingPage/CreateLanding/ChooseTemplate';
import MainLandingPage from './Components/LandingPage/MainLandingPage';

function App() {
  const dispatch = useDispatch()
  const alert = useSelector(state => state.alert)
  const auth = useSelector(state => state.auth)

  const [memberShip, setMemberShip] = useState([])


  const fetchUser = () => {
    dispatch(loadUser())
  }

  useEffect(() => {
    if (alert !== undefined) {
      if (alert.message !== "") {
        setTimeout(() => {
          dispatch(removeAlert())
        }, 4000);
      }
    }
  }, [alert, dispatch])



  useEffect(() => {
    if (auth.user) {
      setMemberShip(auth.user.membership.split("__"))
    }
  }, [auth.user])

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <div className="App">
      <Alert />

      <BrowserRouter>
        <ScrollTop />

        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="/master-login" element={<MasterLogin />} />
          <Route path="/reset-password" element={<ResetPassword />} />

          {/* QUIZ ROUTES */}

          <Route path='/quiz' element={<PublicQuiz />} />
          <Route path='/your-quiz' element={<YourQuiz />} />

          <Route exact path="/my-account" element={<PrivateRoute><MyAccount /> </PrivateRoute>} />
          <Route exact path="/privacy" element={<PrivateRoute><Privacy /> </PrivateRoute>} />
          <Route exact path="/account-management" element={<PrivateRoute><AccountManagement /> </PrivateRoute>} />
          <Route exact path="/upgrades" element={<PrivateRoute><Upgrades /> </PrivateRoute>} />
          <Route exact path="/help-and-support" element={<PrivateRoute><Help /> </PrivateRoute>} />
          <Route exact path="/training" element={<PrivateRoute><Training /> </PrivateRoute>} />
          <Route exact path="/support-article" element={<PrivateRoute><TrainingArticles /> </PrivateRoute>} />
          <Route exact path="/integration" element={<PrivateRoute><Integration /> </PrivateRoute>} />

          <Route exact path="/dashboard" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />
          <Route exact path="/courses" element={<PrivateRoute><Courses /> </PrivateRoute>} />
          <Route exact path="/courses/create-topic" element={<PrivateRoute><CreateTopic /> </PrivateRoute>} />
          <Route exact path="/courses/create-chapter" element={<PrivateRoute><CreateChapter /> </PrivateRoute>} />
          <Route exact path="/courses/chapters" element={<PrivateRoute><Chapters /> </PrivateRoute>} />
          <Route exact path="/courses/write-chapters" element={<PrivateRoute><WriteChapters /> </PrivateRoute>} />


          <Route exact path="/editor" element={<PrivateRoute><Editor /> </PrivateRoute>} />


          <Route exact path="/promo-video" element={<PrivateRoute><PromoVideo /> </PrivateRoute>} />
          <Route exact path="/promo-video/chapter-summaries" element={<PrivateRoute><ChapterSummaries /> </PrivateRoute>} />
          <Route exact path="/promo-video/review-script" element={<PrivateRoute><ReviewScript /> </PrivateRoute>} />


          <Route exact path="/quiz-generation/create-quiz" element={<PrivateRoute><CreateQuiz /> </PrivateRoute>} />
          <Route exact path="/quiz-generation" element={<PrivateRoute><Quiz /> </PrivateRoute>} />
          <Route exact path="/participants/review-participants" element={<PrivateRoute><ReviewParticipantsName /> </PrivateRoute>} />
          <Route exact path="/participants" element={<PrivateRoute><Participants /> </PrivateRoute>} />

          {/* <Route exact path="/ai-templates" element={<PrivateRoute><AiTemplates /> </PrivateRoute>} /> */}


          <Route exact path="/landing-page" element={<PrivateRoute><MainLandingPage /></PrivateRoute>} >
            <Route index element={<LandingPage />} />
            <Route path="generate-landing-page" element={<PrivateRoute><GenerateLandingPage /> </PrivateRoute>} />
            <Route path="content-extraction" element={<PrivateRoute><ContentExtraction /> </PrivateRoute>} />
            <Route path="pre-filled-landing-page" element={<PrivateRoute><PreFilledLandingPage /> </PrivateRoute>} />
            <Route path="customize-website" element={<PrivateRoute><CustomizeWebsite /> </PrivateRoute>} />
            <Route path="website-editor" element={<PrivateRoute><WebsiteEditor /> </PrivateRoute>} />
            <Route path="choose-templates" element={<PrivateRoute><ChooseTemplate /> </PrivateRoute>} />
          </Route>
          <Route exact path="/reel-merge" element={<PrivateRoute><ReelMerge /> </PrivateRoute>} />
          <Route path="*" element={<Login />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
