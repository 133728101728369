import React, { useEffect, useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";

import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import TitleBar from '../../CommonComponents/TitleBar';
import Navbar from '../../CommonComponents/Navs/Navbar';
import SideNav from '../../CommonComponents/Navs/SideNav';
import Footer from '../../CommonComponents/Footer/Footer';
import { useNavigate } from 'react-router';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import CreateModal from '../Create/CreateModal';
import ParticipantRows from './ParticipantRows';
import { themeColor } from '../../../Global/Global';
import Pagination from '../../CommonComponents/Pagination';
import { useSelector } from 'react-redux';


const Participants = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [searchKey, setSearchKey] = useState("");


    const [loader, setLoader] = useState({
        fetch: true
    })
    const [participant, setParticipant] = useState([])
    const [pagination, setPagination] = useState({
        currentPage: 1,
        totalItemOnPage: 10
    });

    const indexofLastTodo = pagination.currentPage * pagination.totalItemOnPage;
    const indexofFirstTodo = indexofLastTodo - pagination.totalItemOnPage;

    const currentTodo = participant.slice(indexofFirstTodo, indexofLastTodo);



    const fetchParticipants = () => {
        dispatch(fetchData("fetch-all-participants", {}, setParticipant, loader, setLoader))
    }

    useEffect(() => {
        fetchParticipants()
    }, [])

    return (

        <>
            <TitleBar title="Participants" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">
                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Participant"
                                                onChange={(e) => setSearchKey(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        {+auth.user.isClientAccount === 1 ? null :
                                            <button className="demoLink" onClick={handleShow}><BsStars /> Create Quiz</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">
                            <div className='quiz-wrap'>
                                <div className='mt-4 participants-card'>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h5>Participants List</h5>
                                    </div>
                                    <div className='table-responsive mt-4'>
                                        <table className='table theme-table'>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>Age</th>
                                                <th>Gender</th>
                                                <th>Score</th>
                                                <th>Completion Time</th>
                                                <th>Action</th>
                                            </tr>
                                            {currentTodo.length > 0 ?
                                                currentTodo.filter((curElem) => {
                                                    let name = `${curElem.firstName + " " + curElem.lastName}`
                                                    if (searchKey) {
                                                        return name.toLowerCase().includes(searchKey.toLowerCase());
                                                    } else {
                                                        return curElem;
                                                    }
                                                }).length > 0 ?
                                                    currentTodo.filter((curElem) => {
                                                        let name = `${curElem.firstName + " " + curElem.lastName}`
                                                        if (searchKey) {
                                                            return name.toLowerCase().includes(searchKey.toLowerCase());
                                                        } else {
                                                            return curElem;
                                                        }
                                                    }).map((curElem, index) => {
                                                        const idx = index + (pagination.currentPage - 1) * pagination.totalItemOnPage;
                                                        return (
                                                            <React.Fragment key={idx}>
                                                                <ParticipantRows
                                                                    index={idx}
                                                                    curElem={curElem}
                                                                    participant={participant}
                                                                    setParticipant={setParticipant}
                                                                />
                                                            </React.Fragment>
                                                        )
                                                    }) :

                                                    <tr className='text-center'>
                                                        <td colSpan={7}>
                                                            No Data Found
                                                        </td>
                                                    </tr>
                                                :
                                                <tr className='text-center'>
                                                    <td colSpan={7}>
                                                        {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 20, color: themeColor }} /> : "No Data Found"}
                                                    </td>
                                                </tr>
                                            }
                                        </table>
                                    </div>

                                    {!loader.fetch &&
                                        <Pagination
                                            listData={participant}
                                            pagination={pagination}
                                            setPagination={setPagination}
                                            currentTodo={currentTodo}
                                            listArr={participant}
                                            loader={loader.fetch}
                                        />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <CreateModal
                show={show}
                handleClose={handleClose}
            />
            <Footer />
        </>
    )
}

export default Participants;