import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { onUpdateCourse } from '../../../Redux/Actions/CourseActions'

const RecommendedRows = ({ curElem, course }) => {

    let str = curElem.replace(/^[1-9][0-9]*\.\s|^[1-9][0-9]*\)\s/g, '')
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loader, setLoader] = useState({
        update: false
    })

    const handleUse = () => {
        let data = {
            courseId: course.id,
            projectName: course.projectName,
            topicName: str
        }
        setLoader({
            ...loader,
            update: true
        })
        dispatch(onUpdateCourse(data, navigate, loader, setLoader))
    }

    return (
        <li>
            <div className="recomnd-single d-flex justify-content-between align-items-center">
                <p
                    style={{
                        textAlign: "left",
                        paddingRight: '23px',
                        color: '#fff',
                        margin: "auto 0"
                    }}
                >{str}</p>
                <button
                    type="button"
                    // disabled={+curElem.isSelected}
                    style={+curElem.isSelected ? { background: "gray", cursor: "not-allowed" } : {}}
                    className="demoLink mt-0 ms-1"
                    onClick={handleUse}
                >
                    {loader.update ? <>Using <i className="fa fa-spinner fa-spin" /></> : "Use This"}
                </button>
            </div>
        </li >
    )
}

export default RecommendedRows