
import React from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../images/modal-close.png';

const TemplatePreview = ({ show, handleClose, image }) => {
    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec  p-4">
                    <div className="modal-img"><img alt="" src={image} /></div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default TemplatePreview