import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import logo from "../../images/LOGO.png"
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router'
import LoginFooter from '../CommonComponents/Footer/LoginFooter'
import { loginUser } from '../../Redux/Actions/AuthActions'
import { appName } from '../../Global/Global'


const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const [view, setView] = useState(false)
    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader(true)
        dispatch(loginUser(loginInfo, setLoader))
    }

    useEffect(() => {
        if (auth.isAuthenticated) {
            navigate('/dashboard')
        }
    }, [auth])
    return (
        <>
            <TitleBar title="Login" />
            <header className="loginHeader">
                <div className="container-fluid">
                    <div className="loginLogo">
                        <img src={logo} />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2 className='gradientTxt alt'>Welcome To <br /> <span>{appName}</span></h2>
                                <p>Take your course creation to the next level with cutting-edge AI tools designed to save time, enhance engagement, and maximize your profits. From personalized video content to fully automated marketing assets, everything you need to create, customize, and sell professional-grade courses is at your fingertips.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Login</span> to Your Account Now</h2>
                                    <form onSubmit={(e) => handleSubmit(e)}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input
                                                type="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email Address"
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="************"
                                                onChange={(e) => handleChange(e)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="demoLink btn-block m-0" disabled={loader}>
                                                Sign in to {appName}   {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Forgot your account details? <Link to="/forgot-password" className="text-center "> Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    )
}

export default Login