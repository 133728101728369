import React from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import modalCloseIcon from '../../../images/modal-close.png';

import {
    EmailShareButton,
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    // PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    InstapaperIcon,
    LinkedinIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WhatsappIcon,
    WorkplaceIcon
} from 'react-share';

const ShareThisModal = (props) => {
    return (
        <Modal className="theme-modal VideoModal xl small" show={props.show} centered>
            {/* <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div> */}
            <div onClick={() => { props.setShow(false) }} className="vidClose"><img src={modalCloseIcon} /></div>
            <Modal.Body>
                <div>
                    <ul>
                        {
                            <>
                                <FacebookShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <FacebookIcon />
                                </FacebookShareButton>

                                <EmailShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <EmailIcon />
                                </EmailShareButton>

                                <LinkedinShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <LinkedinIcon />
                                </LinkedinShareButton>

                                {/* <PinterestShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <PinterestIcon />
                                </PinterestShareButton> */}

                                <RedditShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <RedditIcon />
                                </RedditShareButton>

                                <TelegramShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TelegramIcon />
                                </TelegramShareButton>

                                <PocketShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <PocketIcon />
                                </PocketShareButton>

                                <TwitterShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TwitterIcon />
                                </TwitterShareButton>

                                <WhatsappShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <WhatsappIcon />
                                </WhatsappShareButton>

                                <TumblrShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <TumblrIcon />
                                </TumblrShareButton>

                                <InstapaperShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <InstapaperIcon />
                                </InstapaperShareButton>

                                {/* <ViberShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <ViberIcon />
                                </ViberShareButton> */}

                                <VKShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <VKIcon />
                                </VKShareButton>

                                <WorkplaceShareButton
                                    url={props.url}
                                    quote={props.url}
                                >
                                    <WorkplaceIcon />
                                </WorkplaceShareButton>
                            </>
                        }
                    </ul>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ShareThisModal
