import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"


export const onUploadUserImages = (data, fetchUserImages, uploadedImages, setUploadedImages, loader) => (dispatch, getState) => {
    commonAxios("upload-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                let arr = [...uploadedImages]
                arr.unshift({ url: res.data.path, name: res.data.name })
                setUploadedImages(arr)
                fetchUserImages();
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            loader.style.display = "none"
        }).catch((err) => {
            console.log(err)
            loader.style.display = "none"
        })
}
export const onFetchUserImages = (data, setUploadedImages, editor) => (dispatch, getState) => {
    commonAxios("fetch-user-uploaded-file", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setUploadedImages(res.data);
                editor.trigger('asset:update', res.data)
            }
        }).catch((err) => {
            console.log(err)
        })
}

export const onDeleteUserImages = (url, data, videos, setVideos, setSweet, editor) => (dispatch, getState) => {
    commonAxios(url, data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                const deleteId = data.get("id");
                let arr = videos.filter((curElem) => {
                    return +curElem.id !== +deleteId
                })
                setVideos(arr)
                editor.trigger('asset:update', arr)
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        }).catch((err) => {
            console.log(err)
            setSweet({
                enable: false,
                id: false,
                confirmButtonName: "Delete",
                loader: false
            })
        })
}

export const onGenerateAiImage = (data, imageContainer, editor) => (dispatch, getState) => {
    commonAxios("generate-ai-image", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data) {
                    imageContainer.innerHTML = `<div class="ai_describe_img">
                                        <img id="website-builder-generate-ai-img" src="${res.data}" alt="aiImage" style="cursor:pointer;" />
                                    </div>`

                    const clickAiImage = document.querySelector('#website-builder-generate-ai-img');
                    clickAiImage.addEventListener('click', function () {
                        let generatedImage = clickAiImage.getAttribute("src")
                        editor.trigger('asset:clickAiImage', generatedImage)
                    });
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            let loaderText = `Generate <i id="website-builder-ai-btn-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i>`
            document.getElementById("website-builder-ai-btn").innerHTML = loaderText;
        }).catch((err) => {
            let loaderText = `Generate <i id="website-builder-ai-btn-loader" class="fas fa-spinner web-spin website_builder_loader mx-1" style="color: white; font-size: 20px; display:none;"></i>`
            document.getElementById("website-builder-ai-btn").innerHTML = loaderText;
            console.log(err)
        })
}
export const onGenerateAiContent = (data, selected) => (dispatch, getState) => {
    commonAxios("generate-ai-content", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data) {
                    selected.view.el.innerText = res.data
                }
            } else {
                selected.view.el.innerText = data.text
                dispatch(setAlert(res.msg, "danger"))
            }
        }).catch((err) => {
            console.log(err)
            selected.view.el.innerText = data.text
        })
}
export const onSearchImages = (data, loader, editor, icon) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data) {
                    let imageContainer = document.getElementById("website-builder-search-image-container");
                    let loadMoreBtn = document.getElementById("website-builder-load-more-btn");
                    imageContainer.innerHTML = ``
                    let html = `${res.data.length > 0 ?
                        res.data.map((img, index) => `
                                <div class="col-xl-3 col-sm-6 col-12">
                                    <div class="Universal_url_images">
                                        <img class="asset-manager-search-image" src="${img.thumbnail}" value="${img.url}" alt="Image ${index}" />
                                    </div>
                                </div>
                            `).join('')
                        : ''
                        }`

                    imageContainer.insertAdjacentHTML("beforeend", html);
                    loadMoreBtn.style.display = "block";

                    const searchImg = document.querySelectorAll('.asset-manager-search-image');
                    searchImg.forEach((image) => {
                        image.addEventListener('click', function () {
                            let selectedImg = image.getAttribute("value")
                            editor.trigger('asset:clickSearchImage', selectedImg)
                        });
                    });
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            loader.style.display = "none"
            icon.style.display = "block"
        }).catch((err) => {
            loader.style.display = "none"
            icon.style.display = "block"
            console.log(err)
        })
}
export const onLoadMoreImages = (data, loader, editor) => (dispatch, getState) => {
    commonAxios("load-library-images", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (res.data) {
                    let html = `${res.data.length > 0 ?
                        res.data.map((img, index) => `
                                <div class="col-xl-3 col-sm-6 col-12">
                                    <div class="Universal_url_images">
                                        <img class="asset-manager-search-image" src="${img.thumbnail}" value="${img.url}" alt="Image ${index}" />
                                    </div>
                                </div>
                            `).join('')
                        : ''
                        }`

                    let imageContainer = document.getElementById("website-builder-search-image-container");
                    imageContainer.insertAdjacentHTML("beforeend", html);

                    const searchImg = document.querySelectorAll('.asset-manager-search-image');
                    searchImg.forEach((image) => {
                        image.addEventListener('click', function () {
                            let selectedImg = image.getAttribute("value")
                            editor.trigger('asset:clickSearchImage', selectedImg)
                        });
                    });
                }
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            loader.style.display = "none"
        }).catch((err) => {
            loader.style.display = "none"
            console.log(err)
        })
}

