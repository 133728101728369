import React, { useEffect, useState } from 'react'
import CommonAlert from '../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { Link } from 'react-router'
import PublishModal from './Publish/PublishModal'
import videoThumb from '../../images/max.png';
import { onCheckStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import { onCheckTranscribe, onSaveChapter } from '../../Redux/Actions/CourseActions'
import { Tooltip } from "react-tooltip";


const ChapterCard = ({ curElem, fetchCourse, promoVideo, setPromoVideo, type }) => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => state.auth);
    const [loader, setLoader] = useState({
        check: false
    })
    const [showModel, setShowModel] = useState(false)

    const [common, setCommon] = useState({
        isEditabel: false,
        status: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
        bgColor: "#117899"
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "chapters"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, promoVideo, setPromoVideo, setSweet, fetchCourse))

    }

    const handlePublish = () => {
        setShowModel(true)
    }



    const handleSaveTranscribe = (data) => {
        let obj = {
            courseId: curElem.cid,
            chapterId: curElem.id,
            text: data.transcribeText,
            slideBreak: "separator",
            buttonType: "save",
            createdFrom: {
                enable: true,
                type: curElem.createdFrom.type === "music" ? "audio" : curElem.createdFrom.type,
                source: data.transcript_uri,
                transcribeId: data.transcribeId,
                text: data.transcribeText
            }
        }
        dispatch(onSaveChapter(obj))
    }
    const checkTranscribe = (intCheck) => {
        let data = {
            courseId: curElem.cid,
            chapterId: curElem.id
        }
        dispatch(onCheckTranscribe(data, handleSaveTranscribe, setCommon, intCheck))
    }


    const fetchVideo = (id, interval) => {
        let data = {
            chapterId: id
        }
        dispatch(onCheckStatus(data, common, setCommon, interval))
    }

    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let activeTrans = false
            let progress = 100
            let bgColor = "#117899"
            videoUrl = curElem.videoUrl
            switch (+curElem.status) {
                case 0:
                    text = "Awaiting Script Generation"
                    activeIntrval = false
                    canEdit = type === "promo" ? true : false
                    url = type === "promo" ? `/promo-video/chapter-summaries?id=${curElem.id}` : ""
                    bgColor = "#117899"
                    break;
                case 1:
                    text = "Generating Video..."
                    showProg = true
                    activeIntrval = true
                    bgColor = "#9a15c2"

                    break;
                case 2:
                    text = "Render Successful"
                    canEdit = true
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#0f9628"
                    break;
                case 3:
                    canEdit = true
                    text = "Rendering Failed"
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#a62812"
                    break;
                case 4:
                    canEdit = true
                    text = "Script Generated"
                    // url = "create-script"
                    break;
                case 5:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = `/editor?id=${curElem.id}`
                    progress = curElem.renderProgress
                    bgColor = "#ffa500"
                    break;
                case 6:
                    canEdit = true
                    text = "Awaiting Customization"
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#122bc9"
                    break;
                case 7:
                    canEdit = true
                    text = "Transcribing Failed"
                    activeIntrval = false
                    bgColor = "#a62812"
                    break;
                case 8:
                    canEdit = true
                    text = "Script Generated"
                    url = type === "promo" ? `/promo-video/review-script?id=${curElem.id}` : `/courses/write-chapters?id=${curElem.cid}&chapId=${curElem.id}`
                    bgColor = "#117899"
                    break;
                case 9:
                    text = "Transcribing..."
                    activeTrans = true
                    bgColor = "#8f840d"
                    break;

                case 10:
                    text = "Downloading Resources..."
                    canEdit = true
                    activeIntrval = true
                    progress = curElem.renderProgress
                    bgColor = "#8f840d"
                    break;
                case 11:
                    canEdit = true
                    text = "Waiting in Queue..."
                    activeIntrval = true
                    showProg = true
                    url = `/editor?id=${curElem.id}`
                    break;
                case 12:
                    canEdit = true
                    text = curElem.renderProgress
                    activeIntrval = true
                    showProg = true
                    url = `/editor?id=${curElem.id}`
                    bgColor = "#8f840d"
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }
            setCommon({
                ...common,
                statusText: text,
                status: +curElem.status,
                isEditabel: canEdit,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                showProgAnimation: showProg,
                thumbUrl: curElem.thumbnail,
                failedData: curElem.log_file ? JSON.parse(curElem.log_file) : false,
                progress: progress,
                bgColor: bgColor
            })


            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchVideo(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }

            if (activeTrans) {
                const intCheck = setInterval(() => {
                    checkTranscribe(intCheck)
                }, 5000)

            }
        }
    }, [curElem])

    return (
        <>
            <div className={`videoProject-single m-0 ${curElem.dimension === "9x16" ? "videoProject-single-2" : "videoProject-single-1"}`}>
                {common.failedData && common.statusText === "Rendering Failed" ?
                    <Tooltip
                        id={`my-tooltip-${curElem.id}`}
                        style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0, zIndex: 9, overflow: "hidden" }}
                        place="bottom"
                        content={common.failedData.errorMessage}
                    />
                    : ""}
                <div className={` ${curElem.dimension === "9x16" ? "videoProject-single-top-2" : "videoProject-single-top-1"}`}>
                    <div className="video-card-wrapper-1 dashboard-card-bg">
                        {common.thumbUrl ?
                            <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} /> : null}
                        <div className="video-card-content">
                            <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                        </div>

                        <span className={`qz-tag ${+common.status === 2 ? "active" : ""}`} style={{ background: common.bgColor, zIndex: 5 }} data-tooltip-id={`my-tooltip-${curElem.id}`}>

                            {
                                common.showRendering ?
                                    <>{common.status === 12 ? common.progress : <>  Rendering {common.progress}%</>}</>
                                    : common.statusText
                            }
                        </span>
                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.create}</p></div>
                        <div className="vidiconAll">
                            {common.isEditabel ?
                                <div className="vidHover-single">
                                    {+auth.user.isClientAccount === 1 ? null :
                                        <Link to={common.urlToRedirect}>
                                            <span className="vidHover-icon">
                                                {loader.check ? <i className="fa fa-spinner fa-spin" /> :
                                                    <i className="fa fa-pencil-square-o" aria-hidden="true"></i>}
                                            </span>
                                            <span>Edit</span>
                                        </Link>
                                    }
                                </div> : ""}
                            <div className="vidHover-single">
                                {+auth.user.isClientAccount === 1 ? null :
                                    <a onClick={onDelete}>
                                        <span className="vidHover-icon"><i className="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                }
                            </div>
                            {common.isDownloadable ?
                                <>
                                    <div className="vidHover-single">
                                        <a href={`${common.isDownloadable}`} target='_blank'>
                                            <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                            <span>Download</span>
                                        </a>
                                    </div>
                                    {+auth.user.isClientAccount !== 1 ?
                                        <div className="vidHover-single cursor-pointer">
                                            <a onClick={handlePublish} >
                                                <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                <span>Publish</span>
                                            </a>
                                        </div> : ""}
                                </> : ""}
                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <h6 className='single-line'>{curElem.name}</h6>
                    {/* <p>Date Created: 12-01-24</p> */}
                </div>
            </div>


            <PublishModal
                showModel={showModel}
                setShowModel={setShowModel}
                curElem={curElem}
            />

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this ${type === "promo" ? "promo video" : "Chapter"}  "${curElem.name}"?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </>
    )
}

export default ChapterCard