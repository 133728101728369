

import React from 'react';
import { useSelector } from 'react-redux';
import IntroOutro from './IntroOutro';

const Credit = () => {
  const campaign = useSelector(state => state.video.data)
  const intro = campaign.intro
  const outro = campaign.outro

  return (
    <>
      <IntroOutro
        data={intro}
        type="intro"
      />
      <hr />
      <IntroOutro
        data={outro}
        type="outro"
      />
    </>
  );
}

export default Credit;
