import React from 'react'
import { Modal } from 'react-bootstrap'
import { ReactComponent as UploadIcon } from "../../images/upload.svg";
import { appName } from '../../Global/Global';

const FileUploadModal = ({ show, handleClose, percent }) => {
    return (
        <Modal className="VideoModal small" show={show} centered>
            <Modal.Body className="bg-white text-center">
                <div className="uploadFile-wrap">
                    <div className="img-wrapper mb-1">
                        <UploadIcon />
                    </div>
                    <h4 className="modal-title mb-2 upload_title text-dark" id="delete-warn">
                        Uploading File
                    </h4>
                    <p className="w-100 mx-auto mb-3 para1 text-dark">
                        <span className="d-block sp1">
                            Your file is uploading to {appName}.
                        </span>
                        This may take a few moments. We will alert you when completed.
                    </p>
                    <div className="progress-label mb-3">{percent}%</div>
                    <div className="progress mb-3">
                        <div
                            className="progress-bar"
                            style={{ width: `${percent}%`}}
                        ></div>
                    </div>
                    <button
                        type="submit"
                        className="inpBtn auto mt-4"
                        onClick={handleClose}
                    >
                        Cancel
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default FileUploadModal