import React, { useEffect, useState } from 'react'
import { IoCopyOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import { FaDesktop } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";
import TitleBar from '../../CommonComponents/TitleBar';
import Navbar from '../../CommonComponents/Navs/Navbar';
import SideNav from '../../CommonComponents/Navs/SideNav';
import Footer from '../../CommonComponents/Footer/Footer';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { onFetchUserQuiz } from '../../../Redux/Actions/QuizActions';
import ParticipantRows from './ParticipantRows';
import ParticipantsResBox from './ParticipantsResBox';


const ReviewParticipantsName = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    const { id } = queryString.parse(location.search)

    const [percentage, setPercentage] = useState(0)
    const [score, setScore] = useState({
        total: 0,
        right: 0
    })


    const [activeIndex, setActiveIndex] = useState(0)
    const [quizData, setQuizData] = useState(false)
    const [view, setView] = useState("mobile")

    const [loader, setLoader] = useState({
        fetch: true
    })


    const fetchUserQuiz = () => {
        let data = { id }
        dispatch(onFetchUserQuiz(data, setQuizData, loader, setLoader))
    }


    useEffect(() => {
        if (quizData && quizData.responseData) {
            if (quizData.responseData.length > 0) {

                let correct = 0
                quizData.responseData.forEach((curElem) => {
                    correct += curElem.userAnswer === curElem.answer ? 1 : 0
                })
                setScore({
                    ...score,
                    total: quizData.responseData.length,
                    right: correct
                })
                setPercentage(((correct / quizData.responseData.length) * 100).toFixed(1))
            }
        }
    }, [quizData])

    useEffect(() => {
        if (id) {
            fetchUserQuiz()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Quiz" />
                <Navbar />
                <section className="siteWrap">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className="col-auto">
                                        <h2 className='pageTitle-2'>{quizData.name}</h2>
                                    </div>
                                    <div className="col-auto">
                                        {/* <div className="projectTitle-right">
                                            <button className="demoLink grey">Save as Draft</button>
                                            <button className="demoLink">Publish</button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className='quiz-wrap'>
                                    <h2 className='text-center gradientTxt'><span>Detailed Response Review for  Participants</span></h2>

                                    <div className='quiz-card mt-5'>
                                        {/* <h6 className='text-center pt-2'>Analyze detailed responses for all your participants</h6> */}


                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">

                                            <div className='text-center mt-2'>
                                                <Nav variant="v" className="tabStyleTwo">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first" onClick={() => setView("mobile")}><MdPhoneIphone /></Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second" onClick={() => setView("desktop")}><FaDesktop /></Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </div>

                                            <div className='row pt-5'>
                                                <div className={`col-sm-${view === "mobile" ? "6" : "12"}`}>
                                                    {quizData.responseData.length > 0 ?
                                                        quizData.responseData.map((curElem, index, arr) => {
                                                            return (
                                                                <React.Fragment key={index}>
                                                                    {index === activeIndex ?
                                                                        <ParticipantsResBox
                                                                            activeIndex={activeIndex}
                                                                            setActiveIndex={setActiveIndex}
                                                                            curElem={curElem}
                                                                            index={index}
                                                                            length={arr.length}
                                                                            view={view}
                                                                        /> : null}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        : ""}
                                                </div>

                                                <div className={`col-sm-${view === "mobile" ? "6" : "12"}`}>
                                                    <div className='quiz-prev-wrap large mt-4'>
                                                        <h5 className='gradientTxt text-center'><span>Metrics</span></h5>
                                                        <div className='metrics mt-3'>
                                                            <div className='gradient-box'>
                                                            <div className='metrics-single'>
                                                                <h6>Average score of participants</h6>
                                                                <h3 className='pt-1'>{score.right}/{score.total}</h3>
                                                            </div>
                                                            </div>
                                                            

                                                            <div className='gradient-box mt-3'>
                                                                <div className='metrics-single'>
                                                                    <h6>Percentage</h6>
                                                                    <h3 className='pt-1'>{percentage}%</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    

                                                    <div className='mt-4'>
                                                        <div className='question-number'>

                                                            <div className='row align-items-center gap-2'>
                                                                <div className='col-auto'>
                                                                    <div className='qt-color'></div>
                                                                </div>
                                                                <div className='col ps-0'>Wrong Answer</div>
                                                            </div>

                                                            <div className='row align-items-center gap-2 mt-2 mb-2'>
                                                                <div className='col-auto'>
                                                                    <div className='qt-color green'></div>
                                                                </div>
                                                                <div className='col ps-0'>Right Answer</div>
                                                            </div>

                                                            <div className='row align-items-center gap-2 mb-3'>
                                                                <div className='col-auto'>
                                                                    <div className='qt-color grey'></div>
                                                                </div>
                                                                <div className='col ps-0'>Not Attempted</div>
                                                            </div>

                                                            <ul className={`question-number-list ${view === "mobile" ? "small" : ""}`}>
                                                                {quizData.responseData.length > 0 ?
                                                                    quizData.responseData.map((curElem, index) => {
                                                                        return (
                                                                            <li onClick={() => setActiveIndex(index)} style={{ cursor: "pointer" }}>
                                                                                <div className={`gradient-box ${activeIndex === index ? "active" : ""} ${curElem.answer === curElem.userAnswer ? "correct" :
                                                                                    curElem.userAnswer ? "incorrect" : ""
                                                                                    }`}>
                                                                                    <div className='question-number-single'>
                                                                                        <div className='question-number-single-in'>{index + 1}</div>
                                                                                    </div>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                    : ""}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </Tab.Container>


                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                </section >

                <Footer />

            </>
    )
}

export default ReviewParticipantsName;