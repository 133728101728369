import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchData } from '../../../Redux/Actions/CommonActions';
import { themeColor } from '../../../Global/Global';
import CoursesListRow from './CoursesListRow';
import { onChangeManualData, onHandleCourseChange } from '../../../Redux/Actions/QuizActions';

const ProvideInput = ({ tab }) => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)

  const [language, setLanguage] = useState([])
  const [country, setCountry] = useState([])

  const [courses, setCourses] = useState([])
  const [loader, setLoader] = useState({
    fetch: true
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    dispatch(onChangeManualData(name, value))
  }

  const fetchQuizCahpters = () => {
    let data = {}
    dispatch(fetchData("fetch-quiz-courses", data, setCourses, loader, setLoader))
  }


  const fetchLanguage = () => {
    let data = {}
    dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
  }
  const fetchCountry = () => {
    let data = {}
    dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
  }

  useEffect(() => {
    if (courses.length > 0 && tab.second && quiz.quizSource === "course") {
      if (!quiz.courseContent.courseId) {
        let chapIds = courses[0].chapters.map((curElem) => {
          return {
            id: curElem.id
          }
        })
        dispatch(onHandleCourseChange(true, courses[0].id, chapIds))
        dispatch(onChangeManualData("country", courses[0].country))
        dispatch(onChangeManualData("language", courses[0].language))
      }
    }
  }, [courses, tab, quiz])

  useEffect(() => {
    fetchLanguage()
    fetchCountry()
  }, [])

  useEffect(() => {
    fetchQuizCahpters()
  }, [])

  return (
    <>
      {quiz.quizSource === "course" ?
        <div className='quiz-card'>
          <div className='row justify-content-center'>
            <div className='col-lg-12'>
              <div className='gradient-box'>
                <div className='gradient-box-head'>
                  <p style={{ color: '#FFF' }}>All Your Courses</p>
                  <p style={{ fontSize: '13px' }}>Select Chapters you would like AI to Generate Quiz Questions from </p>
                </div>
                <div className='gradient-box-in'>
                  <div className='slide-selection'>
                    <div className='slide-selection-group'>
                      {courses.length > 0 ?
                        courses.map((curElem, index) => {
                          return (
                            <React.Fragment key={index}>
                              <CoursesListRow
                                curElem={curElem}
                                index={index}
                              />
                            </React.Fragment>
                          )
                        })
                        :
                        <div className='text-center'>
                          {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: themeColor }} /> : "No chapter available in any of the courses."}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        quiz.quizSource === "manual" ?
          <>
            <h2 className='pb-3'>Manual Prompt</h2>
            <div className='quiz-card'>
              <div className='row justify-content-center'>
                <div className='col-lg-8 text-center'>
                  <h5 className='gradientTxt'><span>Type in Specific Topic or Command for your Quiz</span></h5>
                  <p style={{ fontSize: '0.9rem' }} className='pt-2'>👇 Type in a specific command and let our AI Generate a Quiz for you 👇</p>
                  <div className='mycourseInp-wrap for-input full'>
                    <input
                      className='courseInp mycourseInp'
                      type="text"
                      placeholder='Enter a prompt here'
                      name='prompt'
                      value={quiz.prompt}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <label>Country</label>
                  <select
                    className='input'
                    name='country'
                    value={quiz.country}
                    onChange={handleChange}
                    required
                  >
                    {country.length > 0 ?
                      country.map((curElem, index) => {
                        return (
                          <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                        )
                      })
                      : ""}
                  </select>

                </div>
                <div className='col-lg-6 p-3'>
                  <label>Language</label>
                  <select
                    className='input'
                    name='language'
                    value={quiz.language}
                    onChange={handleChange}
                  >
                    {language.length > 0 ?
                      language.filter((curElem) => {
                        return +curElem.id !== 40
                      }).map((curElem, index) => {
                        return (
                          <option key={index} value={curElem.name}>{curElem.name}</option>
                        )
                      })
                      : ""}
                  </select>

                </div>

              </div>
            </div>
          </> : ""
      }
    </>
  )
}

export default ProvideInput