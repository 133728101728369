import React, { useState } from 'react'
import { IoCopyOutline } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import QuestionsBox from './QuestionsBox'
import { onDeleteQuestion, onReorderQuestions } from '../../../Redux/Actions/QuizActions'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import { GrDocumentPdf } from "react-icons/gr";
import QuizPdfView from './QuizPdfView'
import { setAlert } from '../../../Redux/Actions/AlertActions'

const MobileView = ({ view, activeIndex, setActiveIndex }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const quiz = useSelector(state => state.quiz.data)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [isCopy, setIsCopy] = useState(false)

    const [isAnswerKey, setIsAnswerKey] = useState(false)

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const handleDragEnd = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(quiz.quizData, result.source.index, result.destination.index);
        dispatch(onReorderQuestions(items))
    }

    const handleCopyLink = () => {
        if (!isCopy) {
            navigator.clipboard.writeText(`https://coursereelx.reelapps.io/quiz?id=${quiz.id}`);
            dispatch(setAlert("Quiz URL is copied!", "success"))
            setIsCopy(true)
            setTimeout(() => {
                setIsCopy(false)
            }, 5000)
        }
    }


    const deleteQuestion = (indexToDelete, qId) => {
        if (+indexToDelete === +activeIndex) {
            if (indexToDelete === quiz.quizData.length - 1) {
                setActiveIndex(indexToDelete - 1)
            } else {
                setActiveIndex(indexToDelete + 1)
            }
        }
        dispatch(onDeleteQuestion(indexToDelete, qId))
    }

    return (
        <>
            <div className='row pt-5'>
                <div className={`col-sm-${view === "mobile" ? 6 : 12}`} >
                    {quiz.quizData.length > 0 ?
                        quiz.quizData.map((curElem, index, arr) => {
                            return (
                                index === activeIndex ?
                                    <React.Fragment key={index}>
                                        <QuestionsBox
                                            curElem={curElem}
                                            length={arr.length}
                                            index={index}
                                            setActiveIndex={setActiveIndex}
                                            activeIndex={activeIndex}
                                            view={view}
                                            deleteQuestion={deleteQuestion}
                                        />
                                    </React.Fragment> : null
                            )
                        })
                        : ""}
                </div>
                <div className={`col-sm-${view === "mobile" ? 6 : 12}`}>
                    <div className={`quiz-prev-wrap ${view === "mobile" ? "" : "large mt-4"}`} style={{ height: '100%' }}>
                        <h5 className='gradientTxt text-center'><span>Save and Publish</span></h5>
                        <div className='question-type full '>
                            <ul>
                                <li className='px-3'>

                                    <div className='question-type-singlen h-auto'>
                                        <label className='m-0 p-0 d-flex align-items-center gap-2' style={{ cursor: "pointer" }}>
                                            <input
                                                type='checkbox'
                                                checked={isAnswerKey}
                                                onChange={(e) => setIsAnswerKey(e.target.checked)}

                                            /> Include answers in PDF
                                        </label>
                                    </div>

                                    <div className='question-type-single h-auto mt-3' onClick={handleShow}>
                                        <div className='d-flex'>
                                            <h6 className='d-flex align-items-center gap-2'><GrDocumentPdf />Preview & Download as PDF</h6>
                                        </div>
                                    </div>



                                </li>
                            </ul>
                        </div>

                        <div className='quizLink mt-3'>
                            <div className="row">
                                <div className="col">
                                    <h6>Quiz Link</h6>
                                    <p className='pt-2 pb-4' style={{ fontSize: '1rem' }}>Share your Quiz link to allow participants make attempt on quizzes</p>
                                </div>
                                <div className="col-auto">
                                    <img src={require('../../../images/quiz/prev.png')} alt='' />
                                </div>
                            </div>
                        </div>

                        <div className='mt-3'>
                            <div>
                                <input
                                    className='input'
                                    type="text"
                                    placeholder='Quiz Link'
                                    value={`https://coursereelx.reelapps.io/quiz?id=${quiz.id}`}
                                    readOnly
                                />
                            </div>
                            <div className='d-flex justify-content-end pt-2'><a style={{ fontSize: '0.9rem', color: '#0077FF', cursor: "pointer" }} onClick={handleCopyLink}><IoCopyOutline /> <strong>{isCopy ? "Copied" : "Copy Quiz Link"}</strong></a></div>
                        </div>

                    </div>
                </div>
            </div>


            <div className='question-number pt-5'>
                <p className='pb-2' style={{ fontSize: '0.9rem' }}>Drag  each question number to reorder</p>
                <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <ul
                                className='question-number-list'
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {quiz.quizData.length > 0 ?
                                    quiz.quizData.map((curElem, index) => {
                                        return (
                                            <React.Fragment key={index}>
                                                <Draggable key={index} draggableId={`id_${index}`} index={index} isDragDisabled={+auth.user.isClientAccount === 1 ? true : false}>
                                                    {(provided) => (
                                                        <li
                                                            key={index}
                                                            onClick={() => setActiveIndex(index)}
                                                            style={{ cursor: "pointer" }}
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div className={`gradient-box ${index === activeIndex ? "active" : ""}`}>
                                                                <div className='question-number-single'>
                                                                    <div className='question-number-single-in'>{curElem.id}</div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                                {/* {provided.placeholder} */}
                                            </React.Fragment>
                                        )
                                    })
                                    : ""}
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>
            <QuizPdfView
                show={show}
                handleClose={handleClose}
                data={quiz.quizData}
                name={quiz.name}
                id={"quizDataVal"}
                showAnswer={isAnswerKey}
            />

        </>
    )
}

export default MobileView