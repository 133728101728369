import React, { useRef, useState } from 'react'
import ContentEditable from 'react-contenteditable'
import { IoClose } from 'react-icons/io5'
import { MdDragIndicator } from 'react-icons/md'
import { useDispatch } from 'react-redux'
import { setAlert } from '../../../../Redux/Actions/AlertActions'
import CommonAlert from '../../../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'

const YourChapterRows = ({ curElem, index, setFieldArr, provided }) => {
    const dispatch = useDispatch()
    const [isEdit, setIsEdit] = useState(false)
    const tileRef = useRef(null)

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Remove",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Remove",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Remove",
            loader: false,
        })
    }


    const performDelete = () => {
        setSweet({
            ...sweet,
            confirmButtonName: "Removing",
            loader: true
        })
        setTimeout(() => {
            setSweet({
                ...sweet,
                enable: false,
                confirmButtonName: "Remove",
                loader: false
            })
            handleRemove()
        }, 500)
    }

    const handleRemove = () => {
        setFieldArr((draft) => {
            draft.splice(index, 1)
        })
        dispatch(setAlert("Chapter removed successfully!", "success"))
    }

    const handleEdit = () => {
        if (curElem.chapType !== "created") {
            setIsEdit(true)
            tileRef.current.focus()
        }
    }

    const handleChange = (e) => {
        setFieldArr((draft) => {
            draft[index].name = e.target.value
        })
    }

    return (
        <div
            className="chapterList-single"
            onDoubleClick={handleEdit}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
        >
            <span className='chapterList-icon'><MdDragIndicator /></span>
            <div className="chapterList-single-left px-3">
                <ContentEditable
                    innerRef={tileRef}
                    html={curElem.name}
                    disabled={!isEdit}
                    onChange={handleChange}
                    onBlur={() => setIsEdit(false)}
                    style={{ fontSize: '1rem', color: '#FFFFFF' }}
                    tagName='p'
                />
            </div>
            {curElem.chapType === "created" ? null :
                <span className='chapterList-icon'><IoClose onClick={onDelete} style={{ cursor: "pointer" }} title='Remove' /></span>}



            <CommonAlert
                show={sweet.enable}
                message={`Do you want to remove this added chapter?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </div>
    )
}

export default YourChapterRows