import React from 'react'
import { MdDragIndicator } from 'react-icons/md'

const ParticipantsResBox = ({ curElem, index, activeIndex, setActiveIndex, view, length }) => {

    const onPrev = () => {
        if (activeIndex > 0) {
            setActiveIndex(prev => prev - 1)
        }
    }

    const onNext = () => {
        if (activeIndex < length - 1) {
            setActiveIndex(prev => prev + 1)
        }
    }

    return (
        <div className={`quiz-prev-outer ${view === "mobile" ? "" : "large"}`}>
            <div className={`quiz-prev-wrap ${view === "mobile" ? "" : "large"}`}>
            <h5 className='gradientTxt mb-3 text-center'><span>Analysis</span></h5>
                <div className='gradient-box'>
                
                    <div className='quiz-prev-in'>
                        <div className='text-right'>
                        </div>
                        <div className='quiz-prev-box'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <span>Question {index + 1}/{length}</span>
                            </div>
                            <div className='edit-area mt-3'>
                                <p>{curElem.question}</p>
                            </div>
                        </div>

                        {curElem.options && curElem.options.length > 0 ?
                            <>
                                <div className='selected-ans'>
                                    {curElem.options.map((curData, ind) => {
                                        return (
                                            <div
                                                key={ind}
                                                className={`selected-ans-single ${(curElem.answer === curElem.userAnswer) && (curData === curElem.userAnswer) ? "active" : curElem.userAnswer === curData ? "wrong" : ""} `}
                                            >
                                                {curData}
                                                <MdDragIndicator />
                                            </div>
                                        )
                                    }
                                    )}
                                </div>
                            </>
                            : ""}
                        <div className='row pt-5'>
                            <div className='col-6'>
                                <button className='qz-button' onClick={onPrev}>&lt;&lt; Prev</button>
                            </div>
                            <div className='col-6'>
                                <button className='qz-button blue' onClick={onNext}>Next &gt;&gt;</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <div className='quiz-prev-nav' onClick={onPrev}><img src={require('../../../images/quiz/nav-prev.svg').default} alt='' /></div>
            <div className='quiz-prev-nav alt' onClick={onNext}><img src={require('../../../images/quiz/nav-next.svg').default} alt='' /></div>

        </div >
    )
}

export default ParticipantsResBox