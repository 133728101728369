import React, { useEffect, useState } from "react";
import { FiCopy } from "react-icons/fi";
import { Link, useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import queryString from "query-string";
import { onCheckedToggle, onFetchLanding, onFetchOptin, onToggleOptins, onUpdateLanding, onUpdateOptin } from "../../../../Redux/Actions/ProjectActions";

import HeaderSection from "./HeaderSection";
import VideoSection from "./VideoSection";
import FooterSection from "./FooterSection";
import Footer from "../../../CommonComponents/Footer/Footer";
import TitleBar from "../../../CommonComponents/TitleBar";
import AddonDomain from "./AddonDomain";
import PageStyleSection from "./PageStyleSection";
import BonusSection from "./BonusSection";
import BenifitSection from "./BenifitSection";
import ThankyouSection from "./ThankyouSection";
import Navbar from "../../../CommonComponents/Navs/Navbar";
import SideNav from "../../../CommonComponents/Navs/SideNav";


const CustomizeWebsite = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id = queryString.parse(location.search).id
  const landingData = useSelector(state => state.bonus.data)

  const [loader, setLoader] = useState({
    submitLoader: false,
    pageLoader: true
  })

  const [addon, setAddon] = useState(false)

  const fetchLandingData = () => {
    let data = {
      id: id
    }
    dispatch(onFetchLanding(data, loader, setLoader))
  }

  const handleChecked = (e) => {
    const { name, checked } = e.target
    if (name === "addon") {
      if (addon) {
        setAddon(false)
      } else {
        setAddon(true)
      }
    }
    dispatch(onCheckedToggle(name, checked))
  }

  const onCopyLink = () => {
    var copyText = document.getElementById("liveLink");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
  }

  const handleSubmit = () => {
    let data = {
      ...landingData,
      method: ""
    }
    delete data.domain
    setLoader({
      ...loader,
      submitLoader: true
    })
    dispatch(onUpdateLanding(data, navigate, loader, setLoader))
  }

  const handlePreview = () => {
    window.open(landingData.liveLink, "_blank")
  }

  useEffect(() => {
    if (id) {
      fetchLandingData()
    }
  }, [id])

  useEffect(() => {
    if (landingData.domain.enable) {
      setAddon(landingData.domain.enable)
    }
  }, [landingData])

  return (
    loader.pageLoader ?
      <div className="loader-sec">
        <div className="loader" />
      </div>
      :
      <>
        <TitleBar title="Dashboard" />
        <Navbar />
        <section className="siteWrap">
          <div className="cont_wrap">
            <div className="side_bar"><SideNav /></div>
            <div className="cont_area">

              <div className="projectTitle-wrap">
                <div className="projectTitle row">
                  <div className="col-sm-6">
                    <h3 className="gradientTxt"><span>Edit Landing Page</span></h3>
                  </div>
                  <div className="col-auto">
                    <div className="projectTitle-right pt-3 pt-sm-0">
                      <Link className="demoLink grey m-0" to={`/landing-page/website-editor?liveLink=${landingData.liveLink}&productId=${id}`} target="_blank"><span>Edit in WYSIWYG</span></Link>
                      <button onClick={handlePreview} className="demoLink m-0" ><span>Preview</span></button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="business-wrap">
                  <div className="business-list">
                    <div className="inpLabelWrap mt-0">
                      <span className="labelTxt mb-4"><strong>Live Link</strong></span>

                      <div className="inpLabel">
                        <label htmlhtmlFor="liveLink">Live Link</label>
                        <input
                          className="inpLabel-inp"
                          value={landingData.liveLink}
                          type="text"
                          readOnly
                          id="liveLink"
                        />
                        <span className="inpBtn-icon " style={{ cursor: "pointer" }} onClick={onCopyLink}>
                          <FiCopy /> <span className="ps-1">Copy</span>
                        </span>
                      </div>
                    </div>

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Add Your Domain</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            onChange={(e) => handleChecked(e)}
                            name="addon"
                            checked={addon}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      addon ?
                        <AddonDomain />
                        : ""
                    }

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Page Style</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="style"
                            checked={landingData.style.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {landingData.style.enable ?
                      <PageStyleSection /> : ""}

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Header Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="header"
                            checked={landingData.headers.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.headers.enable ?
                        <HeaderSection />
                        : ""
                    }

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Video Embed Code Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="videos"
                            checked={landingData.videos.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round">
                          </span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.videos.enable ?
                        <VideoSection />
                        : ""
                    }

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Product Features Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="benefits"
                            checked={landingData.benefits.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.benefits.enable ?
                        <BenifitSection />
                        : ""
                    }

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Bonus Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="bonus"
                            checked={landingData.bonus.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.bonus.enable ?
                        <BonusSection />
                        : ""
                    }

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Thank You Page Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="thankyou"
                            checked={landingData.thankyou.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.thankyou.enable ?
                        <ThankyouSection />
                        : ""
                    }
                    {/* <AicoachSection /> */}

                    <div className="inpLabel d-flex align-items-center justify-content-between line pt-4 pb-4">
                      <label htmlFor="">Footer Section</label>
                      <div className="d-flex">
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="footer"
                            checked={landingData.footer.enable}
                            onChange={(e) => handleChecked(e)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    {
                      landingData.footer.enable ?
                        <FooterSection />
                        : ""
                    }

                    <div className="btn-sec text-end mt-4">
                      <button onClick={handleSubmit} className="demoLink m-0"><span>{loader.submitLoader ? <>Updating <i className="fa fa-spinner fa-spin mx-1" /></> : "Update"}</span></button>
                    </div>


                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

        {/* <TitleBar title="Bonus Page" />
        <div className="page-wrap">
          
        </div> */}
        <Footer />
      </>
  )
}

export default CustomizeWebsite;