import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import { BsStars } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { onCreateLanding } from '../../../Redux/Actions/LandingActions';
import { useNavigate } from 'react-router';
import { fetchData } from '../../../Redux/Actions/CommonActions';

const CreateLandingModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loader, setLoader] = useState({
        create: false
    })
    const [data, setData] = useState({
        name: "",
        // language: "English",
        // languageCode: "en-US",
        // country: "United States",
    })
    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            ...data
        }
        // let count = country.find(({ country_name }) => country_name === data.country)
        // let lang = language.find(({ name }) => name === data.language)
        // if (count && lang) {
        //     obj.languageCode = `${lang.code}-${count.code}`
        // }
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateLanding(obj, navigate, loader, setLoader))
    }

    // const fetchLanguage = () => {
    //     let data = {}
    //     dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    // }
    // const fetchCountry = () => {
    //     let data = {}
    //     dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    // }

    // useEffect(() => {
    //     fetchLanguage()
    //     fetchCountry()
    // }, [])


    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec  p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Enter your product name</h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            name='name'
                            value={data.name}
                            placeholder="Give a name to your landing page..."
                            onChange={handleChange}
                            required
                        />
                    </div>
                    {/* <div className='row'>
                        <div className='col-6 p-3'>
                            <label>Country</label>
                            <select
                                className='inpField-inp'
                                name='country'
                                value={data.country}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select Country</option>
                                {country.length > 0 ?
                                    country.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                        <div className='col-6 p-3'>
                            <label>Language</label>
                            <select
                                className='inpField-inp'
                                name='language'
                                value={data.language}
                                onChange={handleChange}
                            >
                                {language.length > 0 ?
                                    language.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                    </div> */}
                    <div className='text-center'>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn">Next {loader.create ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateLandingModal