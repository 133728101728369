import React from "react";
import TrainingTitles from "./TrainingTitles";
import queryString from "query-string";
import { useSelector } from "react-redux";
import TitleBar from "../CommonComponents/TitleBar";
import Navbar from "../CommonComponents/Navs/Navbar";
import { Link, useLocation } from "react-router";
import Footer from "../CommonComponents/Footer/Footer";
import SideNav from "../CommonComponents/Navs/SideNav";

const TrainingArticles = () => {
    const location = useLocation()
    const id = queryString.parse(location.search).id;
    const art_id = queryString.parse(location.search).art_id;

    const articleData = useSelector(state => state.help);
    const articleData2 = useSelector(state => state.help.article);

    const filterArticle = articleData2.filter(article => article.id === id)[0];
    const filterName = filterArticle.items.filter((id) => id.id == art_id)[0];


    return (
        <>
            <TitleBar title="Training" />
            <Navbar />

            <section className="siteWrap">
                {/* <div className="siteWrap-in"> */}
                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">
                        <div className="tabCont">
                            <div className="row justify-content-center">
                                <TrainingTitles id={id} supportData={articleData} name={filterName} />
                                <div className="col-lg-8 tabRight" style={{ paddingTop: 0 }}>
                                    <div className="tabCont-right">

                                        <div className="tabepaneIn forTraining">
                                            <h2 className="tabTitle">
                                                <Link className="vidoebtn" to="/help-and-support"><span className="mr-1">
                                                    <i className="fas fa-arrow-left"></i></span> Back to Video Training
                                                </Link>
                                            </h2>

                                            <div className="tab-content mt-4" id="v-pills-tabContent">
                                                <div className="tab-pane fade show active" id="v-pills-client" role="tabpanel" aria-labelledby="v-pills-client-tab">

                                                    {
                                                        filterArticle.items.length > 0 ?
                                                            filterArticle.items.map((item, index) => {
                                                                return (
                                                                    item.id == art_id ?
                                                                        <>
                                                                            <div className="tabepaneIn-main">
                                                                                <div className="tabepaneIn-cont tabepaneIn-cont-alt">

                                                                                    <div className="text-white"
                                                                                        dangerouslySetInnerHTML={{
                                                                                            __html: item.content
                                                                                        }}>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                        : ''
                                                                )
                                                            })
                                                            : ''
                                                    }

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <Footer />
        </>
    )
}

export default TrainingArticles;