import React, { useState } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import logo from '../../images/LOGO.png';
import { Link, useLocation, useNavigate } from "react-router";
import { resetPassword } from "../../Redux/Actions/AuthActions";
import TitleBar from "../CommonComponents/TitleBar";
import LoginFooter from "../CommonComponents/Footer/LoginFooter";
import { appName } from "../../Global/Global";

const ResetPassword = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    let location = useLocation()
    const [view1, setView1] = useState(false)
    const [view2, setView2] = useState(false)

    const [loader, setLoader] = useState(false)
    const [loginInfo, setLoginInfo] = useState({
        password: null,
        confirm_password: null,
        password_token: queryString.parse(location.search).token
    })

    const [passwordMsg, setPasswordMsg] = useState({
        msg: "",
        validation: false
    })


    const handleChange = (e) => {
        const { name, value } = e.target
        setLoginInfo({
            ...loginInfo,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9\s]).{6,}$/;
        if (loginInfo.password) {
            if (pattern.test(loginInfo.password)) {
                if ((loginInfo.password === loginInfo.confirm_password)) {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is valid!", validation: true })
                    setLoader(true);
                    dispatch(resetPassword(loginInfo, setLoader, navigate));
                } else {
                    setPasswordMsg({ ...passwordMsg, msg: "Password is not matched!", validation: true })
                }
            } else {
                setPasswordMsg({ ...passwordMsg, msg: "Password must contain at least 1 lowercase letter, 1 uppercase letter, 1 digit, 1 alphanumeric and be at least 8 characters long.", validation: true })
            }
        }
    }

    return (
        <>

            <TitleBar title="Reset Password" />

            <header className="loginHeader">
                <div className="container-fluid">
                    <div className="loginLogo">
                        <img src={logo} alt="logo" />
                    </div>
                </div>
            </header>

            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2 className='gradientTxt alt'>Welcome To <br /> <span>{appName}</span></h2>
                                <p>Take your course creation to the next level with cutting-edge AI tools designed to save time, enhance engagement, and maximize your profits. From personalized video content to fully automated marketing assets, everything you need to create, customize, and sell professional-grade courses is at your fingertips.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Reset</span> Password</h2>
                                    <form method="post" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                placeholder="************"
                                                required
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input
                                                type="password"
                                                name="confirm_password"
                                                className="form-control"
                                                onChange={handleChange}
                                                placeholder="************"
                                                required
                                            />
                                        </div>
                                        <p style={{ fontSize: 13, margin: "5px", color: "#DC143C" }}>{passwordMsg.validation ? passwordMsg.msg : ""}</p>

                                        <div className="form-group">
                                            <button type="submit" className="demoLink btn-block m-0" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Reset Password
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Login ? <Link to="/login" > Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <LoginFooter />
        </>
    )
}


export default ResetPassword;