import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { onFetchLang, onFetchVoice, onGenerateTts, onGenerateTtsAll, updateTtsText, updateTtsUrl } from '../../../Redux/Actions/VideoActions';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { generationDisableTTS } from '../../../Redux/Actions/EditorActions';
import iconSave from "../../../images/icon-save.svg";
import { themeColor } from '../../../Global/Global';


let ttsAudio = false;
const TextToSpeech = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const editor = useSelector(state => state.editor)

    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [addAllText, setAddAllText] = useState(false)
    const [pollyLanguages, setPollyLanguages] = useState({ data: [] });
    const [pollyVoices, setPollyVoices] = useState({ data: [] });
    const [engine, setEngine] = useState("")
    const [ttsStatus, setTtsStatus] = useState(true);
    const [ttsText, setTtsText] = useState("")
    const [currentTts, setCurrentTts] = useState('');
    const [loader, setLoader] = useState(false);
    const [langData, setLangData] = useState({
        lang: '',
        voice: '',
        voiceId: "",
    })
    const [generateData, setGenerateData] = useState({
        url: "",
        duration: 0
    })

    const getLanguage = (e) => {
        const vId = pollyLanguages.data.find(({ code }) => code === e.target.value)
        if (vId) {
            fetchPollyVoices(vId.id);
        }
        setTtsStatus(true)
        setLangData({
            ...langData,
            lang: e.target.value
        })
    }
    const getVoices = (e) => {
        const data = pollyVoices.data.find(({ id }) => id === e.target.value)
        if (data) {
            setLangData({
                ...langData,
                voice: data.voice_id,
                voiceId: e.target.value
            })
            setEngine(data.type)
        } else {
            setLangData({
                ...langData,
                voice: "",
                voiceId: ""
            })
            setEngine("")
        }
        setTtsStatus(true)
    }

    const playTts = (url) => {
        if (ttsAudio !== false) {
            ttsAudio.pause();
        }
        if (currentTts === url) {
            ttsAudio.pause();
            setCurrentTts('')
        } else {
            ttsAudio = new Audio(url);
            ttsAudio.play();

            ttsAudio.onended = function () {
                setCurrentTts('')
            }
            setCurrentTts(url);
        }
    }

    const generateTts = () => {
        if (ttsText !== "" && langData.lang !== "" && langData.voice !== "") {
            setLoader(true);
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                text: ttsText,
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTts(data, generateData, setGenerateData, setLoader, setTtsStatus))

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Please, add text, language & voice in TTS!',
                confirmButtonColor: themeColor
            })
        }
    }

    const onEditTts = (e) => {
        setTtsText(e.target.value)
        setTtsStatus(true)
        dispatch(updateTtsText(e.target.value, selectedSlideIndex))
    }

    const applyTts = () => {
        if (+generateData.duration <= 30) {
            dispatch(updateTtsUrl(generateData, langData, selectedSlideIndex))
        } else {
            dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
        }
    }

    const fetchPollyLanguages = () => {
        dispatch(onFetchLang(pollyLanguages, setPollyLanguages))
    }

    const fetchPollyVoices = (value, langCode) => {
        dispatch(onFetchVoice(value, pollyVoices, setPollyVoices, setLangData, langData, selectedSlide, langCode, setEngine))
    }

    const handleGenerateAll = (e) => {
        dispatch(generationDisableTTS())
        setTtsStatus(true)
        if (e.target.checked) {
            let engineVal
            if (engine === "Neural") {
                engineVal = engine.replace("Neural", "neural")
            }
            else {
                engineVal = engine.replace("Standard", "standard")
            }
            let data = {
                language_id: langData.lang,
                voice_id: langData.voice,
                engine: engineVal
            }
            dispatch(onGenerateTtsAll(campaign.slides, data))
        }
    }


    useEffect(() => {
        if (addAllText) {
            setAddAllText(false)
        }
        setTtsStatus(true)
        if (selectedSlideIndex !== -1) {
            let vId = pollyLanguages.data.find(({ code }) => code === selectedSlide.voiceOver.meta.languageId)
            if (vId) {
                fetchPollyVoices(vId.id, selectedSlide.voiceOver.meta.languageId)
            }
            setTtsText(selectedSlide.voiceOver.meta.text)
        }
    }, [selectedSlideIndex, pollyLanguages])

    useEffect(() => {
        fetchPollyLanguages()
    }, [])

    return (

        <div className="innertab">
            <div className="alert alert-warning text-center">
                This voiceover would only be added to current slide
            </div>
            <div className="innertab-scroll alt">
                <div className="ai-voice-block">
                    <div className="voice-block-top">
                        <div className="row">
                            <div className="col-3">
                                <input className="" type="text" name="slide" value={selectedSlideIndex + 1} readOnly />
                                <span className="optDesc text-light">SLIDE NO.</span>
                            </div>
                            <div className="col-4">
                                <select
                                    onChange={(e) => getLanguage(e)}
                                    value={langData.lang}
                                >
                                    <option value={""}>Select Language</option>
                                    {
                                        pollyLanguages.data.length > 0 ?
                                            pollyLanguages.data.map((item, index) => {
                                                return (
                                                    <option key={index} value={item.code}>{item.name}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">LANGUAGE</span>
                            </div>
                            <div className="col-5">
                                <select
                                    onChange={(e) => getVoices(e)}
                                    value={langData.voiceId}
                                    className="cursor-pointer text-hover-effect"
                                >
                                    <option disabled selected>Select Voice</option>
                                    {
                                        pollyVoices.data.length > 0 ?
                                            pollyVoices.data.map((item, index) => {

                                                return (
                                                    <option key={index} value={item.id}>{item.voice_id} ({item.gender}), {item.type}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                                <span className="optDesc text-light">VOICE</span>
                            </div>
                        </div>
                    </div>
                    <div className="voice-block-mid">
                        <textarea
                            placeholder="Add text here"
                            onChange={(e) => onEditTts(e)}
                            value={ttsText}
                        />
                        {/* <div className="row">
                            <div className="voice-check-box  ">
                                <label htmlFor='addTtsAll' className='d-flex'>
                                    <input
                                        type="checkbox"
                                        id='addTtsAll'
                                        checked={addAllText}
                                        onChange={handleGenerateAll} style={{ width: '40px', height: "19px", }}
                                    />
                                    <span className='align-self-center' style={{ fontSize: '13px' }}>Load all layers text from the slide</span>
                                </label>
                            </div>
                        </div> */}
                    </div>
                    <div className="voice-block-bottom">
                        <div className="row">
                            <div className="col-lg-7">
                                {ttsStatus ?
                                    <button disabled={loader} className="demoLink btn-block mb-sm-2 justify-content-center" onClick={() => generateTts()} >
                                        <span className="mr-1">
                                            <i className="fas fa-file-upload" />
                                        </span>
                                        {loader ?
                                            <>
                                                Generating
                                                <i className="fa fa-spinner fa-spin mx-1" />
                                            </> : "Generate"}

                                    </button>
                                    :
                                    <button className="demoLink btn-block " onClick={() => playTts(generateData.url)} >
                                        <span className="mr-1">
                                            <i className={`fas ${currentTts === generateData.url ? 'fa-pause-circle' : 'fa-play-circle'}`} />
                                        </span>
                                        Preview
                                    </button>
                                }
                            </div>
                            <div className="col-lg-5" >
                                <button
                                    onClick={() => applyTts()}
                                    className={`${ttsStatus ? "disable-button" : ''} demoLink btn-block`}
                                    disabled={ttsStatus}
                                    style={{ background: ttsStatus ? "gray" : "" }}

                                >
                                    <img src={iconSave} />{" "}
                                    Use
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                {/* <TranslateText
                    selectedSlideIndex={selectedSlideIndex}
                    selectedSlide={selectedSlide}
                    setDataToRevert={setDataToRevert}
                /> */}

            </div>
        </div>
    );
}

export default TextToSpeech;
