import React from "react";
import ReelAppsLogo from '../../../images/ReelApps.png';
import {useSelector} from "react-redux";

const Footer = () => {

    const rebrandData = useSelector(state => state.rebrand);

    return(
        <>
            <footer className="appFooter innerPage">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-3">
                            <div className="footer-single">
                            <ul className="footer-list-left">
                                    <li><a href="http://reelapps.io/terms.html" target="_blank">Terms </a> | </li> 
                                    <li><a href="http://reelapps.io/privacy.html" target="_blank">Privacy Policy</a>  | </li>
                                    <li><a href="http://support.vineasx.com/" target="_blank">SUPPORT</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="footer-single">
                                <p>You are interacting with AI system. Content Safety & Content Moderation Limits Applied.</p>

                                <p>© {(new Date().getFullYear())}, All Rights Reserved to <a href="https://vineasx.com/" target="_blank">VineaSX Solutions LLC.</a> Developed and Maintained by <a href="http://www.vega6.com/" target="_blank">Vega6</a></p>

                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="footer-single reelLogo">
                                {/* {
                                    rebrandData.data !== false ?
                                        <a>
                                            <img src={rebrandData.data.logo} className="img-fluid ml-auto logo-image-css" alt="logo"  width="180px" />
                                        </a> : */}
                                        <a href="https://reelapps.io/" target="_blank"><img src={ReelAppsLogo} alt={'logo'} /></a>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default React.memo(Footer);