import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai';
import iconData from '../../../../../Global/icon.json';
import modalCloseIcon from '../../../../../images/modal-close.png';

const IconsModal = (props) => {
    const [iconList, setIconList] = useState([])
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })
    const [selected, setSelected] = useState(-1)
    const [search, setSearch] = useState("")
    const handleChange = (e) => {
        if (iconList.length > 0) {
            setIconList([])
        }
        setSearch(e.target.value)
    }



    const onChooseImage = (curElem, index) => {
        setSelected(curElem)
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            props.callbackFun(selected, props.iconIndex)
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);
    }

    useEffect(() => {
        if (!props.show) {
            setSelected(-1)
        }
    }, [props.show])


    return (
        <Modal className="theme-modal VideoModal modalIcon_modal xl" show={props.show} centered>
            <Modal.Body>
                {/* <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div>  */}
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <div className="imgUp">
                    {/* <div className='inpLabel m-0'>
                        <label style={{ fontSize: 15 }}>Icons</label>
                    </div> */}
                    <div className="imgUp-main mt-1">

                        <div className="project-input-box" >
                            <div className='project-input-in'>
                                <input
                                    type="text"
                                    className='inp'
                                    placeholder="Search Icon"
                                    value={search}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>
                        </div>

                        <div className='landing-items pt-4'>
                            {
                                iconData.length > 0 ?
                                    iconData.filter(
                                        icon => icon.toLowerCase().indexOf('fa-' + search.toLowerCase()) > -1
                                    ).map((item, index) => {
                                        return (
                                            <a onClick={(e) => onChooseImage(item)} key={index} className="emoji-custom" style={{ cursor: "pointer" }}>
                                                <i style={{ color: selected === item ? "#3f429b" : "" }} className={`fa ${item}`} aria-hidden="true"></i>
                                            </a>
                                        )
                                    })
                                    : ''
                            }
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end gap-2" style={{ marginTop: "25px" }}>
                        <button className="demoLink m-0 grey" onClick={props.handleClose}><span>Cancel</span></button>
                        <button className={`demoLink m-0 ${selected === -1 ? "grey" : ""}`} disabled={selected === -1} onClick={handleSave}><span> {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin" /></> : "Use"}</span></button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default IconsModal