import React, { useEffect, useState } from 'react';
import { FaRegTrashCan, FaSpinner } from 'react-icons/fa6';
import { IoPause, IoPlay } from 'react-icons/io5';
import { TiUser } from 'react-icons/ti';
import { useDispatch, useSelector } from 'react-redux';
// import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions';
import { FaCheck, FaTrash } from 'react-icons/fa';
// import { onSetClonedVoice } from '../../../Redux/Actions/PoseActions';
import { onDeleteCampaign } from '../../../../Redux/Actions/CommonActions';
import CommonAlert from '../../../CommonComponents/CommonAlert';
import { setAlert } from '../../../../Redux/Actions/AlertActions';

let music = false
const VoiceRows = ({ curElem, type, cloneVoices, setCloneVoices, state, setState }) => {
    const dispatch = useDispatch()
    const [play, setPlay] = useState(false)

    const [loader, setLoader] = useState({
        save: false,
        playRecording: false
    })
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false

    })

    const handleSelectVoice = () => {
        dispatch(setAlert("Voice applied successfully!", "success"))
        setState({
            ...state,
            voiceClone: {
                ...state.voiceClone,
                cloneId: curElem.cloneId
            }
        })
    }

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-clone-voice", data, cloneVoices, setCloneVoices, setSweet))
    }


    const handlePlay = () => {
        if (curElem.url) {
            // props.setSelectedPlay(curElem.url)
            if (music !== false) {
                music.pause()
                setPlay(false)
            }
            setLoader({
                ...loader,
                playRecording: true
            })
            music = new Audio(curElem.url)
            music.play()
                .then(r => {
                    setLoader({
                        ...loader,
                        playRecording: false
                    })
                    setPlay(true)
                }
                )
                .catch(err => console.log(err));

            music.onended = () => {
                setPlay(false)
            }
        }
    }

    const handlePause = () => {
        if (music !== false) {
            music.pause()
            setPlay(false)
            music = false
            // props.setSelectedPlay(false)
        }
    }



    useEffect(() => {
        return () => {
            if (music !== false) {
                music.pause();
            }
        }
    }, [])

    return (
        <tr className="embed-single" >
            <td className="embed-user">
                {type === "clone" ? null :
                    <div className="embed-user-image ms-2"><img src={curElem.image} alt="" /></div>
                }
                <div className="embed-user-txt">
                    <h6>{curElem.name}</h6>
                    {type === "clone" ? null :
                        <p className="d-flex align-items-center gap-1"><TiUser /> {curElem.gender}</p>
                    }
                </div>
            </td>
            <td>
                <div className='text-capitalize'><span>{curElem.type}</span></div>

            </td>
            <td>
                <div>
                    <span>{curElem.created}</span>
                </div>
            </td>
            <td className=''>
                <div className="embed-user-icon" title='Use' style={{ cursor: "pointer" }} onClick={handleSelectVoice}><FaCheck color={state.voiceClone.cloneId === curElem.cloneId ? "green" : ""} /></div>
                {curElem.url ?
                    play ?
                        <div className="embed-user-icon" style={{ cursor: "pointer" }} title='Pause' onClick={handlePause}><IoPause /></div>
                        :
                        loader.playRecording ?
                            <div className="embed-user-icon" ><i className="fa fa-spinner fa-spin" /> </div>
                            :
                            <div className="embed-user-icon" title='Play' style={{ cursor: "pointer" }} onClick={handlePlay}><IoPlay /></div>
                    : null
                }
                {type === "clone" ?
                    <div className="embed-user-icon" style={{ cursor: "pointer" }} title='Delete' onClick={onDelete}><FaRegTrashCan /></div>
                    : ""}
            </td>

            <CommonAlert
                show={sweet.enable}
                message={"Are you sure you want to delete this voice?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<FaTrash />}
            />
        </tr>
    );
}

export default VoiceRows;
