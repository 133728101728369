import React, { useEffect, useState } from 'react'
import dummyImage from "../../../images/fav-new.png"
import { useNavigate } from 'react-router'
import { FaTrash } from 'react-icons/fa'
import CommonAlert from '../../CommonComponents/CommonAlert'
import { Trash } from 'iconsax-react'
import { useDispatch } from 'react-redux'
import { onDeleteCampaign } from '../../../Redux/Actions/CommonActions'
import { FaExternalLinkAlt } from "react-icons/fa";

import { GoLinkExternal } from "react-icons/go";
import { FiTrash2 } from "react-icons/fi";
import { useSelector } from 'react-redux'

const ParticipantRows = ({ curElem, index, participant, setParticipant }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const auth = useSelector(state => state.auth)
    const [percentage, setPercentage] = useState(0)
    const [time, setTime] = useState({
        h: 0,
        m: 0,
        s: 0
    })

    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }


    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "quizResponse"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, participant, setParticipant, setSweet))
    }


    const handleRedirect = () => {
        navigate(`/participants/review-participants?id=${curElem.id}`)
    }

    useEffect(() => {
        if (curElem) {
            if (curElem.responseData && curElem.responseData.length > 0) {
                let correct = 0
                curElem.responseData.forEach((curData) => {
                    correct += curData.answer === curData.userAnswer ? 1 : 0
                })
                setPercentage(((correct / curElem.responseData.length) * 100).toFixed(1))
            }
            if (curElem.completionTime) {
                setTime({
                    ...time,
                    s: parseInt((+curElem.completionTime % 60)),
                    m: Math.floor((+curElem.completionTime % 3600 / 60)),
                    h: parseInt((+curElem.completionTime / 3600))
                })
            }
        }
    }, [curElem])


    return (
        <tr>
            <td>{index + 1}</td>
            <td>
                <div className="row align-items-center">
                    <div className="col-auto">
                        <div className='userImg'><img src={dummyImage} alt="" /></div>
                    </div>
                    <div className="col ps-0">
                        <h6>{curElem.firstName} {curElem.lastName}</h6>
                        <p>{curElem.email}</p>
                    </div>
                </div>
            </td>
            <td>{curElem.age}</td>
            <td className='text-capitalize'>{curElem.gender}</td>
            <td>{percentage}%</td>
            <td>{time.h < 10 ? "0" : ""}{time.h} : {time.m < 10 ? "0" : ""}{time.m} : {time.s < 10 ? "0" : ""}{time.s}</td>
            <td>
                {curElem.responseData ?
                    <span style={{ cursor: "pointer", fontSize: '1.2rem' }} onClick={handleRedirect} title='View'><GoLinkExternal /></span> : ""}
                {+auth.user.isClientAccount === 1 ? null :
                    <span style={{ cursor: "pointer", fontSize: '1.2rem' }} onClick={onDelete} title='Delete'><FiTrash2 /></span>}
            </td>
            {/* <td>
                <div className='style-checkbox'>
                    <input type="checkbox" />
                    <span className='checkbox-select'></span>
                </div>
            </td> */}

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this participant quiz?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </tr>
    )
}

export default ParticipantRows