import React from 'react'
import { appName } from '../../../Global/Global'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import GeneratePdf from '../../CommonComponents/GeneratePdf';

const QuizPdfView = (props) => {
    return (
        <Modal className="VideoModal" show={props.show} onHide={props.handleClose} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <div style={{ height: 600, overflowY: "auto" }}>
                    <div className='row text-dark p-4 m-4' id={props.id} style={{ position: "relative", background: "#ffffff" }}>
                        <div className='col-md-12 mb-5'>
                            <h4 className='text-center'>Quiz: {props.name}</h4>
                            {props.data.length > 0 ?
                                props.data.map((curElem, index) => {
                                    return (
                                        <div className='mt-5' key={index}>
                                            <p style={{ color: "#000", fontSize: 17 }} ><span style={{ fontWeight: 500, marginRight: 5 }}>{index + 1}.</span> {curElem.question}</p>
                                            {curElem.options && curElem.options.length > 0 ?
                                                <ul className='p-2' >
                                                    {curElem.options.map((curData, ind) => {
                                                        return (
                                                            <li style={{ color: "#000", fontSize: 16, marginBottom: 5 }} key={ind}>
                                                                <span style={{ fontWeight: 500, marginRight: 5 }}>{ind === 0 ? "A" : ind === 1 ? "B" : ind === 2 ? "C" : "D"}.</span>{curData}</li>
                                                        )
                                                    })}
                                                </ul> : ""}
                                        </div>
                                    )
                                })
                                : ""}

                        </div>
                        {props.showAnswer ?
                            <div className='mt-3'>
                                <h4 className='text-center'>Answere Key</h4>
                                {props.data.length > 0 ?
                                    props.data.map((curElem, index) => {
                                        return (
                                            <div className='mt-5' key={index}>
                                                <p style={{ color: "#000", fontSize: 17 }} >
                                                    <span style={{ fontWeight: 500, marginRight: 5 }}>{index + 1}.</span> {curElem.question}
                                                </p>
                                                {curElem.options && curElem.options.length > 0 ?
                                                    <ul className='p-2' >
                                                        {curElem.options.map((curData, ind) => {
                                                            return (
                                                                curData === curElem.answer ?
                                                                    <li style={{ color: "#000", fontSize: 16, marginBottom: 5 }} key={ind}>
                                                                        <span style={{ fontWeight: 500, marginRight: 5 }}>Answere: {ind === 0 ? "A" : ind === 1 ? "B" : ind === 2 ? "C" : "D"}.</span>{curData}</li> : ""
                                                            )
                                                        })}
                                                    </ul> :
                                                    <ul className='p-2' >
                                                        <li style={{ color: "#000", fontSize: 16, marginBottom: 5 }}>
                                                            <span style={{ fontWeight: 500, marginRight: 5 }}> Answere: </span>{curElem.answer}</li>
                                                    </ul>
                                                }
                                            </div>
                                        )
                                    })
                                    : ""}
                            </div> : ""}
                        <h6 className='text-end' style={{ position: "absolute", bottom: 5, right: 5 }}>This PDF is Generated By {appName}</h6>
                    </div>
                </div>
                <div className='text-right'>
                    <GeneratePdf
                        fileName={props.name}
                        contentId={props.id}
                    >
                        <button className='demoLink'>Download PDF</button>
                    </GeneratePdf>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default QuizPdfView