import React, { useEffect, useState } from 'react'
import SideNav from '../../CommonComponents/Navs/SideNav'
import Navbar from '../../CommonComponents/Navs/Navbar'
import TitleBar from '../../CommonComponents/TitleBar'
import { TbEdit } from 'react-icons/tb'
import Footer from '../../CommonComponents/Footer/Footer'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { onFetchPromoVideo, onGeneratePromoVideo } from '../../../Redux/Actions/VideoActions'
import queryString from 'query-string'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import WriteDataModal from '../../Courses/WriteChapter/WriteDataModal'
import { IoArrowForwardOutline } from 'react-icons/io5'

const ReviewScript = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = queryString.parse(location.search)


    const [promoData, setPromoData] = useState(false)
    const [loader, setLoader] = useState({
        fetch: false,
        generate: false,
        save: false
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleSubmit = (type) => {
        if (promoData.script) {
            let obj = { ...promoData }
            obj.buttonType = type
            obj.text = promoData.script

            setLoader({
                ...loader,
                [type]: true
            })
            dispatch(onGeneratePromoVideo(obj, navigate, loader, setLoader))
        } else {
            dispatch(setAlert("Please add script to generate promo video.", "danger"))
        }
    }

    console.log(promoData)


    const fetchVideo = () => {
        let data = { id }
        dispatch(onFetchPromoVideo(data, setPromoData, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchVideo()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Review Script" />
                <Navbar />
                <section className="siteWrap w1">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">
                            <div className="create-course-top flex">
                                <div className="research-head-left" style={{ maxWidth: "800px" }}>
                                </div>
                                <div className="projectTitle-right">
                                    <button
                                        className="demoLink grey m-0"
                                        onClick={() => handleSubmit("save")}
                                        disabled={!promoData.script}
                                        style={promoData.script ? {} : { background: "gray", cursor: "not-allowed" }}
                                    >
                                        {loader.save ? <> Saving <i className="fa fa-spinner fa-spin mr-2" /> </> : "Save Script"}
                                    </button>
                                    <button
                                        className="demoLink m-0"
                                        // onClick={() => handleSubmit("generate")}
                                        onClick={handleShow}
                                        disabled={!promoData.script}
                                        style={promoData.script ? {} : { background: "gray", cursor: "not-allowed" }}
                                    >
                                        Next <IoArrowForwardOutline />
                                        {/* {loader.generate ? <> Generating <i className="fa fa-spinner fa-spin mr-2" /> </> : "Generate"} */}
                                    </button>

                                </div>
                            </div>

                            <div className="container">
                                <div className="cont-research">
                                    <div className="cont-research-body modified">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="research-body-right">

                                                    <div className="title-block">
                                                        <div className="title-block-top">
                                                            <div className="col ps-0">
                                                                <h6 style={{ fontSize: '0.9rem' }}>Write, Edit & Paste Your Content Here</h6>
                                                                {/* <p className="pt-2" style={{ fontSize: '0.85rem' }}>To separate slides press <span style={{ color: '#001EFF' }}>Ctrl + Enter</span></p> */}
                                                            </div>
                                                            <div className="col-auto pe-0 text-right">
                                                                <button className="simple-btn"><TbEdit /></button>
                                                                {/* <p>Slides: {slideLength}</p> */}
                                                            </div>
                                                        </div>
                                                        <div className="title-block-cont">
                                                            <textarea
                                                                className="txtArea sm"
                                                                type="text"
                                                                value={promoData.script}
                                                                name="text"
                                                                onChange={(e) => setPromoData({ ...promoData, script: e.target.value })}
                                                                placeholder="Write Your Content Here"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>

                <Footer />

                <WriteDataModal
                    show={show}
                    handleClose={handleClose}
                    chapterData={promoData}
                    id={promoData.cid}
                    chapId={id}
                    type="promo"
                />
            </>
    )
}

export default ReviewScript