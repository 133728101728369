import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onCreateLanding = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                navigate(`/landing-page/generate-landing-page?id=${res.data}&name=${data.name}`)
            }
            setLoader({
                ...loader,
                create: false
            })
        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                create: false
            })
        })
}

export const onUpdateLandingPage = (data, navigate, loader, setLoader, redirectUrl) => (dispatch, getState) => {
    commonAxios("update-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (navigate) {
                    if (redirectUrl) {
                        navigate(redirectUrl)
                    } else {
                        navigate(`/landing-page/choose-templates?id=${data.id}`)
                    }
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false
            })

        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onUpdateTemplate = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("update-landing", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                if (navigate) {
                    navigate("/landing-page")
                }
                dispatch(setAlert(res.msg, "success"))
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                next: false
            })

        }).catch((err) => {
            console.log(err)
            setLoader({
                ...loader,
                next: false
            })
            dispatch(setAlert(err.msg, "danger"))
        })
}

export const onCheckLandingPageStatus = (data, common, setCommon, interval) => (dispatch, getState) => {
    commonAxios("fetch-landing-page", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                let statusNum = +res.data[0].status
                if (statusNum === 1 || statusNum === 3) {
                    clearInterval(interval)
                    let text = ""
                    let canEdit = false
                    let url = false
                    let bgColor = "#122bc9"
                    switch (statusNum) {
                        case 1:
                            text = "Active"
                            canEdit = true
                            url = `/landing-page/customize-website?id=${+res.data[0].id}`
                            bgColor = "#0f9628"
                            break;
                        case 2:
                            text = "Generating"
                            canEdit = false
                            url = false
                            bgColor = "#ffa500"
                            break;
                        case 3:
                            text = "Draft"
                            canEdit = false
                            url = !res.data[0].contentType ? `/landing-page/generate-landing-page?id=${res.data[0].id}` : !res.data[0].courseContent ? `/landing-page/content-extraction?id=${res.data[0].id}` : `/landing-page/choose-templates?id=${res.data[0].id}`
                            bgColor = "#122bc9"
                            break;
                        default:
                            canEdit = false
                            text = "Draft"
                            url = false
                    }
                    setCommon({
                        ...common,
                        statusText: text,
                        status: statusNum,
                        isEditabel: canEdit,
                        bgColor: bgColor,
                        urlToRedirect: url,
                        isDownloadable: false,
                        showRendering: false,
                        showProgAnimation: false,
                        thumbUrl: false,
                        failedData: false
                    })
                }
                else if (statusNum === 2) {
                    setCommon({
                        ...common,
                        statusText: "Generating",
                        status: statusNum,
                        showRendering: false,
                        showProgAnimation: false,
                        urlToRedirect: false,
                        isEditabel: false,
                        isDownloadable: false,
                        thumbUrl: false,
                        progress: false,
                        bgColor: "#ffa500"
                    })
                }
            }

        }).catch((err) => {
            clearInterval(interval)
            console.log(err)
        })
}
