import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import SummerNote from './SummerNote'
import { onEnableCtaData, onUpdateCtaPos } from '../../../Redux/Actions/VideoActions'
// import { onEnableCtaData, onUpdateCtaPos } from '../../../../../Redux/Actions/VideoActions'

const Prompt = ({ promo }) => {
    const dispatch = useDispatch()
    const [state, setState] = useState({
        promo: {
            position: "",
            data: "",
            enable: false
        }
    })


    const handleEnable = (e) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                enable: e.target.checked
            }
        })
        dispatch(onEnableCtaData(e.target.checked))
    }

    const handlePos = (val) => {
        setState({
            ...state,
            promo: {
                ...state.promo,
                position: val
            }
        })
        dispatch(onUpdateCtaPos(val))
    }

    useEffect(() => {
        if (promo) {
            setState({
                ...state,
                promo: promo
            })
        }
    }, [promo])

    return (
        <div>
            <div className='opt-select mt-4'>

                <span>CTA</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        id="cta"
                        checked={state.promo.enable}
                        onChange={(e) => handleEnable(e)}
                    />
                    <span className="slider round"></span>
                </label>


            </div>
            {state.promo.enable ?
                <>
                    {/* <hr className='mb-1 p-0' /> */}
                    <p style={{ fontSize: '15px', padding:"10px 0"}}>CTA Text</p>
                    <div style={{ color: "#000000" }}>
                        <SummerNote
                            state={state}
                            setState={setState}
                            type="inEditor"
                        />
                      <div className="col-12">
                      <div className='inp-wrap d-flex justify-content-between align-items-center' style={{width:"100%",height:"100%",marginTop:"20px"}}>
                            <label style={{color:"#fff"}}>CTA Positions</label>
                            <select
                                className='input solid inpx'
                                value={state.promo.position}
                                onChange={(e) => handlePos(e.target.value)}
                                style={{width:"auto"}}
                            >
                                <option value={'top-left'}>Top Left</option>
                                <option value={'top-center'}>Top Center</option>
                                <option value={'top-right'}>Top Right</option>
                                <option value={'bottom-left'}>Bottom Left</option>
                                <option value={'bottom-center'}>Bottom Center</option>
                                <option value={'bottom-right'}>Bottom Right</option>
                            </select>
                        </div>
                      </div>
                    </div>
                </>
                : ''}


        </div>
    )
}

export default Prompt