import React from 'react'
import { Outlet } from 'react-router';

const MainLandingPage = () => {
    return (
        <>
            <Outlet />
        </>
    );
}

export default MainLandingPage
