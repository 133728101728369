import React, { useEffect, useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { useNavigate } from 'react-router';
import Footer from '../CommonComponents/Footer/Footer';
import CreateModal from './Create/CreateModal';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import ChapterCard from '../Courses/ChapterCard';
import { themeColor } from '../../Global/Global';
import { useSelector } from 'react-redux';


const PromoVideo = () => {
    const dispatch = useDispatch()

    const auth = useSelector(state => state.auth)

    const [promoVideo, setPromoVideo] = useState([])
    const [loader, setLoader] = useState({
        fetch: true
    })
    const [q, setQ] = useState("")

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const fetchPromoVideos = () => {
        dispatch(fetchData("fetch-all-promo-video", {}, setPromoVideo, loader, setLoader))
    }

    useEffect(() => {
        fetchPromoVideos()
    }, [])

    return (
        <>
            <TitleBar title="Promo Video" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Promo Videos"
                                                value={q}
                                                onChange={(e) => setQ(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        {+auth.user.isClientAccount !== 1 ?
                                            <button onClick={handleShow} className="demoLink"><BsStars /> Generate Video</button> : null}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="forproject">
                                <div className="videoProject-top mt-2">
                                    <h2><span>Total Promo Videos ({promoVideo.length})</span></h2>
                                </div>
                                <div className="row mt-5 mb-5">
                                    {promoVideo.length > 0 ?
                                        promoVideo.filter((curElem) => {
                                            return curElem.name.toLowerCase().includes(q.toLowerCase())
                                        }).length > 0 ?
                                            promoVideo.filter((curElem) => {
                                                return curElem.name.toLowerCase().includes(q.toLowerCase())
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <div className="col-xl-3 col-md-4 col-sm-6 col-12 mb-4" key={index}>
                                                            <ChapterCard
                                                                curElem={curElem}
                                                                promoVideo={promoVideo}
                                                                setPromoVideo={setPromoVideo}
                                                                type={"promo"}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <div className='col-md-12 text-center'>
                                                <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                    <h4>
                                                        No promo video available!
                                                    </h4>
                                                </div>
                                            </div>
                                        :
                                        <div className='col-md-12 text-center'>
                                            <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                {loader.fetch ?
                                                    <i className="fa fa-spinner fa-spin" style={{ color: themeColor }} /> :
                                                    <h4 >
                                                        No promo video created yet!
                                                    </h4>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />

            <CreateModal
                show={show}
                handleClose={handleClose}
            />
        </>
    )
}

export default PromoVideo;