import React from "react";
import IntegrationHead from "./IntegrationHead";
import ConnectFacebook from "./ConnectFacebook";
import ConnectYoutube from "./ConnectYoutube";
import ConnectReelapps from "./ConnectReelapps";
import syvidIcon from "../../images/logo-syvideo.svg";
import scriptIcon from "../../images/logo-scriptreel.svg";
import trimIcon from "../../images/logo-trim.png";
import liveIcon from "../../images/logo-livereel.png";
import { useSelector } from "react-redux";
import sonorityIcon from "../../images/logo-sonority.png";
import videoIcon from "../../images/videoreel.svg";
import elevenlabsLogo from '../../images/elevenlabs.png'
import autoresponder from '../../images/autoresponder.png'



import Webinar from "../Dashboard/Webinar";
import SideNav from "../CommonComponents/Navs/SideNav";
import Navbar from "../CommonComponents/Navs/Navbar";
import TitleBar from "../CommonComponents/TitleBar";
import Footer from "../CommonComponents/Footer/Footer";

const Integration = () => {

    const socialData = useSelector(state => state.social);
    return (
        <>
            <TitleBar title="Integrations" />
            <Navbar />
            <div className="siteWrap">
                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">
                        <IntegrationHead socialData={socialData} />
                        <section className="connection">
                            <div className="container">
                                <h2 className="titleBdr">My Connection</h2>
                                <ConnectFacebook data={socialData.facebook} />
                                {/* <ConnectYoutube data={socialData.youtube} /> */}
                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={syvidIcon}
                                    name="Syvid"
                                    type="syvid"
                                    data={socialData.syvid} />

                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={scriptIcon}
                                    name="ScriptReel"
                                    type="scriptreel"
                                    data={socialData.scriptreel} />

                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={trimIcon}
                                    name="TrimReel"
                                    type="trimreel"
                                    data={socialData.trimreel} />

                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={liveIcon}
                                    name="LiveReel"
                                    type="livereel"
                                    data={socialData.livereel} />

                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={sonorityIcon}
                                    name="Sonority"
                                    type="sonority"
                                    data={socialData.sonority} />

                                <ConnectReelapps
                                    url="connect-to-app"
                                    icon={videoIcon}
                                    name="VideoReel"
                                    type="videoreel"
                                    data={socialData.videoreel} />

                                <ConnectReelapps
                                    icon={elevenlabsLogo}
                                    name="Elevenlabs"
                                    url="create-elevenlabs"
                                    type="elevenlabs"
                                    data={socialData.elevenlabs} />

                                <ConnectReelapps
                                    icon={autoresponder}
                                    name="Autoresponder"
                                    url="create-autoresponder"
                                    type="autoresponder"
                                    data={socialData.autoresponder} />


                            </div>
                        </section>

                        {/* <Webinar /> */}
                    </div>
                </div>

            </div>

            <Footer />
        </>
    )
}

export default Integration;