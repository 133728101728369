import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import { useSelector, useDispatch } from "react-redux";
import { setAlert } from "../../Redux/Actions/AlertActions";
import { IoPlay, IoPause } from "react-icons/io5";
import { useNavigate } from "react-router";
import axios from "axios";
import { fetchData } from "../../Redux/Actions/CommonActions";
import CommonAlert from '../CommonComponents/CommonAlert'
import { baseURL, themeColor } from "../../Global/Global";
import TitleBar from "../CommonComponents/TitleBar";
import Navbar from "../CommonComponents/Navs/Navbar";
import { HiSwitchHorizontal } from "react-icons/hi";
import VideoTiles from "./VideoTiles";

const ReelMerge = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const [videoData, setVideoData] = useState([]);
    const [mergeVideos, setMergeVideos] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(false);
    const [memberShip, setMemberShip] = useState([])
    const [pageLoader, setPageLoader] = useState(true)

    const videoRef = useRef(null)

    const [play, setPlay] = useState(false);
    const [loader, setLoader] = useState({
        dataLoader: false,
        mergeLoader: false,
    });

    const [resolution, setResolution] = useState("16x9");
    const [sweet, setSweet] = useState({
        enable: false,
        eventValue: false,
        confirmButtonName: "Change",

    });
    const [mergeData, setMergeData] = useState({
        data: [],
        name: "",
        preview_image: false,
        ratio: "16x9",
        user_id: auth.user.id,
    });





    const handleMerge = (item) => {
        if (currentSlide.url !== item.videoUrl) {
            let jsonObj = {
                id: item.id,
                name: item.name,
                preview_image: item.thumbnail,
                url: item.videoUrl,
                isSelected: true,
            };
            let data = [...mergeVideos];
            data = data.concat(jsonObj);

            if (data.length > 20) {
                dispatch(setAlert("You can merge maximum 20 videos only!", "danger"))
            } else {
                if (data.length > 1) {
                    const selectedIndex = data.findIndex(({ isSelected }) => isSelected === true);
                    data[selectedIndex].isSelected = false;
                }
                setCurrentSlide(jsonObj);
                setMergeVideos(data);
            }

        }
    };
    const handleFormSubmit = (e) => {
        e.preventDefault();

        setLoader({
            ...loader,
            mergeLoader: true,
        });
        mergeData.data = mergeVideos;
        mergeData.preview_image = mergeVideos[0]?.preview_image;
        axios({
            method: "POST",
            url: `${baseURL}create-merge`,
            data: mergeData,
            headers: {
                "Content-Type": "application/json",
                Authorization: auth.token,
            },
        })
            .then((res) => {
                if (res.data.status === true) {
                    navigate("/courses");
                    dispatch(setAlert(res.data.message, "success"));
                } else {
                    dispatch(setAlert(res.data.message, "danger"));
                }
                setLoader({
                    ...loader,
                    mergeLoader: false,
                });
            })
            .catch((err) => {
                console.log(err);
                setLoader({
                    ...loader,
                    mergeLoader: false,
                });
            });
    };


    const handleBack = () => {
        let selectedIndex = mergeVideos.findIndex(({ isSelected }) => isSelected === true);
        let data = [...mergeVideos];
        if (selectedIndex !== -1) {
            if (selectedIndex > 0) {
                data[selectedIndex].isSelected = false;
                data[selectedIndex - 1].isSelected = true;
                setMergeData(data);
                setCurrentSlide(data[selectedIndex - 1]);
            }
        }
    };

    const handleFor = () => {
        let selectedIndex = mergeVideos.findIndex(
            ({ isSelected }) => isSelected === true
        );
        let data = [...mergeVideos];
        if (selectedIndex !== -1) {
            if (selectedIndex < data.length - 1) {
                data[selectedIndex].isSelected = false;
                data[selectedIndex + 1].isSelected = true;
                setMergeData(data);
                setCurrentSlide(data[selectedIndex + 1]);
            }
        }
    };
    const handleSubmit1 = (e) => {
        setSweet({
            ...sweet,
            enable: true,
            eventValue: e.target.value,
        });
        setCurrentSlide(false);
    };
    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        });
    };

    const onPlay = () => {
        if (videoRef.current) {
            videoRef.current.play()
            setPlay(true)
        }
    }

    const onStop = () => {
        if (videoRef.current && !videoRef.current.paused) {
            videoRef.current.pause()
            setPlay(false)
        }
    }

    const performDelete = () => {
        setCurrentSlide(false);
        setResolution(sweet.eventValue);
        setMergeVideos([]);

        setMergeData({
            ...mergeData,
            ratio:
                sweet.eventValue === "16x9"
                    ? "16x9"
                    : sweet.eventValue === "9x16"
                        ? "9x16"
                        : "",
        });
        setSweet({
            ...sweet,
            enable: false,
            eventValue: false,
        });
    };

    const fetchProject = () => {
        setLoader({
            ...loader,
            dataLoader: true,
        });
        dispatch(
            fetchData("fetch-rendered-chapters", {}, setVideoData, loader, setLoader)
        );
    };


    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])


    useEffect(() => {
        if (memberShip.length > 0) {
            if (!(memberShip.includes("professional") || memberShip.includes("bundle") || memberShip.includes("fastpass"))) {
                navigate("/")
            } else {
                setPageLoader(false)
            }
        }
    }, [memberShip])

    useEffect(() => {
        fetchProject();
    }, []);

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split("__"))
        }
    }, [auth.user])

    return (
        pageLoader ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :
            <>
                <TitleBar title="Reel Merge" />
                <div className="navFull"><Navbar /></div>

                <section className="siteWrap forEditor">
                    <div className="ai-reelmerge siteWrap reelsitewrap">
                        <div className="editor-left">
                            <div className="row px-2">
                                <div className="inpField">
                                    <div className="col-12">
                                        <div className="Select_heading text-center">
                                            <h6>Select Resolution</h6>
                                        </div>
                                        <div className="set_resolution1 text-center">
                                            <div className="d-flex justify-content-center align-items-center gap-5">
                                                <div className="form-check1">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="flexCheckDefault"
                                                        name="resolution"
                                                        checked={resolution === "16x9" ? true : false}
                                                        value={"16x9"}
                                                        onChange={handleSubmit1}
                                                    />
                                                    <span className="" for="flexCheckDefault">
                                                        16:9
                                                    </span>
                                                </div>
                                                <div className="form-check1">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id="flexCheckChecked"
                                                        name="resolution"
                                                        checked={resolution === "9x16" ? true : false}
                                                        value={"9x16"}
                                                        onChange={handleSubmit1}
                                                    />
                                                    <span className="" for="flexCheckChecked">
                                                        9:16
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="slides">
                                <div className="row py-3 px-4">
                                    {videoData.length > 0 ? (
                                        videoData
                                            .filter((elem) => {
                                                return (
                                                    resolution === elem.dimension && elem.videoUrl
                                                );
                                            }).length > 0 ? (videoData
                                                .filter((elem) => {
                                                    return (
                                                        resolution === elem.dimension && elem.videoUrl
                                                    );
                                                }))
                                                .map((curElem, index) => {
                                                    return (
                                                        <div
                                                            className="col-6 cursor-pointer mb-2 px-1 text-center merge-wrap-img"
                                                            key={index}
                                                            onClick={() => handleMerge(curElem)}
                                                            title={curElem.name}
                                                        >
                                                            <div
                                                                className="mediaList-single myImage-respo "
                                                                style={{ textAlign: "center" }}
                                                            >
                                                                <img
                                                                    src={curElem.thumbnail}
                                                                    alt=""
                                                                    className="img-fluid"
                                                                    style={{ width: "100%", height: "100%", objectFit: "contain" }}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }) : <p className="text-center">No {resolution} Videos Created</p>
                                    ) : !loader.dataLoader ? (
                                        <div className="text-center" style={{ width: "100%" }}>
                                            <h5 className="text-white">No Videos Created</h5>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                    {loader.dataLoader ? (
                                        <div className="col-md-12">
                                            <h4 className="text-center load-icon-help">
                                                <i className="fa fa-spinner fa-spin mr-2" />
                                            </h4>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="editor-right">
                            <div className="campaignBar">
                                <form
                                    method="post"
                                    onSubmit={(e) => handleFormSubmit(e)}
                                    className="col-md-12 d-flex flex-wrap align-items-center justify-content-center gap-3"
                                >
                                    <div className="project-input-box text-white">
                                        <div className='project-input-in'>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Add Campaign Name"
                                                // style={{ background: "none" }}
                                                defaultValue={mergeData.name}
                                                required
                                                onChange={(e) => setMergeData({ ...mergeData, name: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                        className="demoLink mb-2"
                                        disabled={mergeVideos.length > 1 ? false : true}
                                    >
                                        <span className="mr-1">
                                            {loader.mergeLoader ? (
                                                <>
                                                    Merging
                                                    <i className="fa fa-spinner fa-spin mx-1" />
                                                </>
                                            ) : (
                                                <>
                                                    <i className="fas fa-file-video mx-2" />
                                                    Merge
                                                </>
                                            )}
                                        </span>
                                    </button>
                                </form>
                            </div>

                            <div className="mergeVideo">
                                <div className="mergeVideo-main">
                                    {mergeVideos.length > 0 ?
                                        <video src={currentSlide.url} width={"100%"} height={"100%"} ref={videoRef} muted loop={play} /> :
                                      null
                                    }

                                </div>
                                <div className="videoControll">
                                    <div className="row h-100">
                                        <div className="col-12 h-100">
                                            <div className="playButton text-center">
                                                <i
                                                    className="fa fa-step-backward playIcon me-2 cursor-pointer"
                                                    aria-hidden="true"
                                                    onClick={handleBack}
                                                ></i>
                                                {!play ?
                                                    <IoPlay
                                                        className="playIcon cursor-pointer"
                                                        onClick={() => onPlay()}
                                                    />
                                                    :
                                                    <IoPause
                                                        className="playIcon cursor-pointer"
                                                        onClick={() => onStop()}
                                                    />
                                                }
                                                <i
                                                    className="fa fa-step-forward playIcon ms-2 cursor-pointer"
                                                    aria-hidden="true"
                                                    onClick={handleFor}
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                {/* Reel Tiles */}
                <div className="editorReel">
                    <VideoTiles
                        mergeVideos={mergeVideos}
                        setMergeVideos={setMergeVideos}
                        setCurrentSlide={setCurrentSlide}
                        onStop={onStop}
                    />
                </div>
                {/* Reel Tiles End */}
                <CommonAlert
                    show={sweet.enable}
                    message={"If you change resolution you will lose current data."}
                    confirmButtonColor={""}
                    cancelButtonColor={""}
                    confirmButtonName={sweet.confirmButtonName}
                    cancelButtonName={"Cancel"}
                    handleClose={onCancelDelete}
                    performDelete={performDelete}
                    alertLoader={false}
                    icon={<HiSwitchHorizontal />}
                />
            </>
    );
};

export default ReelMerge;
