import React, { useEffect, useState } from 'react'

const ImageBox = ({ data, boxLoad, setBoxLoad }) => {
    const [imgData, setImgData] = useState(data)
    const [mainType, setMainType] = useState("image")
    // This mainType state is here to varify that we have to switch the element from video to image of not

    const handleError = () => {
        setImgData({
            ...imgData,
            thumbnail: imgData.src,
        })
        if (imgData.type === "video") {
            setMainType("video")
        }
    }

    useEffect(() => {
        if (data && !boxLoad) {
            setMainType("image")
            setImgData(data)
        } else {
            setBoxLoad(false)
        }
    }, [data])


    return (
        imgData.type === "color" ? "" :
            (imgData.type === "image" || imgData.type === "video") && mainType === "image" ?
                <img
                    src={imgData.thumbnail}
                    alt=""
                    onError={(e) => handleError(e)}
                /> :
                <video
                    src={imgData.src}
                    muted
                    style={{ height: "100%", width: "100%", objectFit: "contain" }}
                />
    )
}

export default ImageBox