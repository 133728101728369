import React, { useState } from 'react'
import videoThumb from '../../images/fav-new.png';
import { Link } from 'react-router';
import { onDeleteCampaign } from '../../Redux/Actions/CommonActions';
import { Trash } from 'iconsax-react';
import CommonAlert from '../CommonComponents/CommonAlert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

const QuizRows = ({ curElem, quiz, setQuiz }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,

    })

    const onDelete = () => {
        setSweet({
            ...sweet,
            enable: true,
            confirmButtonName: "Delete",
        })
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
            confirmButtonName: "Delete",
            loader: false,
        })
    }

    const performDelete = () => {
        let data = {
            id: curElem.id,
            type: "quiz"
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-record", data, quiz, setQuiz, setSweet))
    }

    return (
        <div className="col-xl-3 col-md-6 col-sm-12">
            <div className="videoProject-single">
                <div className="videoProject-single-top">
                    <div className="video-card-wrapper-1 dashboard-card-bg">
                        <div className="video-card-content">
                            <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                        </div>
                        <span className={`qz-tag ${+curElem.status === 2 ? "active" : ""}`} >{+curElem.status === 2 ? "Generated" : "Awaiting Customization"}</span>
                    </div>
                    <div className="vidHover">
                        <div><p>{curElem.created}</p></div>
                        <div className="vidiconAll">
                            <div className="vidHover-single">
                                {+auth.user.isClientAccount === 1 && +curElem.status !== 2 ? null :
                                    <Link to={+curElem.status === 2 ? `/quiz-generation/create-quiz?id=${curElem.id}&type=g` : `/quiz-generation/create-quiz?id=${curElem.id}`}>
                                        <span className="vidHover-icon"><i class="fa fa-pencil-square-o" aria-hidden="true"></i></span>
                                        <span>Edit</span>
                                    </Link>
                                }
                            </div>
                            <div className="vidHover-single">
                                {+auth.user.isClientAccount === 1 ? null :
                                    <a onClick={onDelete}>
                                        <span className="vidHover-icon"><i class="fa fa-trash-o" aria-hidden="true"></i></span>
                                        <span>Delete</span>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="videoProject-single-bottom">
                    <div>
                        <h6>{curElem.name}</h6>
                        {/* <p>Date Created: 12-01-24</p> */}
                    </div>
                </div>
            </div>

            <CommonAlert
                show={sweet.enable}
                message={'Are you sure? you want to delete this quiz.'}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </div>
    )
}

export default QuizRows