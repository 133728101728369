import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { onFetchPublicQuiz } from '../../Redux/Actions/PublicQuizActions'
import { useDispatch } from 'react-redux'
import StartQuizModal from './StartQuizModal'
import Footer from '../CommonComponents/Footer/Footer'
import SideNav from '../CommonComponents/Navs/SideNav'
import Navbar from '../CommonComponents/Navs/Navbar'

const PublicQuiz = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const [loader, setLoader] = useState({
        fetch: true
    })
    const [state, setState] = useState({
        name: ""
    })

    const fetchQuizData = () => {
        let data = { id }
        dispatch(onFetchPublicQuiz(data, setState, loader, setLoader))
    }

    let div = document.getElementById("beacon-container")

    useEffect(() => {
        if (div) {
            div.style.display = "none"
        }

        return () => {
            if (div) {
                div.style.display = "block"
            }
        }
    }, [div])


    useEffect(() => {
        if (id) {
            fetchQuizData()
        }
    }, [id])


    return (
        loader.fetch ?
            <div className="loader-sec">
                <div className="loader">
                </div>
            </div> :

            <>
                <TitleBar title="Quiz" />
                <section className="siteWrap">
                    <div className="cont_wrap">

                        <div className='container'>
                            <div className='quiz-wrap'>
                                <div className='quiz-card'>
                                    <h2 className='text-center gradientTxt'><span>{state.name}</span></h2>
                                    <div className='pt-5'>
                                        <h4 className='gradientTxt'><span>Instructions</span></h4>
                                        <div className="instruction mt-4">
                                            <ul>
                                                <li>The quiz consists of <em>{state.quizCustomization.noOfQuestion}</em> questions.</li>
                                                <li>Each question will be multiple-choice / short answer / true or false.</li>
                                                <li>You will have <em>{state.quizCustomization.timeLimit}</em> minutes to complete questions.</li>
                                            </ul>
                                        </div>
                                        <button style={{ minWidth: 120 }} className='demoLink small mt-4' onClick={handleShow}>Take quiz</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <StartQuizModal
                    show={show}
                    handleClose={handleClose}
                    quizCustomization={state.quizCustomization}
                    userId={state.userId}
                    quizId={id}
                />
            </>
    )
}

export default PublicQuiz