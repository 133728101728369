import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import sample from "../../../images/sample.png";
import { onUploadIR, removeIO, updateIOStatus } from '../../../Redux/Actions/VideoActions';
import { themeColor } from '../../../Global/Global';
import VideoReelModal from './VideoReelModal';
import { useSelector } from 'react-redux';
import { fetchData } from '../../../Redux/Actions/CommonActions';
import axios from 'axios';

const IntroOutro = (props) => {
    const dispatch = useDispatch()
    const videoreel = useSelector(state => state.social.videoreel)

    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState(false)
    const [ir, setIr] = useState("")

    const [videoReel, setVideoReel] = useState([])
    const [introArr, setIntroArr] = useState([])

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleStatus = (e) => {
        dispatch(updateIOStatus(e.target.checked, props.type))
        if (e.target.checked === false) {
            dispatch(removeIO(props.type))
        }
    }

    const onInputVideo = (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].type === "video/mp4") {
                const allowedSize = 20000000;
                if (e.target.files[0].size < allowedSize) {

                    const formData = new FormData();
                    setLoader(true)
                    formData.append('file', e.target.files[0])
                    formData.append('upload_type', props.type)
                    dispatch(onUploadIR(formData, setLoader, props.type, setPercent, fetchDataModal))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for video is 20MB!',
                        confirmButtonColor: themeColor
                    })
                }
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `Upload ${props.type.charAt(0).toUpperCase() + props.type.slice(1)} Video!`,
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    useEffect(() => {
        if (videoreel.length > 0) {
          const token = { "token": videoreel[0].token }
          axios({
            method: 'POST',
            url: 'https://v2server.videoreel.io/fetch-all-projects-gets',
            data: JSON.stringify(token),
            headers: { 'Content-Type': 'application/json', 'Authorization': videoreel[0].token }
          }).then((res) => {
            if (res.data.status === true) {
              setVideoReel(res.data.data)
            }
          }).catch((error) => {
            console.log(error)
          })
        }
      }, [videoreel])


    const fetchDataModal = () => {
        let data = { type: props.type }
        dispatch(fetchData("fetch-user-uploaded-file", data, setIntroArr))
    }

    useEffect(() => {
        fetchDataModal()
    }, [])


    return (
        <div className="tabInner">
            <div className="media-block">
                <div className="add-block-single">
                    <div className="add-block-main">
                        <div className="add-block-title">
                            <h2 className='text-capitalize'>{props.type}</h2>
                            <div className="switch-single">
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        id={props.type}
                                        checked={props.data.enable}
                                        onChange={(e) => handleStatus(e)}
                                        name={props.type}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        {props.data.enable ?
                            <>
                                <div className="fileUpload mt-4" >
                                    <i className="fa fa-cloud-upload" aria-hidden="true" />

                                    <p className='text-capitalize'>
                                        Upload {props.type}
                                    </p>
                                    <input
                                        type="file"
                                        className='cursor-pointer'
                                        accept="video/*"
                                        onChange={(e) => onInputVideo(e)}
                                    />
                                </div>
                                <div className="itemDisp">
                                    <div className="itemDisp-left">
                                        <ul>
                                            <li style={{ position: 'relative' }}>
                                                {loader ? <i className="fa fa-spinner fa-spin loader-center" />
                                                    : <img
                                                        src={props.data.thumbnail ? props.data.thumbnail : sample}
                                                        alt=""
                                                        className="uploadImg"
                                                    />
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='itemDisp-right'>
                                        <button onClick={handleShow}>Select {props.type} video +</button>
                                    </div>
                                </div>
                            </> : ""}
                    </div>
                </div>
            </div>
            <VideoReelModal
                show={show}
                handleClose={handleClose}
                type={props.type}
                introArr={introArr}
                videoReel={videoReel}
                fetchDataModal={fetchDataModal}
            />
        </div>
    );
}

export default IntroOutro;
