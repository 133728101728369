import React, { useCallback } from 'react'
import { AbsoluteFill, Sequence, Series, Loop } from 'remotion';
import TransitionSeries from 'remotion-transition-series/lib/TransitionSeries';
import SlideBackground from './layers/SlideBackground';
import AudioLayer from './layers/AudioLayer';
import Intro from './layers/Intro';
import VideoLayer from './layers/VideoLayer';
import ShapeLayer from './layers/ShapeLayer';
import TextLayer from './layers/TextLayer';
import Promo from './layers/Promo';
import Logo from './layers/Logo';
import { SlidingDoors } from './Transitions/SlidingDoors';
import { CircularWipe } from './Transitions/CircularWipe';
import Dissolve from './Transitions/Dissolve';
import { FadeThroughColor } from './Transitions/FadeThroughColor';
import { LinearWipe } from './Transitions/LinearWipe';
import { Pan } from './Transitions/Pan';
import { Slide } from './Transitions/Slide';
import { useMemo } from 'react';
import LogoLayer from './layers/LogoLayer';
import FaceNarration from './layers/FaceNarration';

const RemotionEditor = ({ slideData, multiplyNum = 1, playerRef, totalDuration }) => {

    const transitionDuration = 30;
    const audioDelay = 30;

    const renderTransitionComponent = useCallback((transition, props) => {
        switch (transition) {
            case "CircularWipeIn":
                return <CircularWipe {...props} direction="in" />;
            case "CircularWipeOut":
                return <CircularWipe {...props} direction="out" />;
            case "Dissolve":
                return <Dissolve  {...props} />;
            case "FadeThroughColor":
                return <FadeThroughColor {...props} />;
            case "LinearWipe60":
                return <LinearWipe {...props} angle={60} />;
            case "LinearWipe90":
                return <LinearWipe {...props} angle={90} />;
            case "LinearWipe120":
                return <LinearWipe {...props} angle={120} />;
            case "LinearWipe240":
                return <LinearWipe {...props} angle={240} />;
            case "LinearWipe300":
                return <LinearWipe {...props} angle={300} />;
            case "PanLeft":
                return <Pan {...props} direction="left" />;
            case "PanRight":
                return <Pan {...props} direction="right" />;
            case "PanUp":
                return <Pan {...props} direction="up" />;
            case "PanDown":
                return <Pan {...props} direction="down" />;
            case "SlideLeft":
                return <Slide {...props} direction="left" />;
            case "SlideRight":
                return <Slide {...props} direction="right" />;
            case "SlideUp":
                return <Slide {...props} direction="up" />;
            case "SlideDown":
                return <Slide {...props} direction="down" />;
            case "SlidingDoorsOpen":
                return <SlidingDoors {...props} direction="open" angle={0} />;
            case "SlidingDoorsOpen60":
                return <SlidingDoors {...props} direction="open" angle={60} />;
            case "SlidingDoorsOpen90":
                return <SlidingDoors {...props} direction="open" angle={90} />;
            case "SlidingDoorsOpen120":
                return <SlidingDoors {...props} direction="open" angle={120} />;
            case "SlidingDoorsClose":
                return <SlidingDoors {...props} direction="close" angle={0} />;
            case "SlidingDoorsClose60":
                return <SlidingDoors {...props} direction="close" angle={60} />;
            case "SlidingDoorsClose90":
                return <SlidingDoors {...props} direction="close" angle={90} />;
            case "SlidingDoorsClose120":
                return <SlidingDoors {...props} direction="close" angle={120} />;
            default:
                return <SlidingDoors {...props} direction="close" angle={0} />;
        }
    }, [])

    const memoizedContent = useMemo(() => {
        return (
            <AbsoluteFill>
                {slideData?.audio?.source && slideData?.audio?.source !== "" ?
                    <Sequence from={(slideData?.intro?.enable === true && slideData?.intro?.src !== "") ? +slideData.intro.duration * 30 + audioDelay : 0} durationInFrames={totalDuration}>
                        <AudioLayer src={slideData.audio.source} volume={+slideData.audio.volume / 100} loop={true} />
                    </Sequence>
                    : ""
                }
                <TransitionSeries>
                    {slideData?.intro?.enable === true && slideData?.intro?.src !== "" ?
                        <React.Fragment >
                            <TransitionSeries.Sequence durationInFrames={+slideData.intro.duration * 30 + transitionDuration} >
                                <Intro src={slideData.intro.src} duration={+slideData.intro.duration * 30} playerRef={playerRef} />
                            </TransitionSeries.Sequence>
                            <TransitionSeries.Transition
                                durationInFrames={transitionDuration}
                                transitionComponent={(props) => renderTransitionComponent(slideData.transitionName, props)}
                            />
                        </React.Fragment> : <></>
                    }
                    {
                        slideData.slides?.length > 0 ?
                            slideData.slides.map((slide, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <TransitionSeries.Sequence durationInFrames={(slideData?.intro?.enable === true && slideData?.intro?.src !== "") ? +slide.duration * 30 + audioDelay + transitionDuration : index === 0 ? +slide.duration * 30 + transitionDuration : +slide.duration * 30 + audioDelay + transitionDuration} >
                                            <Loop durationInFrames={Number(slide.background.length * 6 * 30)}>
                                                <Series key={index}>
                                                    {
                                                        slide?.background?.length > 0 ?
                                                            slide.background.map((background, bgIndex) => {
                                                                return (
                                                                    <React.Fragment key={bgIndex} >
                                                                        <Series.Sequence durationInFrames={30 * 6}>
                                                                            <SlideBackground dimension={slideData.dimension} playerRef={playerRef} animation={background.animation} src={background.src} duration={30 * 6} type={background.type} />
                                                                        </Series.Sequence>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                            : ""
                                                    }

                                                </Series>
                                            </Loop>
                                            {
                                                slide?.voiceOver?.enable === true && slide?.voiceOver?.src !== "" ?
                                                    <Sequence from={(slideData?.intro?.enable === true && slideData?.intro?.src !== "") ? audioDelay : index === 0 ? 0 : audioDelay}>
                                                        <AudioLayer src={slide.voiceOver.src} volume={+slide.voiceOver.volume / 100} loop={false} />
                                                    </Sequence>
                                                    : ""
                                            }
                                            {
                                                slide.layers?.length > 0 ?
                                                    slide.layers.map((layer, layerIndex) => {
                                                        return (
                                                            <React.Fragment key={layerIndex}>
                                                                {
                                                                    layer.type === "video" || layer.type === "image" ?
                                                                        <VideoLayer src={layer.src} layer={layer} multiplyNum={multiplyNum} mute={layer?.mute} />
                                                                        : layer.type === "text" ?
                                                                            <TextLayer layer={layer} multiplyNum={multiplyNum} />
                                                                            : layer.type === "shape" ?
                                                                                <ShapeLayer layer={layer} multiplyNum={multiplyNum} />
                                                                                : layer.type === "logo" || layer.type === "graph" ?
                                                                                    <LogoLayer src={layer.src} layer={layer} multiplyNum={multiplyNum} />
                                                                                    : ""

                                                                }
                                                            </React.Fragment>
                                                        )
                                                    })

                                                    : ""
                                            }

                                            {slideData?.logo?.enable === true && slideData?.logo?.src !== "" ?
                                                <Logo src={slideData.logo.src} type={"logo"} position={slideData.logo.pos} multiplyNum={multiplyNum} />
                                                : ""
                                            }
                                            {slideData?.watermark?.enable === true && slideData?.watermark?.src !== "" ?
                                                <Logo src={slideData.watermark.src} type={"watermark"} position={slideData.watermark.pos} multiplyNum={multiplyNum} />
                                                : ""
                                            }
                                            {slideData?.promo?.enable === true && slideData?.promo?.data !== "" ?
                                                <Promo src={slideData.promo.data} position={slideData.promo.position} multiplyNum={multiplyNum} />
                                                : ""
                                            }
                                            {slide?.faceNarration?.enable === true && slide?.faceNarration?.media?.url !== "" ?
                                                <Sequence from={(slideData?.intro?.enable === true && slideData?.intro?.src !== "") ? audioDelay : index === 0 ? 0 : audioDelay}>
                                                    <FaceNarration
                                                        src={slide.faceNarration.media.url}
                                                        position={slide?.faceNarration?.media?.pos}
                                                        height={slide?.faceNarration?.media?.height}
                                                        width={slide?.faceNarration?.media?.width}
                                                        shape={slide?.faceNarration?.media?.shape}
                                                        multiplyNum={multiplyNum}
                                                    />
                                                </Sequence>
                                                : ""
                                            }
                                        </TransitionSeries.Sequence >

                                        <TransitionSeries.Transition
                                            durationInFrames={transitionDuration}
                                            transitionComponent={(props) => renderTransitionComponent(slide.transitionName, props)}
                                        />
                                    </React.Fragment >
                                )

                            })
                            : <></>
                    }

                    {slideData?.outro?.enable === true && slideData?.outro?.src !== "" ?
                        (
                            <TransitionSeries.Sequence durationInFrames={+slideData.outro.duration * 30} >
                                <Intro src={slideData.outro.src} duration={+slideData.outro.duration * 30} playerRef={playerRef} />
                            </TransitionSeries.Sequence>
                        ) : <></>
                    }

                </TransitionSeries>
            </AbsoluteFill >
        )
    }, [slideData, totalDuration]);

    return memoizedContent
}

export default RemotionEditor
