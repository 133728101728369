import React, { useState } from "react";
import ReactPlayer from "react-player";
// import HoverVideoPlayer from 'react-hover-video-player'

const Week1 = () => {

    const videos = [
        {
            id: 1,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/1-0+Sales+Funnel-The+Basics-1.mp4"
        },
        {
            id: 2,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/1-0+Setting+Up+Your+Online+Business-2.mp4"
        },
        {
            id: 3,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/1-0+What+Is+Affiliate+Marketing-3.mp4"
        },
        {
            id: 4,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/1-0+Your+High+Ticket+Offer-4.mp4"
        },
        {
            id: 5,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/10-0+Media+Buying+Your+First+Banner+And+Offer-5.mp4"
        },
        {
            id: 6,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/10-0+Solo+Ads+To+Market+Your+Business-6.mp4"
        },
        {
            id: 7,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/11-0+Facebook+Ads+The+Fundamentals+1-7.mp4"
        },
        {
            id: 8,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/12-0+Facebook+Ads+The+Fundamentals+2-8.mp4"
        },
        {
            id: 9,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/13-0+Warrior+Special+Offers+Wso-9.mp4"
        },
        {
            id: 10,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/14-0+Search+Engine+Optimization+Seo-10.mp4"
        },
        {
            id: 11,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/15-0+Instagram-11.mp4"
        },
        {
            id: 12,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/16-0+Tumblr-12.mp4"
        },
        {
            id: 13,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/17-0+Pinterest-13.mp4"
        },
        {
            id: 14,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/2-0+Components+Of+A+Sales+Funnel-14.mp4"
        },
        {
            id: 15,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/2-0+Spying+On+The+Market-15.mp4"
        },
        {
            id: 16,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/2-0+The+C+R+A+F+T+Strategy-16.mp4"
        },
        {
            id: 17,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/2-0+Your+Step+By+Step+Guide-17.mp4"
        },
        {
            id: 18,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/3-0+Introduction+Marketing+Your+Offer-18.mp4"
        },
        {
            id: 19,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/3-0+The+7+Figure+Mindset-19.mp4"
        },
        {
            id: 20,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/3-0+The+Myth+Of+Affiliate+Marketing-20.mp4"
        },
        {
            id: 21,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/4-0+Copywriting+Writing+To+Sell-21.mp4"
        },
        {
            id: 22,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/4-0+How+To+Choose+The+Best+Product+To+Promote-22.mp4"
        },
        {
            id: 23,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/5-0+Criteria+Of+The+Best+Product-23.mp4"
        },
        {
            id: 24,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/5-0+Google+Adwords++The+Basics-24.mp4"
        },
        {
            id: 25,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/6-0+Google+Adwords+Strategies+And+Tactics+1-25.mp4"
        },
        {
            id: 26,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/6-0+Killer+Promo+Secrets-26.mp4"
        },
        {
            id: 27,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/7-0+Google+Adwords+Strategies+And+Tactics+2-27.mp4"
        },
        {
            id: 28,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/7-0+How+To+3X+Your+Commissions-28.mp4"
        },
        {
            id: 29,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/8-0+Bonus+Techniques-29.mp4"
        },
        {
            id: 30,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/8-0+Optimizing+Your+Google+Adwords+Campaign-30.mp4"
        },
        {
            id: 31,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/9-0+Media+Buying+Research+And+Analysis-31.mp4"
        },
        {
            id: 32,
            url: "https://reelapps.s3.us-west-2.amazonaws.com/reviewreel/Master+Class/Week+1/Week+1+Compressed/9-0+Types+Of+Killer+Bonuses-32.mp4"
        }

    ]


    return (
        <div
            className="tab-pane fade"
            id="v-pills-week-1"
            role="tabpanel"
            aria-labelledby="v-pills-week-1-tab"
        >
            <div className="row">
                <h2 style={{ padding: "20px 30px" }}>Master Class Week 1</h2>
                {videos.map((curElem) => {
                    return (
                        <div className="col-lg-6 col-12 mx-auto " key={curElem.id}>
                            <div className="master-box-content dashboard-block" >
                                <ReactPlayer
                                    url={curElem.url}
                                    loop="true"
                                    muted
                                    controls
                                    width="100%"
                                    height="100%"
                                    playing={false}
                                />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div >



    )
}
export default Week1;