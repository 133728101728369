import React from 'react'

const Platform = ({ data }) => {
    return (
        data.length > 0 ?
            <>
                <optgroup label={data[0].group_name}>
                    {data.map((curElem, index) => {
                        // let ratio  = curElem.dimension === "1920x1080" ? "16:9" : "9:16";
                        return (
                            <option value={curElem.dimension} key={index}>{curElem.name}{" "}{`[${curElem.dimension}]`}</option>
                        )
                    })
                    }
                </optgroup>
            </>
            : ""
    )
}

export default Platform