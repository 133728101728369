import React from 'react'

const QuizQuestionBox = ({ curElem, index, length, onPrev, onNext, time, setYourQuizData }) => {

    const handleChange = (answ) => {
        setYourQuizData(draft => {
            draft[index].userAnswer = answ
        })
    }


    return (
        <div className='quiz-prev-in'>
            <div className='quiz-prev-box'>
                <div className='d-flex align-items-center justify-content-between'>
                    <span>Question {index + 1}/{length} </span>
                    <span>{time.h < 10 ? "0" : ""}{time.h} : {time.m < 10 ? "0" : ""}{time.m} : {time.s < 10 ? "0" : ""}{time.s}</span>
                </div>
                <div className='edit-area mt-3'>
                    <p>{curElem.question}</p>
                </div>
            </div>

            <div className='selected-ans'>
                {curElem.options ?
                    curElem.options.length > 0 ?
                        curElem.options.map((curData, index) => {
                            return (
                                <div
                                    key={index}
                                    className={`selected-ans-single ${curElem.userAnswer === curData ? "active" : ""}`}
                                    onClick={() => handleChange(curData)}
                                    style={{ cursor: "pointer" }}
                                >
                                    {curData}
                                </div>
                            )
                        })
                        : ""
                    :
                    <div className='mt-3 px-3'>
                        <p>Write your answer</p>
                        <div className='selected-ans-single p-0 mt-1' >
                            <input
                                className='input'
                                type='text'
                                value={curElem.userAnswer}
                                onChange={(e) => handleChange(e.target.value)}
                            />
                        </div>
                    </div>
                }
                <div className='row pt-5'>
                    <div className='col-6'>
                        <button className='qz-button' onClick={onPrev} >
                            &lt;&lt; Prev
                        </button>
                    </div>
                    <div className='col-6'>
                        <button className='qz-button blue' onClick={onNext}>
                            Next &gt;&gt;
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuizQuestionBox