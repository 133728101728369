import React, { useEffect, useState } from 'react';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';

const GradientBox = (props) => {
    let dummyString = "linear-gradient(to right, #91bd1eff, #c56d6dff)"
    const [color, setColor] = useState({
        left: "",
        showLeft: false,
        right: "",
        showRight: false,
    })

    useEffect(() => {
        if (props.colorString) {
            if (props.colorString.includes("linear-gradient")) {
                if (props.setIsGradient) {
                    props.setIsGradient(true)
                }
                let val = props.colorString.split(", ")
                if (val.length > 0) {
                    let leftStuff = val[1]

                    let rightStuff = val[2]
                    rightStuff = rightStuff.replace(")", "")
                    setColor({
                        ...color,
                        left: leftStuff,
                        right: rightStuff
                    })
                }
            } else {
                if (props.setIsGradient) {
                    props.setIsGradient(false)
                }
            }
        }
    }, [props.colorString])

    const handleChangeColor = (e, type) => {
        let rgba = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a.toFixed(2)})`
        let hex = `#${rgbHex(rgba)}`
        let finalGradient
        let bg = props.colorString

        if (type === "left") {
            if (!bg.includes("linear-gradient")) {
                bg = dummyString
            }
            bg = bg.split(",")
            let newArr = bg[1].split(" ")
            newArr[1] = hex
            newArr = newArr.join(" ")
            bg[1] = newArr
            finalGradient = bg.join(",")

        } else {
            if (!bg.includes("linear-gradient")) {
                bg = dummyString
            }
            bg = bg.split(",")
            let newArr = bg[2].split(" ")
            newArr[1] = hex
            newArr = newArr.join(" ")
            bg[2] = newArr
            finalGradient = bg.join(",")
        }
        if (!finalGradient.endsWith(')')) {
            finalGradient += ')';
        }
        props.getFinalGradient(props.type, finalGradient)
    }

    const handleColorActive = (type) => {
        if (type === "left") {
            setColor({
                ...color,
                showLeft: true
            })
        } else {
            setColor({
                ...color,
                showRight: true
            })
        }
    }

    const handleColorDeactive = (type) => {
        if (type === "left") {
            setColor({
                ...color,
                showLeft: false
            })
        } else {
            setColor({
                ...color,
                showRight: false
            })
        }
    }

    return (
        <div className='row'>
            <div className='col-xl-5'>
                <div className='color-wrap'>
                    <span
                        className='colorBox'
                        onClick={() => handleColorActive("left")}
                        style={{ background: color.left }}
                    ></span>
                    <span className='colorVal'>{color.left}</span>
                </div>
                {color.showLeft ?
                    <>
                        <div
                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                            onClick={() => handleColorDeactive("left")}
                        />
                        <span className="color-box">
                            <SketchPicker
                                color={color.left}
                                onChange={(e) => handleChangeColor(e, "left")}
                            />
                        </span>
                    </> : null}
            </div>
            <div className='col-xl-5'>
                <div className='color-wrap'>
                    <span
                        className='colorBox'
                        onClick={() => handleColorActive("right")}
                        style={{ background: color.right }}

                    ></span>
                    <span className='colorVal'>{color.right}</span>
                </div>
                {color.showRight ?
                    <>
                        <div
                            style={{ height: "100%", width: "100%", position: "fixed", top: 0, left: 0 }}
                            onClick={() => handleColorDeactive("right")}
                        />
                        <span className="color-box">
                            <SketchPicker
                                color={color.right}
                                onChange={(e) => handleChangeColor(e, "right")}
                            />
                        </span>
                    </> : null}
            </div>
            <div className='col-xl-2 mt-xl-0 mt-1' >
                <div className='gradient-box min-kap' style={{ background: props.colorString, height: "100%" }} />
            </div>
        </div>
    );
}

export default GradientBox;
