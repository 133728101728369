import React, { useEffect, useState } from 'react'

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import QuizSource from './QuizSource';
import CustomizeQuiz from '../CustomizeQuiz';
import TitleBar from '../../CommonComponents/TitleBar';
import Navbar from '../../CommonComponents/Navs/Navbar';
import SideNav from '../../CommonComponents/Navs/SideNav';
import Footer from '../../CommonComponents/Footer/Footer';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { onChangeContentCreated, onFetchQuiz, onNextQuiz, unmountQuiz } from '../../../Redux/Actions/QuizActions';
import ProvideInput from './ProvideInput';
import FinalizePublish from '../FInalQuiz/FinalizePublish';
import { IoArrowBackSharp, IoArrowForwardSharp } from 'react-icons/io5'
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { Tooltip } from 'react-tooltip';

const CreateQuiz = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const { id, type } = queryString.parse(location.search)
    const auth = useSelector(state => state.auth)
    const quiz = useSelector(state => state.quiz.data)

    const [loader, setLoader] = useState({
        fetch: true,
        next: false
    })

    const [tab, setTab] = useState({
        first: true,
        second: false,
        // third: false,
        forth: false,
        fifth: false,
    })

    const handleTabNext = () => {
        let arr = Object.keys(tab)
        for (let i = 0; i < arr.length - 1; i++) {
            if (tab[arr[i]]) {
                setTab({
                    ...tab,
                    [arr[i]]: false,
                    [arr[i + 1]]: true,
                })
                break;
            }
        }
    }

    const handleTabPrev = () => {
        let arr = Object.keys(tab)
        for (let i = 0; i < arr.length - 1; i++) {
            if (tab[arr[i]]) {
                setTab({
                    ...tab,
                    [arr[i]]: false,
                    [arr[i - 1]]: true,
                })
                break;
            }
        }
    }

    const handleTabs = (tabName) => {
        let objj = { ...tab }
        if (tabName === "fifth") {
            if (!tab[tabName] && quiz.quizData.length > 0) {
                Object.keys(tab).forEach((curElem) => {
                    objj[curElem] = tabName === curElem ? true : false
                })
                setTab(objj)
            }
        } else if (tabName === "third" || tabName === "forth") {
            if (quiz.courseContent.chapters.length > 0 || (quiz.prompt !== null && quiz.prompt !== "")) {
                Object.keys(tab).forEach((curElem) => {
                    objj[curElem] = tabName === curElem ? true : false
                })
                setTab(objj)
            }
        }
        else {
            if (!tab[tabName]) {
                Object.keys(tab).forEach((curElem) => {
                    objj[curElem] = tabName === curElem ? true : false
                })
                setTab(objj)
            }
        }
    }


    const handleSubmit = () => {
        let obj = { ...quiz }

        if (tab.forth) {
            obj.type = "generate"
        } else {
            obj.type = "update"
        }
        let variabel = "courseContent"
        if (obj.quizSource === "manual") {
            obj.courseContent = {
                courseId: false,
                chapters: []
            }
        } else if (obj.quizSource === "course") {
            obj.prompt = ""
            variabel = "prompt"
        }

        if (tab.second && (obj.courseContent.chapters.length === 0 && !obj.prompt)) {
            if (obj.quizSource === "course") {
                dispatch(setAlert("Please provide course to continue!", "danger"))
            } else {
                dispatch(setAlert("Please provide manual input to continue!", "danger"))
            }
        } else {
            if (tab.forth) {
                if (+obj.quizCustomization.noOfQuestion === 0) {
                    dispatch(setAlert("Please provide a valid question count!", "danger"))
                } else if (+obj.quizCustomization.timeLimit === 0) {
                    dispatch(setAlert("Please provide a valid time limit!", "danger"))
                } else {
                    setLoader({
                        ...loader,
                        next: true
                    })
                    dispatch(onChangeContentCreated(variabel))
                    dispatch(onNextQuiz(obj, handleTabNext, loader, setLoader, tab.fifth, navigate))
                }
            } else {
                setLoader({
                    ...loader,
                    next: true
                })
                dispatch(onChangeContentCreated(variabel))
                dispatch(onNextQuiz(obj, handleTabNext, loader, setLoader, tab.fifth, navigate))
            }

        }
    }

    const fetchQuiz = () => {
        let data = { id }
        dispatch(onFetchQuiz(data, loader, setLoader))
    }



    useEffect(() => {
        if (id) {
            fetchQuiz()
            return () => {
                dispatch(unmountQuiz())
            }
        }
    }, [id])

    useEffect(() => {
        if (type === "g") {
            setTab({
                ...tab,
                fifth: true,
                first: false
            })
        }
    }, [type])

    return (
        loader.fetch ?
            < div className="loader-sec" >
                <div className="loader">
                </div>
            </div >
            :
            <>
                <TitleBar title="Quiz" />
                <Navbar />
                <section className="siteWrap">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className="col-auto">
                                        <h2 className='pageTitle-2'>{quiz.name}</h2>
                                    </div>
                                    <div className="col-auto">
                                        <div className="projectTitle-right">
                                            <button className="demoLink grey" onClick={() => navigate(-1)}><IoArrowBackSharp />Back</button>
                                            {+auth.user.isClientAccount === 1 ? null :
                                                <button
                                                    className="demoLink"
                                                    onClick={handleSubmit}
                                                >
                                                    {
                                                        loader.next ?
                                                            <> {tab.fifth ? "Saving" : "Next"}  <i className="fa fa-spinner fa-spin" /> </>
                                                            : <> {tab.fifth ? "Save" : <>Next <IoArrowForwardSharp /></>} </>
                                                    }

                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className='quiz-wrap'>
                                    {+auth.user.isClientAccount === 1 ? null :
                                        <h2 className='text-center gradientTxt'><span>Choose How You Want To Generate Your Quiz</span></h2>}

                                    <div className='pt-5 quizTab'>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                            {+auth.user.isClientAccount === 1 ? null :
                                                <Nav variant="n" className="quizNav">
                                                    <Nav.Item>
                                                        <Nav.Link onClick={() => handleTabs("first")} className={tab.first ? "active" : ""}>
                                                            <div className='q-icon'>
                                                                <div className='q-icon-in'><img src={require('../../../images/quiz/source.svg').default} alt='' /></div>
                                                            </div>
                                                            <p>Quiz Source</p>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link onClick={() => handleTabs("second")} className={tab.second ? "active" : ""}>
                                                            <div className='q-icon'>
                                                                <div className='q-icon-in'><img src={require('../../../images/quiz/info.svg').default} alt='' /></div>
                                                            </div>
                                                            <p>Provide Input</p>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item>
                                                        <Nav.Link onClick={() => handleTabs("forth")} className={tab.forth ? "active" : ""}>
                                                            <div className='q-icon'>
                                                                <div className='q-icon-in'><img src={require('../../../images/quiz/filter.svg').default} alt='' /></div>
                                                            </div>
                                                            <p>Customize the Quiz</p>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item data-tooltip-id={"my-tooltip-quiz-link"}>
                                                        <Nav.Link onClick={() => handleTabs("fifth")} className={tab.fifth ? "active" : ""} style={
                                                            { cursor: quiz ? quiz.quizData.length === 0 ? "not-allowed" : "" : "" }}>
                                                            <div className='q-icon'>
                                                                <div className='q-icon-in'><img src={require('../../../images/quiz/finalize.svg').default} alt='' /></div>
                                                            </div>
                                                            <p>Finalize & Publish</p>
                                                        </Nav.Link>
                                                        <Tooltip
                                                            id={"my-tooltip-quiz-link"}
                                                            style={{ backgroundColor: "#424b6c", color: "#fff", maxWidth: 500, position: "absolute", top: 0 }}
                                                            place="bottom"
                                                            content={"To finalized quiz click on next."}
                                                        />
                                                    </Nav.Item>
                                                </Nav>
                                            }

                                            <Tab.Content className='pt-5'>

                                                <Tab.Pane className={tab.first ? "active show" : ""}>
                                                    <QuizSource />
                                                </Tab.Pane>

                                                <Tab.Pane className={tab.second ? "active show" : ""}>
                                                    <ProvideInput
                                                        tab={tab}
                                                    />
                                                </Tab.Pane>

                                                {/* <Tab.Pane className={tab.third ? "active show" : ""}>
                                                    <QuestionTypes />
                                                </Tab.Pane> */}

                                                <Tab.Pane className={tab.forth ? "active show" : ""}>
                                                    <CustomizeQuiz />
                                                </Tab.Pane>

                                                <Tab.Pane className={tab.fifth ? "active show" : ""}>
                                                    <FinalizePublish />
                                                </Tab.Pane>

                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />

            </>
    )
}

export default CreateQuiz;