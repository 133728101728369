import React from 'react'
import { useSelector } from 'react-redux'

const TalkingHead = () => {
    const campaign = useSelector(state => state.video.data)
    const selectedSlide = campaign.slides.find(({ isSelected }) => +isSelected === 1)

    return (
        selectedSlide.faceNarration.enable && selectedSlide.faceNarration.media.url ?
            <div
                className={`logo-${selectedSlide.faceNarration.media.pos} Talking_Wrap ${selectedSlide.faceNarration.media.type === "video" ? "Talking_Wrap_video" : ""}`}
                style={{ height: selectedSlide.faceNarration.media.height, width: selectedSlide.faceNarration.media.width }}
            >
                <img
                    src={selectedSlide.faceNarration.media.url}
                    alt=""
                    style={{ borderRadius: selectedSlide.faceNarration.media.shape === "circle" ? "50%" : "0%" }}
                />

            </div>
            : ''
    )
}

export default TalkingHead