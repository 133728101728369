import React, { useEffect, useState } from 'react'
import Footer from '../../CommonComponents/Footer/Footer'
import SideNav from '../../CommonComponents/Navs/SideNav'
import Navbar from '../../CommonComponents/Navs/Navbar'
import TitleBar from '../../CommonComponents/TitleBar'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import CourseHeader from './CourseHeader'
import queryString from 'query-string'
import { onFetchAiData, onFetchCourse, onGetAiData, onHandleCourse, onRecommendedMore } from '../../../Redux/Actions/CourseActions'
import TopicsRows from './TopicsRows'
import { BsStars } from "react-icons/bs";
import RecommendedRows from './RecommendedRows'

import { RxReload } from "react-icons/rx";
import { IoReloadOutline } from 'react-icons/io5'
import { setAlert } from '../../../Redux/Actions/AlertActions'
import { themeColor } from '../../../Global/Global'

const CreateTopic = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const { id } = queryString.parse(location.search)
    const course = useSelector(state => state.course.data)

    const [type, setType] = useState("key")
    const [aiSuggestion, setAiSuggestion] = useState([])
    const [recommendation, setRecommendation] = useState([])
    const [loader, setLoader] = useState({
        fetch: true,
        search: false,
        update: false
    })
    const [moreLoader, setMoreLoader] = useState(false)
    const [moreLoaderReco, setMoreLoaderReco] = useState(false)

    const [recomendLoader, setRecomendLoaderLoader] = useState({
        fetch: false,
    })
    const handleChange = (e) => {
        const { name, value } = e.target
        dispatch(onHandleCourse(name, value))
    }

    const handleType = (ty) => {
        setType(ty)
    }

    const handleSearch = () => {
        if (course.topicName) {
            let data = {
                text: course.topicName,
                type: "topic",
                limit: 0,
                courseId: id
            }
            setLoader({
                ...loader,
                search: true
            })
            dispatch(onGetAiData(data, setAiSuggestion, setMoreLoader, loader, setLoader))
        } else {
            dispatch(setAlert("Please enter a keyword!", "danger"))
        }
    }

    const handleLoadMore = (type) => {
        if (type === "ai") {
            setMoreLoader(true)
            handleSearch()
        } else {
            setMoreLoaderReco(true)
            handeGenerateAi()
        }
    }


    const handeGenerateAi = () => {
        let data = {}
        setRecomendLoaderLoader({
            ...recomendLoader,
            fetch: true
        })
        let url = "use-recommendation"
        dispatch(onRecommendedMore(url, data, setRecommendation, setMoreLoaderReco, recomendLoader, setRecomendLoaderLoader))
    }


    const handeGenerateMoreAi = () => {
        let data = {
            topicData: recommendation
        }
        setRecomendLoaderLoader({
            ...recomendLoader,
            fetch: true
        })
        let url = "laod-more-recommendation"
        dispatch(onRecommendedMore(url, data, setRecommendation, setMoreLoaderReco, recomendLoader, setRecomendLoaderLoader))
    }

    const fetchAiData = () => {
        let data = {
            courseId: id,
            type: "topic"
        }
        dispatch(onFetchAiData(data, setAiSuggestion))
    }

    const fetchCourse = () => {
        let data = {
            courseId: id
        }
        dispatch(onFetchCourse(data, loader, setLoader))
    }

    useEffect(() => {
        if (id) {
            fetchCourse()
            fetchAiData()
            handeGenerateAi()
        }
    }, [id])

    return (
        loader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Create Course" />
                <Navbar />
                <section className="siteWrap">
                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>

                        <div className="cont_area" >
                            <div className="create-course-top">
                                <div className="row">
                                    <div className="col">
                                        <div className="research-head-left">
                                            <h2>
                                                <span>
                                                    <CourseHeader
                                                        courseName={course.projectName}
                                                        courseId={course.id}
                                                        topicName={course.topicName}
                                                    />
                                                </span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="create-course-mid alt">
                                <div className="container">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-11 text-center" >
                                            <div className="row" >
                                                <div className="col-md-12">
                                                    <h2 className="text-center gradientTxt mb-3"><span>Course topic research</span></h2>
                                                </div>

                                                <div>
                                                    <div className="research-btn-group">
                                                        <button
                                                            className={`demoLink m-0 ${type === "key" ? "act" : ""}`}
                                                            onClick={() => handleType("key")}
                                                        >
                                                            Keyword
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className={`demoLink m-0 ${type === "ai" ? "act" : ""}`}
                                                            onClick={() => handleType("ai")}
                                                        >
                                                            <BsStars /> Use AI Recommendation
                                                        </button>
                                                    </div>
                                                    <div className='topic-key mt-0'>
                                                    </div>
                                                </div>
                                                {type === "ai" ?
                                                    <div>
                                                        {/* <button
                                                            type="button"
                                                            className={`demoLink ${type === "ai" ? "act" : ""}`}
                                                            onClick={() => handeGenerateAi()}
                                                            style={{}}
                                                        >
                                                            <BsStars /> AI Recommendation {recomendLoader.fetch ? <><i className="fa fa-spinner fa-spin" /></> : ""}
                                                        </button> */}
                                                    </div>
                                                    :
                                                    <div className="topic-key mt-2">
                                                        <input
                                                            className="courseInp"
                                                            placeholder="Enter The Keyword"
                                                            required
                                                            name="topicName"
                                                            value={course.topicName}
                                                            maxLength={100}
                                                            onChange={handleChange}
                                                        />
                                                            <button
                                                                type="submit"
                                                                className="demoLink"
                                                                style={course.topicName ? {} : { background: "gray", cursor: "not-allowed" }}
                                                                disabled={course.topicName ? false : true}
                                                                onClick={handleSearch}
                                                            >
                                                                <BsStars /> {loader.search && !moreLoader ? <> Searching topics <i className="fa fa-spinner fa-spin" /> </> : " Search topics"}
                                                            </button> 
                                                    </div>
                                                }
                                                {type === "ai" ? "" :
                                                    <p className="pt-4 d-none">Enter the Keywords for your course research and click on “Use This” to begin</p>
                                                }
                                            </div>
                                            {type === "ai" ?
                                                <>
                                                    {recommendation.length > 0 ?
                                                        <div className="recomnd mt-4" style={{ maxWidth: '100%' }}>
                                                            <div className="d-flex align-items-center justify-content-between pb-4">
                                                                <h5 className="gradientTxt"><span>Recommended AI generated topics</span></h5>
                                                                {/* <button className="simple-btn"><IoReloadOutline /> Load More Recommendaitons</button> */}
                                                            </div>
                                                            <ul>
                                                                {recommendation.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <RecommendedRows
                                                                                curElem={curElem}
                                                                                course={course}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                }
                                                            </ul>
                                                            <div className='mt-3'>
                                                                <button className='demoLink px-5' onClick={handeGenerateMoreAi}>
                                                                    <span>
                                                                        {recomendLoader.fetch && !moreLoaderReco ?
                                                                            <><i className="fa fa-spinner fa-spin" /> Loading More </> :
                                                                            <><RxReload className='me-2' /> Load More</>
                                                                        }
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='mt-3  text-center'>
                                                            {recomendLoader.fetch ?
                                                                <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> : "No AI suggestions found!"}
                                                        </div>
                                                    }
                                                </>
                                                :

                                                <>
                                                    {aiSuggestion.length > 0 ?
                                                        <div className="recomnd mt-4" style={{ maxWidth: '100%' }}>
                                                            <div className="d-flex align-items-center justify-content-between pb-4">
                                                                <h5 className="gradientTxt"><span>Your Searched Topics</span></h5>

                                                            </div>
                                                            <ul>
                                                                {aiSuggestion.map((curElem, index) => {
                                                                    return (
                                                                        <React.Fragment key={index}>
                                                                            <TopicsRows
                                                                                curElem={curElem}
                                                                                course={course}
                                                                                aiSuggestion={aiSuggestion}
                                                                                setAiSuggestion={setAiSuggestion}
                                                                            />
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                                }
                                                                <li>
                                                                    <button className="simple-btn mt-3" onClick={() => handleLoadMore("ai")}>
                                                                        {loader.search && moreLoader ? <><i className="fa fa-spinner fa-spin" /> Loading More Recommendaitons </>
                                                                            : <><IoReloadOutline /> Load More Recommendaitons</>}</button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        :
                                                        <div className='mt-3 d-none'>
                                                            No topic search yet.
                                                        </div>
                                                    }
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </section >
                <Footer />

            </>
    )
}

export default CreateTopic