import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import { useDispatch } from 'react-redux';
import { onAddQuestion } from '../../../Redux/Actions/QuizActions';
import { useSelector } from 'react-redux';

const AddQuestionModal = (props) => {

    const dispatch = useDispatch()
    const quiz = useSelector(state => state.quiz.data)
    const [state, setState] = useState({
        id: false,
        question: "",
        options: ["", "", "", ""],
        answere: ""
    })
    const [availableArr, setAvailableArr] = useState([])

    const handleChange = (e, index) => {
        const { name, value } = e.target
        if (name === "options") {
            setState(prev => {
                let arr = [...prev.options]
                arr[index] = value
                return {
                    ...prev,
                    options: arr
                }
            })
        } else {
            setState({
                ...state,
                [name]: value
            })
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = { ...state }
        dispatch(onAddQuestion(obj))
        props.handleClose()
    }

    useEffect(() => {
        if (quiz.emptyIds && quiz.emptyIds.length > 0) {
            let arr = [...quiz.emptyIds]
            arr.sort((a, b) => a - b)
            setAvailableArr(arr)
        }
    }, [quiz.emptyIds])


    return (
        <Modal className="VideoModal white" show={props.show} centered>
            <Modal.Body>
                <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <h5 className='py-2 text-center'>Add New Question</h5>
                <form className="formSec  p-4" onSubmit={handleSubmit}>
                    <div className={`quiz-prev-outer large`}>
                        <div className={`quiz-prev-wrap large`}>
                            <div className='gradient-box' style={{ background: "none" }}>
                                <div className='quiz-prev-in'>

                                    <div className='quiz-prev-box'>
                                        <label>Enter Question</label>
                                        <div className='edit-area mt-3'>
                                            <input
                                                type='text'
                                                className='input'
                                                placeholder='Enter Your Question Here'
                                                value={state.question}
                                                name='question'
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3 p-1'>
                                        <label>Write Your Options</label>
                                        <div className='selected-ans  px-2'>
                                            {state.options.length > 0 ?
                                                state.options.map((curData, index) => {
                                                    return (
                                                        <div key={index} className={`selected-ans-single ${curData === state.answere && curData !== "" ? "active" : ""}`}>
                                                            <input
                                                                className='input'
                                                                type='text'
                                                                placeholder={`Enter Option ${index + 1}`}
                                                                value={curData}
                                                                name='options'
                                                                onChange={(e) => handleChange(e, index)}
                                                                required
                                                            />

                                                        </div>
                                                    )
                                                })
                                                : ""}
                                        </div>
                                    </div>
                                    <div className='mt-3 p-1'>
                                        <label>Choose Correct Answer</label>
                                        <div className=''>
                                            <div className='question-type'>
                                                <ul>
                                                    {state.options.length > 0 ?
                                                        state.options.map((curData, index) => {
                                                            return (
                                                                <li style={{ width: 100 }} key={index}>
                                                                    <div className='question-type-single'>
                                                                        <div className='d-flex align-items-center'>
                                                                            <div className='col-auto'>
                                                                                <div className='radio-style'></div>
                                                                            </div>
                                                                            <div className='col'>
                                                                                <h6>{index + 1}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <input
                                                                            type="radio"
                                                                            name='answere'
                                                                            checked={curData === state.answere && curData !== ""}
                                                                            value={curData}
                                                                            onChange={handleChange}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </li>)
                                                        }) : ""}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    {availableArr.length > 0 ?
                                        <div className='mt-3 p-1'>
                                            <label>Available places where this question can take place.</label>
                                            <div className=''>
                                                <div className='question-type'>
                                                    <ul>
                                                        {
                                                            availableArr.map((curData, index) => {
                                                                return (
                                                                    <li style={{ width: 60 }} key={index} className='p-0 mx-1'>
                                                                        <div className='question-type-single'>
                                                                            <div className='d-flex align-items-center'>
                                                                                <div className='col-auto'>
                                                                                    {/* <div className='radio-style'></div> */}
                                                                                </div>
                                                                                <div className='col'>
                                                                                    <h6>{curData}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <input
                                                                                type="radio"
                                                                                name='id'
                                                                                checked={+curData === +state.id}
                                                                                value={curData}
                                                                                onChange={handleChange}
                                                                                required
                                                                            />
                                                                        </div>
                                                                    </li>)
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button type="button" className="inpBtn mt-3 create-modal-btn" onClick={props.handleClose}>Cancel</button>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn ms-2"> Add</button>
                    </div>
                </form>
            </Modal.Body >
        </Modal >
    )
}

export default AddQuestionModal