import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
// import dummy16x9 from '../../images/dummy16x9.png'
// import dummy9x16 from '../../images/dummy9x16.png'
import { useSelector } from 'react-redux'
import { FaAngleDown, FaDownload } from 'react-icons/fa6'
import { MdDeleteForever } from 'react-icons/md'
import { RiEdit2Fill } from "react-icons/ri";
import videoThumb from '../../images/fav-new.png';
import { Link } from 'react-router'
// import { Tooltip } from "react-tooltip";
import CommonAlert from '../CommonComponents/CommonAlert'
import { onCheckMergeStatus, onDeleteCampaign } from '../../Redux/Actions/CommonActions'
import PublishModal from './Publish/PublishModal'
import { Trash } from 'iconsax-react'

const MergeCard = ({ curElem, videos, setVideos, searchKey }) => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)
    const [showModel, setShowModel] = useState(false)
    const [memberShip, setMemberShip] = useState([])



    const [sweet, setSweet] = useState({
        enable: false,
        id: false,
        confirmButtonName: "Delete",
        loader: false,
    })

    const [ocp, setOcp] = useState({
        loader: false,
        data: "",
        show: false
    })

    const [common, setCommon] = useState({
        isEditabel: false,
        isDownloadable: false,
        statusText: "",
        showRendering: false,
        showProgAnimation: false,
        urlToRedirect: "",
        progress: 100,
        thumbUrl: "",
        failedData: false,
    })

    const handleDownload = () => {
        let a = document.createElement('a')
        a.href = common.isDownloadable
        a.download = `${curElem.name}.mp4`
        a.target = "_blank"
        a.click()
    }

    const handlePublish = () => {
        setShowModel(true)
    }

    const onDelete = (id) => {
        setSweet({
            ...sweet,
            enable: true,
            id: id
        })
    }

    const onCancel = () => {
        setSweet({
            ...sweet,
            enable: false,
            id: false,
        })
    }

    const onConfirm = () => {
        let data = {
            id: curElem.id
        }
        setSweet({
            ...sweet,
            confirmButtonName: "Deleting",
            loader: true
        })
        dispatch(onDeleteCampaign("delete-merge", data, videos, setVideos, setSweet))
    }

    const fetchMerge = (id, interval) => {
        let data = {
            id: id
        }
        dispatch(onCheckMergeStatus(data, common, setCommon, interval))
    }

    useEffect(() => {
        if (curElem) {
            let text = ""
            let canEdit = false
            let showRend = false
            let showProg = false
            let url = ""
            let videoUrl = ""
            let activeIntrval = false
            let progress = 100
            let bgColor = "#117899"
            videoUrl = curElem.output_video
            switch (+curElem.status) {
                case 1:
                    text = "Rendering"
                    canEdit = true
                    showRend = true
                    activeIntrval = true
                    url = "editor"
                    bgColor = "#ffa500"
                    progress = curElem.render_progress
                    break;
                case 2:
                    text = "Video Merged Successful"
                    canEdit = true
                    url = "editor"
                    bgColor = "#0f9628"
                    break;
                case 3:
                    canEdit = true
                    text = "Video Merging Failed"
                    url = "editor"
                    bgColor = "#a62812"
                    break;
                case 4:
                    text = "Merging Videos"
                    canEdit = true
                    showRend = false
                    url = "editor"
                    activeIntrval = true
                    bgColor = "#117899"
                    progress = curElem.render_progress
                    break;
                default:
                    text = ""
                    canEdit = true
                    url = "update-content"
            }

            setCommon({
                ...common,
                statusText: text,
                isEditabel: canEdit,
                status: +curElem.status,
                isDownloadable: videoUrl,
                showRendering: showRend,
                urlToRedirect: url,
                bgColor: bgColor,
                showProgAnimation: showProg,
                thumbUrl: curElem.preview_image,
                failedData: curElem.logFile,
                progress: progress
            })

            if (activeIntrval) {
                const intreval = setInterval(() => {
                    fetchMerge(curElem.id, intreval)
                }, 5000)

                return () => {
                    clearInterval(intreval)
                }
            }
        }
    }, [curElem])

    useEffect(() => {
        if (auth.user) {
            setMemberShip(auth.user.membership.split('__'))
        }
    }, [auth.user])

    return (
        <>

            <div className={curElem.ratio === "16x9" ? "manage_col col-xxl-3 col-lg-3 col-md-6 col-sm-6 col-12" : 'col-xxl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center'}>

                <div className={`videoProject-single ${curElem.ratio === "9x16" ? "videoProject-single-2" : "videoProject-single-1"}`}>
                    <div className={` ${curElem.ratio === "9x16" ? "videoProject-single-top-2" : "videoProject-single-top-1"}`}>
                        <div className="video-card-wrapper-1 dashboard-card-bg">
                            {common.thumbUrl ?
                                <img src={common.thumbUrl} alt='' style={{ width: "100%", height: "100%" }} /> : null}
                            <div className="video-card-content">
                                <img className="mb-3 vidThumb-img  img-fluid" src={videoThumb} />
                            </div>
                            <span className={`qz-tag ${+common.status === 2 ? "active" : ""}`} style={{ background: common.bgColor, zIndex: 5 }} data-tooltip-id={`my-tooltip-${curElem.id}`}>
                                {
                                    common.showRendering ?
                                        <> Rendering {common.progress}%</>
                                        : common.statusText
                                }
                            </span>
                        </div>


                        <div className="vidHover">
                            <div><p>{curElem.created}</p></div>
                            <div className="vidiconAll">
                                <div className="vidHover-single">
                                    <a onClick={onDelete}>
                                        <span className="vidHover-icon"><Trash /></span>
                                        <span>Delete</span>
                                    </a>
                                </div>
                                {common.isDownloadable ?
                                    <>
                                        <div className="vidHover-single">
                                            <a href={`${curElem.output_video}`} target='_blank'>
                                                <span className="vidHover-icon"><i className="fa fa-download" aria-hidden="true"></i></span>
                                                <span>Download</span>
                                            </a>
                                        </div>
                                        {auth.user.isClientAccount !== "1" ?
                                            <div className="vidHover-single cursor-pointer">
                                                <a onClick={handlePublish} >
                                                    <span className="vidHover-icon"><i className="fa fa-cloud-upload" aria-hidden="true"></i></span>
                                                    <span>Publish</span>
                                                </a>
                                            </div> : ""}
                                    </> : ""}
                            </div>
                        </div>
                    </div>
                    <div className="videoProject-single-bottom">
                        <div>
                            <h6>{curElem.name}</h6>
                        </div>
                    </div>
                </div>
            </div>

            <PublishModal
                showModel={showModel}
                setShowModel={setShowModel}
                curElem={curElem}

            />

            <CommonAlert
                show={sweet.enable}
                message={`Do you want to delete this merge video?`}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancel}
                performDelete={onConfirm}
                alertLoader={sweet.loader}
                icon={<Trash />}
            />
        </>
    )
}

export default MergeCard