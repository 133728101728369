import React, { useState } from 'react'
import { FiUpload } from 'react-icons/fi'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import dummmyImg from "../../../images/dummy.jpg"
import Swal from 'sweetalert2'
import { onChangeHeadPositions, onChangeHeadShape, onChangeHeadSize, onEnableHeadMovement, onEnableTalkingHead, onSetFaceOnAll, onUpdateTalkingHeadMedia } from '../../../Redux/Actions/VideoActions'
import { onUploadMedia } from '../../../Redux/Actions/CommonActions'
import { themeColor } from '../../../Global/Global'
import { BsQuestionCircle } from 'react-icons/bs'
import InstructionModal from '../../Courses/WriteChapter/InstructionModal'

const TalkingHead = () => {
    let allowedExt = ['image/png', 'image/jpg', 'image/jpeg']
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const selectedSlide = campaign.slides.find(({ isSelected }) => +isSelected === 1)
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => +isSelected === 1)

    const [isAllFace, setIsAllFace] = useState(false)
    const [percent, setPercent] = useState(0)
    const [loader, setLoader] = useState({
        upload: false
    })

    const [show, setShow] = useState(false)
    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleChangeEnable = (e) => {
        dispatch(onEnableTalkingHead(e.target.checked, selectedSlideIndex))
    }
    const handleChangeMovement = (e) => {
        dispatch(onEnableHeadMovement(e.target.checked, selectedSlideIndex))
        setIsAllFace(false)
    }

    const onSuccessUpload = (url, type, thumb) => {
        dispatch(onUpdateTalkingHeadMedia(url, type, selectedSlideIndex))
        setIsAllFace(false)
    }

    const handlePositons = (val) => {
        dispatch(onChangeHeadPositions(val, selectedSlideIndex))
        setIsAllFace(false)
    }

    const handleSize = (val) => {
        if (val === "s") {
            dispatch(onChangeHeadSize(125, selectedSlideIndex))
        } else if (val === "m") {
            dispatch(onChangeHeadSize(175, selectedSlideIndex))
        }
        else {
            dispatch(onChangeHeadSize(256, selectedSlideIndex))
        }
        setIsAllFace(false)
    }

    const handleShape = (val) => {
        dispatch(onChangeHeadShape(val, selectedSlideIndex))
        setIsAllFace(false)
    }

    const onInputChange = (e) => {
        const formData = new FormData()
        if (e.target.files.length > 0) {
            if (allowedExt.includes(e.target.files[0].type)) {
                if (e.target.files[0].size < 5000000) {
                    formData.append('upload_type', "images")
                    formData.append('file', e.target.files[0])
                    dispatch(onUploadMedia(formData, onSuccessUpload, loader, setLoader, setPercent, "image"))
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Max allowed size for images is 5MB!',
                        confirmButtonColor: themeColor
                    })
                }
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have Selected Invalid File Type!',
                    confirmButtonColor: themeColor
                })
            }
        }
    }

    const handleAddAll = (e) => {
        if(e.target.checked){
            setIsAllFace(true)
            dispatch(onSetFaceOnAll(selectedSlide))

        }else{
            setIsAllFace(false)
        }
    }


    return (
        <div className="font-block sad_talker_wrap ">
            <div className='d-flex  justify-content-between'>
                <h2 className='tabTitle'>Talking Head </h2>
                <BsQuestionCircle color={themeColor} size={15} cursor="help" title='Instructions' onClick={handleShow} />
            </div>
            <div className="opt-select">
                <span className='text-capitalize'>Enable</span>
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={selectedSlide.faceNarration.enable}
                        onChange={handleChangeEnable}
                    />
                    <span className="slider round"></span>
                </label>
            </div>
            <hr />
            {selectedSlide.faceNarration.enable ?
                <>
                    <div className="sad_talker_img mt-3 ">
                        {selectedSlide.faceNarration.media.type === "video" ?
                            <video src={selectedSlide.faceNarration.media.url} muted autoPlay />
                            :
                            <img src={selectedSlide.faceNarration.media.url ? selectedSlide.faceNarration.media.url : dummmyImg} alt="" />
                        }
                    </div>
                    <div className='upload-box'>
                        <div className="upload flex-column mt-3">
                            {loader.upload ?
                                <div className=''>
                                    <div className='mb-2'>
                                        <i className="fa fa-spinner fa-spin" />
                                    </div>
                                    <span className='loader-percent'>{percent}%</span>
                                </div>
                                :
                                <>
                                    <FiUpload />
                                    <p>Upload image here</p>
                                    <input
                                        type="file"
                                        onChange={onInputChange}
                                    />
                                </>
                            }
                        </div>
                        <div className="mt-3 me-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={selectedSlide.faceNarration.headMovement}
                                    id="flexCheckDefault"
                                    onChange={handleChangeMovement}
                                />
                                <label className="form-check-label" for="flexCheckDefault">
                                    Include head movements
                                </label>
                            </div>
                        </div>
                        <div className="mt-3 me-3">
                            <div className="d-flex justify-content-between">
                                <div className=" facelogo_position  ">
                                    <h2 className='tabTitle'>Face Position</h2>
                                    <div className="d-flex justify-content-center align-content-center flex-wrap gap-2" style={{ width: "100px" }}>
                                        <div className={`position-single ${selectedSlide.faceNarration.media.pos === "top-left" ? "active" : ""}`} onClick={() => handlePositons("top-left")}></div>
                                        <div className={`position-single ${selectedSlide.faceNarration.media.pos === "top-right" ? "active" : ""}`} onClick={() => handlePositons("top-right")}></div>
                                        <div className={`position-single ${selectedSlide.faceNarration.media.pos === "bottom-left" ? "active" : ""}`} onClick={() => handlePositons("bottom-left")}></div>
                                        <div className={`position-single ${selectedSlide.faceNarration.media.pos === "bottom-right" ? "active" : ""}`} onClick={() => handlePositons("bottom-right")}></div>
                                    </div>
                                </div>
                                <div className=" facelogo_shape">
                                    <h2 className='tabTitle'>Face Shape</h2>
                                    <div className="d-flex justify-content-center align-content-center flex-wrap gap-2" style={{ width: "50px", margin: "0 auto" }}>
                                        <div className={`position-circle ${selectedSlide.faceNarration.media.shape === "circle" ? "active" : ""}`} onClick={() => handleShape("circle")} style={{ cursor: "pointer", borderRadius: "100%" }}></div>
                                        <div className={`position-square ${selectedSlide.faceNarration.media.shape === "square" ? "active" : ""}`} onClick={() => handleShape("square")} style={{ cursor: "pointer" }}></div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mt-4 me-3">
                            <h2 className='tabTitle'>Face Size</h2>
                            <div className="type-div face_size" style={{ padding: "0 40px", }}>
                                <span className={+selectedSlide.faceNarration.media.height === 125 ? "active" : ""} onClick={() => handleSize("s")}>S</span>
                                <span className={+selectedSlide.faceNarration.media.height === 175 ? "active" : ""} onClick={() => handleSize("m")}>M</span>
                                <span className={+selectedSlide.faceNarration.media.height === 256 ? "active" : ""} onClick={() => handleSize("l")}>L</span>
                            </div>


                        </div>
                        <div className="mt-3 me-3">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={isAllFace}
                                    id="talkheadall"
                                    onChange={handleAddAll}
                                    style={{ cursor: "pointer" }}
                                />
                                <label className="form-check-label" htmlFor="talkheadall">
                                    Include in all slides
                                </label>
                            </div>
                        </div>

                    </div>
                </> : ""
            }

            <InstructionModal
                show={show}
                handleClose={handleClose}
            />
        </div >
    )
}

export default TalkingHead