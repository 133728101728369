import { commonAxios } from "../../Global/CommonAxios"
import { setAlert } from "./AlertActions"

export const onFetchPublicQuiz = (data, setState, loader, setLoader) => (dispatch, getState) => {
    commonAxios("fetch-user-quiz", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                setState(res.data)
            }
            setLoader({
                ...loader,
                fetch: false
            })
        }).catch((err) => {
            console.log(err)
        })
}

export const onSubmitUser = (data, navigate, loader, setLoader) => (dispatch, getState) => {
    commonAxios("create-quiz-response", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
                dispatch(setAlert(res.msg, "success"))
                navigate(`/your-quiz?id=${res.data}&uid=${data.userId}&qid=${data.quizId}`)
            } else {
                dispatch(setAlert(res.msg, "danger"))
            }
            setLoader({
                ...loader,
                submit: false
            })
        }).catch((err) => {
            setLoader({
                ...loader,
                submit: false
            })
            dispatch(setAlert(err.msg, "danger"))
            console.log(err)
        })
}

export const onEndQuiz = (data) => (dispatch, getState) => {
    commonAxios("update-quiz-response", data, dispatch, getState().auth.token)
        .then((res) => {
            if (res.status) {
            }
        }).catch((err) => {
            console.log(err)
        })
}