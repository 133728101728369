import React, { useEffect, useState } from 'react';
import { Tab, Nav } from "react-bootstrap";
import Recording from './Recording';
import Sonarity from './Sonarity';
import { setAlert } from '../../../Redux/Actions/AlertActions';
import { setTtsData } from '../../../Redux/Actions/VideoActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import TextToSpeech from './TextToSpeech';
import UploadMusic from '../../CommonComponents/UploadMusic';
import VoiceCloneSlide from './VoiceCloneSlide';
import { Link } from 'react-router';
import { themeColor } from '../../../Global/Global';

const VoiceOver = () => {
    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const elevenLabs = useSelector(state => state.social.elevenlabs)

    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const [selectedAudio, setSelectedAudio] = useState(false)
    const [active, setActive] = useState({
        sonority: false,
        recording: false
    })

    const addVoiceover = (url, duration) => {

        const audio = {
            src: url,
            duration: +duration <= 0 ? 1 : duration,
            meta: {
                languageId: "",
                text: "",
                translateText: "",
                voiceId: "",
            }
        }
        if (+duration <= 30) {
            setSelectedAudio(url);
            dispatch(setTtsData(audio, selectedSlideIndex, true))
        } else {
            dispatch(setAlert("Max allowed duration is 30 seconds.", "danger"))
        }

    }

    const handleActive = (type) => {
        if (type === "sonority" && active.sonority === false) {
            setActive({
                ...active,
                sonority: true,

            })
        }
        else if (type === "recording" && active.recording === false) {
            setActive({
                ...active,
                recording: true
            })
        }
    }

    useEffect(() => {
        setSelectedAudio(campaign.slides[selectedSlideIndex].voiceOver.src)
    }, [selectedSlideIndex])

    return (
        <div className="tabInner">
            <div className="media-block">
                <Tab.Container id="left-tabs-example-2" defaultActiveKey="voice-cloning">
                    <Nav variant="pills" className="inTabNav full">
                        <Nav.Item>
                            <Nav.Link eventKey="voice-cloning">Voice Cloning</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="voice-tab">AI Voices</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="sonarity-tab">Sonority</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="upload-audio">Upload Voiceover</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="recording-tab">Recording</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="voice-cloning">
                            {elevenLabs.length > 0 ?
                                <VoiceCloneSlide />
                                :
                                <div className="alert alert-warning p-3 mt-4" role="alert">
                                    Please go to the integrations tab to connect your ElevenLabs account. <Link to="/integrations" style={{ color: themeColor }}>Click Here</Link>
                                </div>
                            }
                        </Tab.Pane>

                        <Tab.Pane eventKey="voice-tab">
                            <TextToSpeech />
                        </Tab.Pane>

                        <Tab.Pane eventKey="sonarity-tab">
                            <Sonarity
                                selectedSlideIndex={selectedSlideIndex}
                                onSelectAudio={addVoiceover}
                                selectedAudio={selectedAudio}
                                setSelectedAudio={setSelectedAudio}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="upload-audio">
                            <UploadMusic
                                type="voiceover"
                                onSelectAudio={addVoiceover}
                                selectedAudio={selectedAudio}
                                setSelectedAudio={setSelectedAudio}
                            />
                        </Tab.Pane>

                        <Tab.Pane eventKey="recording-tab">
                            <Recording
                                active={active}
                                onSelectAudio={addVoiceover}
                                selectedAudio={selectedAudio}
                                setSelectedAudio={setSelectedAudio}
                            />
                        </Tab.Pane>


                    </Tab.Content>

                </Tab.Container>
            </div>
        </div>
    )
}

export default VoiceOver