import React, { useState } from 'react'
import { IoCopyOutline } from "react-icons/io5";
import { BiEditAlt } from "react-icons/bi";
import { MdDragIndicator } from "react-icons/md";

import { FaDesktop } from "react-icons/fa";
import { MdPhoneIphone } from "react-icons/md";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import MobileView from './MobileView';
import { ImMobile } from "react-icons/im";

const FinalizePublish = () => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)

  const [activeIndex, setActiveIndex] = useState(0)

  const [view, setView] = useState("mobile")

  return (
    <>
      <div className='quiz-card'>
        {/* <h5 className='gradientTxt text-center'><span>Preview, Edit and Publish your Quiz</span></h5> */}
        {/* <p className='text-center pt-2'>Click on the edit icon to edit a text. Drag each option to reorder </p> */}
        {quiz.quizData.length > 0 ?
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <div className='text-center mt-2'>
              <Nav variant="v" className="tabStyleTwo">
                <Nav.Item onClick={() => setView("mobile")}>
                  <Nav.Link eventKey="first"><ImMobile /></Nav.Link>
                </Nav.Item>
                <Nav.Item onClick={() => setView("desktop")}>
                  <Nav.Link eventKey="second"><FaDesktop /></Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="first">
                <React.Fragment>
                  <MobileView
                    view={view}
                    activeIndex={activeIndex}
                    setActiveIndex={setActiveIndex}
                  />
                </React.Fragment>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <MobileView
                  view={view}
                  activeIndex={activeIndex}
                  setActiveIndex={setActiveIndex}
                />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          :

          <div className='text-center mt-4'>
            <h4>No quiz generated yet!</h4>
          </div>
        }

      </div>
    </>
  )
}

export default FinalizePublish