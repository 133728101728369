import React, { useState } from "react";
import logo from '../../../images/LOGO.png';
import logo2 from '../../../images/logo-sm.svg';
import { useSelector } from "react-redux";
import { IoMenu, IoArrowBack } from "react-icons/io5";

import { IoChevronDownOutline } from "react-icons/io5";
import { Link, NavLink } from "react-router";

const SideNav = () => {
  const auth = useSelector(state => state.auth)
  const rebrandData = useSelector(state => state.rebrand.data);

  const [isActive, setIsActive] = useState(false);
  const toggleClass = () => {
    setIsActive(!isActive);
  };

  const [navDrop, setNavDrop] = useState(false);
  const toggleClass2 = () => {
    setNavDrop(!navDrop);
  };

  return (
    <>
      <div className={isActive ? "menu active" : "menu"}>
        <div className="logo">
          <Link to={'/dashboard'}><img className={`${rebrandData ? "logo-image-set" : ""} img-fluid`} src={rebrandData ? rebrandData.logo : logo} /></Link>
        </div>

        <div className="logo-sm">
          <Link to={'/dashboard'}><img className={`${rebrandData ? "logo-image-set" : ""} img-fluid`} src={rebrandData ? rebrandData.logo2 : logo2} /></Link>
        </div>

        <div className='toggleMenu' onClick={toggleClass}>{isActive ? <IoArrowBack /> : <IoMenu />} {isActive ? "Back" : "Menu"}</div>

        <div className='link-list'>
          <ul>
            <li><NavLink to="/dashboard"><span><img src={require('../../../images/dashboard/dashboard.svg').default} alt="" /></span> Dashboard</NavLink></li>
            <li><NavLink to="/courses"><span><img src={require('../../../images/dashboard/courses.svg').default} alt="" /></span> Courses</NavLink></li>
            <li><NavLink to="/promo-video"><span><img src={require('../../../images/dashboard/promo.svg').default} alt="" /></span> Promo Video</NavLink></li>

            <li onClick={toggleClass2} className={navDrop ? 'active' : ''}><a><span><img src={require('../../../images/dashboard/quiz-maker.svg').default} alt="" /></span> Quiz <span style={{ marginLeft: 'auto' }}><IoChevronDownOutline /></span></a>
              <ul>
                <li>
                  <NavLink to="/quiz-generation"><span><img src={require('../../../images/dashboard/quiz-response.svg').default} alt="" /></span> Quiz Maker</NavLink>
                </li>
                {/* <li>
                  <NavLink to="/review-participants"><span><img src={require('../../../images/dashboard/quiz-response.svg').default} alt="" /></span> Quiz Response</NavLink>
                </li> */}
                <li>
                  <NavLink to="/participants"><span><img src={require('../../../images/dashboard/participants.svg').default} alt="" /></span> Participants</NavLink>
                </li>
              </ul>
            </li>

            <li><NavLink to="/landing-page"><span><img src={require('../../../images/dashboard/landing.svg').default} alt="" /></span> Landing Page</NavLink></li>
            {auth.user.isClientAccount !== "1" ?
              <li><NavLink to="/integration"><span><img src={require('../../../images/dashboard/integration.svg').default} alt="" /></span> Integrations</NavLink></li> : ""}
          </ul>
        </div>
      </div>
    </>
  )
}

export default SideNav