import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import modalCloseIcon from '../../images/modal-close.png';
import { useNavigate } from 'react-router';
import { onCreateCourse } from '../../Redux/Actions/CourseActions';
import { fetchData } from '../../Redux/Actions/CommonActions';
import Swal from 'sweetalert2';
import { themeColor } from '../../Global/Global';

const CreateCourseModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [data, setData] = useState({
        projectName: "",
        language: "English",
        langCode: "en-US",
        country: "United States",
    })
    const [loader, setLoader] = useState({
        create: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (name === "language") {
            const lang = language.find(({ name }) => name === value)
            // if (+lang.polly_support === 0) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Warning...',
            //         text: `We do not have AI voice for this language, thus the video generated will not have any voiceover.`,
            //         confirmButtonColor: themeColor
            //     })
            // }
        }
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        let obj = {
            ...data
        }
        let count = country.find(({ country_name }) => country_name === data.country)
        let lang = language.find(({ name }) => name === data.language)
        if (count && lang) {
            obj.langCode = `${lang.code}-${count.code}`
        }
        setLoader({
            ...loader,
            create: true
        })
        dispatch(onCreateCourse(obj, navigate, handleClose, loader, setLoader))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchCountry = () => {
        let data = {}
        dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    }

    useEffect(() => {
        fetchLanguage()
        fetchCountry()
    }, [])

    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec  p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Create Course </h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            name='projectName'
                            value={data.projectName}
                            placeholder="Course Name"
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className='row'>
                        <div className='col-6 p-3'>
                            <label>Country</label>
                            <select
                                className='inpField-inp'
                                name='country'
                                value={data.country}
                                onChange={handleChange}
                                required
                            >
                                <option value={""}>Select Country</option>
                                {country.length > 0 ?
                                    country.map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                        <div className='col-6 p-3'>
                            <label>Language</label>
                            <select
                                className='inpField-inp'
                                name='language'
                                value={data.language}
                                onChange={handleChange}
                            >
                                {language.length > 0 ?
                                    language.filter((curElem) => {
                                        return +curElem.id !== 40
                                    }).map((curElem, index) => {
                                        return (
                                            <option key={index} value={curElem.name}>{curElem.name}</option>
                                        )
                                    })
                                    : ""}
                            </select>

                        </div>
                    </div>
                    <div className='text-center'>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn">Next {loader.create ? <i className="fa fa-spinner fa-spin mr-2" /> : ''}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateCourseModal