import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { updateAnimation } from "../../../Redux/Actions/VideoActions";

const Animation = () => {

    const dispatch = useDispatch()
    const campaign = useSelector(state => state.video.data)
    const selectedSlide = campaign.slides.find(({ isSelected }) => isSelected === "1")
    const selectedSlideIndex = campaign.slides.findIndex(({ isSelected }) => isSelected === "1")
    const selectedLayer = selectedSlide.layers.find(({ isSelected }) => isSelected === true)
    const selectedLayerIndex = selectedSlide.layers.findIndex(({ isSelected }) => isSelected === true)

    const [anim, setAnim] = useState([])
    const [state, setState] = useState({
        delay: 0,
        duration: 0.5
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        if (selectedLayerIndex !== -1) {
            if (name === "animation") {
                dispatch(updateAnimation(value, selectedSlideIndex, selectedLayerIndex, name))
            } else {
                if (value === "") {
                    dispatch(updateAnimation("0s", selectedSlideIndex, selectedLayerIndex, name))
                } else {
                    dispatch(updateAnimation(value + "s", selectedSlideIndex, selectedLayerIndex, name))
                }

            }
        }
    }

    const fetchAnimation = () => {
        dispatch(fetchData("animation-list", {}, setAnim, false, false, false, "shortDataLg"))
    }

    useEffect(() => {
        fetchAnimation()
    }, [])

    useEffect(() => {
        if (selectedLayer) {
            setState({
                ...state,
                duration: typeof (selectedLayer.animation.duration) === "number" ? selectedLayer.animation.duration : selectedLayer.animation.duration.split("s")[0],
                delay: typeof (selectedLayer.animation.delay) === "number" ? selectedLayer.animation.delay : selectedLayer.animation.delay.split("s")[0]
            })
        }
    }, [selectedLayer])

    return (
        <>
            <div className="tabInner">
                <div className="media-block">
                    <div className="add-block-single txt-format">
                        <h2 className="tabTitle">Animations</h2>
                        <div className="left-scroll">
                            <div className="font-block mt-2">
                                <h6>Type</h6>
                                <select
                                    className="mt-2"
                                    name="animation"
                                    onChange={(e) => handleChange(e)}
                                    value={selectedLayer ? selectedLayer.animation.name : ""}
                                >
                                    <option value="">Select Animation</option>
                                    {anim.length > 0 ?
                                        anim.sort((a, b) => {
                                            if (a.name.replace("animate__", "") > b.name.replace("animate__", "")) {
                                                return 1
                                            } else {
                                                return -1
                                            }
                                        }).map((curElem, index) => {
                                            return (
                                                <option className='text-capitalize' key={index} value={curElem.name}>{curElem.name.replace("animate__", "")}</option>
                                            )
                                        }) : ""
                                    }
                                </select>
                            </div>

                            <div className="font-block mt-3">
                                <h6>Duration(Sec)</h6>
                                <input
                                    className="mt-2"
                                    name='duration' 
                                    type="number"
                                    min={0.5}
                                    value={state.duration}
                                    step={0.5}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>

                            <div className="font-block mt-3">
                                <h6>Start Delay</h6>
                                <input
                                    className="mt-2"
                                    name='delay'
                                    type="number"
                                    min={0}
                                    value={state.delay}
                                    step={0.5}
                                    onChange={(e) => handleChange(e)}
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Animation;