import React from 'react'
import { BsStars } from "react-icons/bs";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { onChangeCustomization, onChangeCustomizationParticipant } from '../../Redux/Actions/QuizActions';

const CustomizeQuiz = () => {
  const dispatch = useDispatch()
  const quiz = useSelector(state => state.quiz.data)
  const autoresponder = useSelector(state => state.social.autoresponder)

  const handleChangeCustomization = (e) => {
    let { name, value } = e.target
    if (name === "noOfQuestion") {
      if (value > 50) {
        value = 50
      }
      else if (value < 0) {
        value = 1
      }
      // value = value.replace(/[^0-9]*/g,'')
    }
    if (name === "timeLimit") {
      if (value > 150) {
        value = 150
      } else if (value < 0) {
        value = 1
      }
    }

    dispatch(onChangeCustomization(name, value))
  }

  const handleDot = (e) => {
    if (e.key === '.') {
      e.preventDefault()
    }
  }

  const handlePaste = (e) => {
   e.preventDefault()
  }

  const handleChangeCustomizationParticipant = (e) => {
    dispatch(onChangeCustomizationParticipant(e.target.name, e.target.checked))
  }

  return (
    <>
      <div className='quiz-card'>
        <h5 className='gradientTxt'><span>Select The Type of Questions to Include in The Quiz</span></h5>
        <h6 className='pt-4'>Difficulty Levels:</h6>
        <p>Choose between Easy, Medium, or Hard. AI adjusts question complexity accordingly.</p>

        <div className='question-type auto-width'>
          <ul>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Easy</h6>
                  </div>
                </div>
                <input
                  type="radio"
                  name='difficultyLevel'
                  value={"easy"}
                  checked={quiz.quizCustomization.difficultyLevel === "easy"}
                  onChange={handleChangeCustomization}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Medium</h6>
                  </div>
                </div>
                <input
                  type="radio"
                  value={"medium"}
                  name='difficultyLevel'
                  checked={quiz.quizCustomization.difficultyLevel === "medium"}
                  onChange={handleChangeCustomization}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Hard</h6>
                  </div>
                </div>
                <input
                  type="radio"
                  value={"hard"}
                  name='difficultyLevel'
                  checked={quiz.quizCustomization.difficultyLevel === "hard"}
                  onChange={handleChangeCustomization}
                />
              </div>
            </li>
            {/* <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6><BsStars /> Use AI Recommendation</h6>
                  </div>
                </div>
                <input type="radio" name='group-3' />
              </div>
            </li> */}
          </ul>
        </div>


        <div className='row'>
          <div className='col-lg-6'>
            <h6 className='pt-4'>Question Count:</h6>
            <p style={{ fontSize: '0.85rem' }}>Specify the number of questions (Max 50).</p>
            <div className='gradient-box mt-3'>
              <input
                className='input'
                type="number"
                placeholder='28'
                name='noOfQuestion'
                max={50}
                onPaste={handlePaste}
                onKeyDown={handleDot}
                value={quiz.quizCustomization.noOfQuestion}
                onChange={handleChangeCustomization}
              />
            </div>
          </div>
          <div className='col-lg-6'>
            <h6 className='pt-4'>Time Limit:</h6>
            <p style={{ fontSize: '0.85rem' }}>Set a time limit (in minutes max 150) for completing the quiz to encourage focus.</p>
            <div className='gradient-box mt-3'>
              <input
                className='input'
                type="number"
                placeholder='60'
                name='timeLimit'
                onKeyDown={handleDot}
                value={quiz.quizCustomization.timeLimit}
                onPaste={handlePaste}
                onChange={handleChangeCustomization}
              />
            </div>
          </div>
        </div>


        <h6 className='pt-4'>Participant’s Details to Collect</h6>
        <p>What information needs to be captured before starting the quiz.</p>

        <div className='question-type auto-width'>
          <ul>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>First Name</h6>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='firstName'
                  checked={quiz.quizCustomization.participantDetails.firstName}
                // onChange={handleChangeCustomizationParticipant}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Last Name</h6>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='lastName'
                  checked={quiz.quizCustomization.participantDetails.lastName}
                  onChange={handleChangeCustomizationParticipant}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Email</h6>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='email'
                  checked={quiz.quizCustomization.participantDetails.email}
                  onChange={handleChangeCustomizationParticipant}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Gender</h6>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='gender'
                  checked={quiz.quizCustomization.participantDetails.gender}
                  onChange={handleChangeCustomizationParticipant}
                />
              </div>
            </li>
            <li>
              <div className='question-type-single'>
                <div className='d-flex'>
                  <div className='col-auto'>
                    <div className='radio-style'></div>
                  </div>
                  <div className='col'>
                    <h6>Age</h6>
                  </div>
                </div>
                <input
                  type="checkbox"
                  name='age'
                  checked={quiz.quizCustomization.participantDetails.age}
                  onChange={handleChangeCustomizationParticipant}
                />
              </div>
            </li>
          </ul>
        </div>

        <div className='row'>
          <div className='col-lg-12'>
            <h6 className='pt-4'>Autoresponder</h6>
            {/* <p style={{ fontSize: '0.85rem' }}>Specify the number of each questions (5, 10, 20).</p> */}
            <div className='gradient-box mt-3'>
              <select
                className='input'
                name='autoresponderId'
                value={quiz.quizCustomization.autoresponderId}
                onChange={handleChangeCustomization}
              >
                <option>Select Autoresponder</option>
                {autoresponder.length > 0 ?
                  autoresponder.map((curElem, index) => {
                    return (
                      <option key={index} value={curElem.id}>{curElem.username}</option>
                    )
                  })
                  : ""}
              </select>
            </div>
          </div>

        </div>

      </div>
    </>
  )
}

export default CustomizeQuiz