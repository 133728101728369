import React, { useState } from "react";
import { useDispatch } from "react-redux";
import logo from '../../images/LOGO.png';
import { forgetPassword } from "../../Redux/Actions/AuthActions";
import { Link } from "react-router";
import LoginFooter from "../CommonComponents/Footer/LoginFooter";
import TitleBar from "../CommonComponents/TitleBar";
import { appName } from "../../Global/Global";

const ForgetPassword = () => {

    const dispatch = useDispatch()
    const [loader, setLoader] = useState(false)
    const [email, setEmail] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault()
        let data = {
            email: email
        }
        setLoader(true)
        dispatch(forgetPassword(data, setLoader))
    }
    return (
        <>
            <TitleBar title="Forgot Password" />
            <header className="loginHeader">
                <div className="container-fluid">
                    <div className="loginLogo">
                        <img src={logo} />
                    </div>
                </div>
            </header>
            <div className="login-container">
                <div className="container full-height-container">
                    <div className="row full-height-row align-items-center">
                        <div className="col-lg-6">
                            <div className="login-container-left">
                                <h2 className='gradientTxt alt'>Welcome To <br /> <span>{appName}</span></h2>
                                <p>Take your course creation to the next level with cutting-edge AI tools designed to save time, enhance engagement, and maximize your profits. From personalized video content to fully automated marketing assets, everything you need to create, customize, and sell professional-grade courses is at your fingertips.</p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="login-container-right">
                                <div className="login-container-right-main">
                                    <h2><span>Enter </span> Your Email Address</h2>
                                    <form method="post" onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <label>Email Address</label>
                                            <input
                                                type="email" 
                                                className="form-control"
                                                placeholder="Enter Email"
                                                onChange={(e) => setEmail(e.target.value)}
                                                required
                                            />
                                        </div>

                                        <div className="form-group">
                                            <button type="submit" className="demoLink btn-block m-0" disabled={loader}>
                                                {loader ? <i className="fa fa-spinner fa-spin mr-2" /> : ''} Submit
                                            </button>
                                        </div>

                                        <div className="form-group">
                                            <p className="forgotLink">Remember Login ? <Link to="/login" className="text-center "> Click here</Link></p>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />

        </>
    )
}

export default ForgetPassword;