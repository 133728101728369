import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { onFetchCampaign, onUpdateCampaign } from "../../../Redux/Actions/VideoActions";
import { useDispatch } from "react-redux";
import queryString from "query-string"
import { fetchData } from "../../../Redux/Actions/CommonActions";
import { FaSpinner } from "react-icons/fa";
import { GiCheckMark } from "react-icons/gi"
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { setAlert } from "../../../Redux/Actions/AlertActions";
import TitleBar from "../../CommonComponents/TitleBar";
import Navbar from "../../CommonComponents/Navs/Navbar";
import { onUpdateTemplate } from "../../../Redux/Actions/LandingActions";
import { themeColor } from "../../../Global/Global";
import SideNav from "../../CommonComponents/Navs/SideNav";
import Footer from "../../CommonComponents/Footer/Footer";
import TemplatePreview from "../TemplatePreview";
import { AiOutlineEye } from "react-icons/ai";



const ChooseTemplate = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const { id } = queryString.parse(location.search)
    const [templates, setTemplates] = useState([])
    const [landingPageData, setLandingPageData] = useState(false)
    const [loader, setLoader] = useState({
        fetch: true,
        next: false

    })

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false) }
    const handleShow = (img) => {
        setShow(true)
        setState({
            ...state,
            image: img
        })
    }

    const [state, setState] = useState({
        templateId: landingPageData[0]?.templateId,
        image: ""
    })

    const handleSelect = (id, image) => {
        setState({
            ...state,
            templateId: id,
            image: image
        })
    }

    const handleSubmit = () => {
        if (+state.templateId === 0) {
            dispatch(setAlert("Please select a template to continue!", "danger"))
            return
        }

        let data = {
            ...landingPageData[0],
            ...state,
            id: id,
            method: "generate"
        }

        setLoader({
            ...loader,
            next: true
        })
        dispatch(onUpdateTemplate(data, navigate, loader, setLoader))
    }

    const fetchTemplates = () => {
        dispatch(fetchData("fetch-templates", {}, setTemplates, loader, setLoader))
    }
    const fetchLandingPage = () => {
        let data = {
            id: id
        }
        dispatch(fetchData("fetch-landing-page", data, setLandingPageData, false, false))
    }


    useEffect(() => {
        fetchTemplates()
        fetchLandingPage()
    }, [])

    useEffect(() => {
        setState({
            ...state,
            templateId: landingPageData[0]?.templateId
        })
    }, [landingPageData[0]])

    return (
        <>
            <TitleBar title="Landing Page" />
            <Navbar />

            <section className="siteWrap">
                <div className="cont_wrap">
                    <div className="side_bar"><SideNav /> </div>

                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <h3 className="gradientTxt"><span>Select a template</span></h3>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <button class="demoLink grey" onClick={() => navigate(`/landing-page/content-extraction?id=${id}`)}><span><BsArrowLeft /> Back</span></button>
                                        <button class="demoLink" onClick={handleSubmit}><span>{loader.next ? <> Next <i className="fa fa-spinner fa-spin mr-2" /></> : <> Next <BsArrowRight /></>}</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="container">


                            <div className="tempList">

                                <div className={`${state.dimension === "9x16" ? " col-lg-8 col-10" : ""}`} style={{ margin: "0 auto" }}>
                                    <ul>
                                        {templates.length > 0 ?
                                            templates.map((curElem, index) => {
                                                return (
                                                    <li className="temp-prev" key={index}>
                                                        <div className="tempList-Single">
                                                            <div className={`${state.dimension === "9x16" ? "template-single_dynmic" : "template-single"}`} style={{ cursor: "pointer" }}>
                                                                <div className={`${state.dimension === "16x9" ? "template-img_16X9" : "template-img"}`} style={{ position: "relative" }}>
                                                                    <img src={curElem.preview_image} alt='image' />
                                                                    <div className="message-image-opt">
                                                                        <button class="demoLink small m-0" onClick={() => handleSelect(curElem.id, curElem.preview_image)}><span>Select</span></button>
                                                                        <div className="embed-user-icon" onClick={() => handleShow(curElem.preview_image)}><AiOutlineEye /></div>
                                                                    </div>
                                                                    {+curElem.id === +state.templateId ?
                                                                        <div className='select-tile-style' style={{ borderRadius: 11, zIndex: "unset" }}>
                                                                            <GiCheckMark style={{ zIndex: 4, position: "relative" }} />
                                                                        </div> : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            })

                                            :
                                            <div className='text-center mt-3' style={{ height: "100%", width: "100%" }}>
                                                {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ color: themeColor, fontSize: 25 }} /> : ""}
                                            </div>
                                        }

                                    </ul>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </section>

            <TemplatePreview
                show={show}
                handleClose={handleClose}
                image={state.image}
            />

            <Footer />

        </>
    )

}

export default ChooseTemplate
