import React, { useEffect, useState } from 'react'
import { SearchNormal } from "iconsax-react";
import { BsStars } from "react-icons/bs";
import videoThumb from '../../images/fav-new.png';

import { Modal } from 'react-bootstrap';
import modalCloseIcon from '../../images/modal-close.png';
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { Link } from 'react-router';
import Footer from '../CommonComponents/Footer/Footer';

import Dropdown from 'react-bootstrap/Dropdown';
import { PiDotsThreeCircle } from "react-icons/pi";
import CreateLandingModal from './CreateLanding/CreateLandingModal';
import { useDispatch } from 'react-redux';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { themeColor } from '../../Global/Global';
import LandingPageCard from './CreateLanding/LandingPageCard';
import ChooseTemplate from './CreateLanding/ChooseTemplate';
import { useSelector } from 'react-redux';


const LandingPage = () => {

    const dispatch = useDispatch();
    const auth = useSelector(state => state.auth)

    const [q, setQ] = useState("")
    const [landingPage, setLandingPage] = useState([]);
    const [loader, setLoader] = useState({
        fetch: true
    });


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const [show2, setShow2] = useState(false);
    // const handleClose2 = () => setShow2(false);
    // const handleShow2 = () => setShow2(true);

    const fetchAllLandingPage = () => {
        dispatch(fetchData("fetch-landing-all", {}, setLandingPage, loader, setLoader))
    }

    useEffect(() => {
        fetchAllLandingPage();
    }, [])

    return (
        <>
            <TitleBar title="Landing Page" />
            <Navbar />
            <section className="siteWrap">

                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">

                        <div className="projectTitle-wrap">
                            <div className="projectTitle row">
                                <div className="col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder="Search Landing Page"
                                                value={q}
                                                onChange={(e) => setQ(e.target.value)}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        {+auth.user.isClientAccount === 1 ? null :
                                            <button onClick={handleShow} className="demoLink"><BsStars /> Create New Landing Page</button>}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container">
                            <div className="forproject">
                                <div className="videoProject-top mt-2">
                                    <h2><span>Total Landing Pages ({landingPage.length})</span></h2>
                                </div>
                                <div className="row mt-5 mb-5">

                                    {landingPage.length > 0 ?
                                        landingPage.filter((curElem) => {
                                            return curElem.name.toLowerCase().includes(q.toLowerCase())
                                        }).length > 0 ?
                                            landingPage.filter((curElem) => {
                                                return curElem.name.toLowerCase().includes(q.toLowerCase())
                                            }).map((curElem, index) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        <LandingPageCard
                                                            curElem={curElem}
                                                            landingPage={landingPage}
                                                            setLandingPage={setLandingPage}
                                                            fetchAllLandingPage={fetchAllLandingPage}
                                                        />
                                                    </React.Fragment>
                                                )
                                            })
                                            :
                                            <div className='col-md-12 text-center'>
                                                <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                    <h4 >
                                                        No landing page available!
                                                    </h4>
                                                </div>
                                            </div>
                                        :
                                        <div className='col-md-12 text-center'>
                                            <div className="no-campaign-card-box text-center text-muted mt-3 load-icon-help">
                                                {loader.fetch ?
                                                    <i className="fa fa-spinner fa-spin" style={{ color: themeColor }} /> :
                                                    <h4 >
                                                        No landing page created yet!
                                                    </h4>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </div>


            </section >

            <Footer />




            {/* <Modal className="VideoModal smaller white" show={show2} onHide={handleClose2} centered>
                <Modal.Body>
                    <div onClick={handleClose2} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className='text-center pt-4 pb-4'>
                        <img src={require('../../images/dashboard/clap.png')} alt="" />
                        <h5 className='pt-3'>Your course on Digital Marketing <br /> Basics is ready!</h5>
                        <div className='d-flex flex-column gap-3 mt-4'>
                            <div><button className='demoLink m-0' style={{ maxWidth: 255 }}>Generate Landing Page</button></div>
                            <div><button className='demoLink grey m-0' style={{ maxWidth: 255 }}>Preview Course</button></div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}

            <CreateLandingModal
                show={show}
                handleClose={handleClose}
            />


        </>
    )
}

export default LandingPage;