import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import { SearchNormal } from 'iconsax-react';
import { Dropdown as PrimeDropdown } from 'primereact/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import CreateCourseModal from '../CommonComponents/CreateCourseModal';
import VideoCard from './VideoCard';
import Footer from '../CommonComponents/Footer/Footer';
import { fetchData } from '../../Redux/Actions/CommonActions';
import MergeCard from './MergeCard';

const Courses = () => {
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth)
    const [show, setShow] = useState(false);
    const [searchKey, setSearchKey] = useState("");
    const [selectedCity, setSelectedCity] = useState(null);
    const [totalCourses, setTotalCourses] = useState(0);
    const [mergeVideo, setMergeVideo] = useState([]);
    const [type, setType] = useState({ name: 'Courses', code: 1 });

    const [loader, setLoader] = useState({ fetch: true })

    const vType = [
        { name: 'Courses', code: 1 },
        { name: 'Merged Videos', code: 2 }
    ];

    const handleShow = () => setShow(true)
    const handleClose = () => setShow(false)

    const handleChangeType = (val) => {
        setType(val);
    };

    const fetchMergeVideo = () => {
        let data = {}
        dispatch(fetchData("fetch-merge-campaign", data, setMergeVideo, loader, setLoader))
    }

    useEffect(() => {
        if (type.code === 2) {
            fetchMergeVideo();
        }
    }, [type]);
    return (
        <>
            <TitleBar title="Courses" />
            <Navbar />
            <section className="siteWrap">
                <div className="cont_wrap">
                    <div className="side_bar">
                        <SideNav />
                    </div>
                    <div className="cont_area">
                        <div className="projectTitle-wrap st-1">
                            <div className="projectTitle row">


                                <div className="col-sm-5 col-lg-6">
                                    <div className="project-input-box">
                                        <div className="project-input-in">
                                            <span><SearchNormal /></span>
                                            <input
                                                type="text"
                                                className="inp"
                                                placeholder={type.code === 1 ? "Search Courses" : "Search Merge Videos"}
                                                onChange={(e) => setSearchKey(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="col-auto">
                                    <div className="projectTitle-right">
                                        <div className="select-style-1">
                                            <PrimeDropdown
                                                value={type}
                                                onChange={(e) => handleChangeType(e.value)}
                                                options={vType}
                                                optionLabel='name'
                                            // placeholder="Select course type"
                                            />
                                        </div>

                                        {+auth.user.isClientAccount !== 1 ? (
                                            <button className="demoLink m-0" onClick={handleShow}>
                                                <i className="fas fa-plus-circle"></i> Create New Course
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div className="container">
                            <div className="forproject">
                                {type.code === 1 ?
                                    <>
                                        <div className="videoProject-top mt-2">
                                            <h2><span>Total Courses ({totalCourses})</span></h2>
                                        </div>
                                        <VideoCard
                                            searchKey={searchKey}
                                            setTotalCourses={setTotalCourses}
                                        />
                                    </>

                                    :
                                    <>
                                        <div className="videoProject-top my-3">
                                            <h2><span>Total Merge Videos ({mergeVideo.length})</span></h2>
                                        </div>
                                        <div className="row mt-2">
                                            {mergeVideo.length > 0 ? (
                                                mergeVideo.filter((curElem) => {
                                                    if (searchKey) {
                                                        return curElem.name.toLowerCase().includes(searchKey.toLowerCase());
                                                    } else {
                                                        return curElem;
                                                    }
                                                }).length > 0 ?
                                                    mergeVideo.filter((curElem) => {
                                                        if (searchKey) {
                                                            return curElem.name.toLowerCase().includes(searchKey.toLowerCase());
                                                        } else {
                                                            return curElem;
                                                        }
                                                    }).map((curElem, index) => {
                                                        return (
                                                            <React.Fragment key={index}>
                                                                <MergeCard
                                                                    videos={mergeVideo}
                                                                    searchKey={searchKey}
                                                                    setVideos={setMergeVideo}
                                                                    curElem={curElem}
                                                                    key={index}
                                                                />
                                                            </React.Fragment>
                                                        );
                                                    }) :

                                                    <div className="no-campaign-card-box text-center text-muted mt-3">
                                                        <h4>
                                                            No merge video found.
                                                        </h4>
                                                    </div>
                                            ) : !loader.fetch ?
                                                <div className="no-campaign-card col-md-12">
                                                    <div className="no-campaign-card-box text-center text-muted mt-3">
                                                        <h4>
                                                            You haven't created any merge video in your account yet.
                                                        </h4>
                                                    </div>
                                                </div>
                                                :
                                                <div className="col-md-12">
                                                    <h4 className="text-center load-icon-help">
                                                        <i className="fa fa-spinner fa-spin mr-2" />
                                                    </h4>
                                                </div>
                                            }

                                        </div>
                                    </>
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <CreateCourseModal
                    show={show}
                    handleClose={handleClose}
                />
            </section>

            <Footer />
        </>
    )
}

export default Courses