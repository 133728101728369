import React, { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { themeColor } from "../../Global/Global";
import CommonAlert from '../CommonComponents/CommonAlert';
import { Trash } from 'iconsax-react';

const VideoTiles = ({ setMergeVideos, mergeVideos, setCurrentSlide, onStop }) => {

    const [sweet, setSweet] = useState({
        enable: false,
        confirmButtonName: "Remove",
        index: -1
    });

    const onDelete = (index) => {
        setSweet({
            ...sweet,
            enable: true,
            index: index,
            confirmButtonName: "Remove",
        });
    }

    const onCancelDelete = () => {
        setSweet({
            ...sweet,
            enable: false,
            confirmButtonName: "Remove",
        });
    };

    const performDelete = () => {
        let activeInd = mergeVideos.findIndex(({ isSelected }) => isSelected === true)
        let removeArr = [...mergeVideos];
        if (+activeInd === +sweet.index && mergeVideos.length !== 1) {
            if (+sweet.index < +mergeVideos.length - 1) {
                removeArr[sweet.index + 1].isSelected = true
                setCurrentSlide(removeArr[sweet.index + 1]);
            } else {
                removeArr[sweet.index - 1].isSelected = true
                setCurrentSlide(removeArr[sweet.index - 1]);
            }
            onStop()
        }
        removeArr.splice(sweet.index, 1);
        if (removeArr.length === 0) {
            setCurrentSlide(false)
        }
        setMergeVideos(removeArr);
        onCancelDelete()
    };


    const activeSlide = (video, index) => {
        let data = [...mergeVideos];
        let selectedIndex = data.findIndex(({ isSelected }) => isSelected === true);
        if (selectedIndex !== -1) {
            data[selectedIndex].isSelected = false;
            data[index].isSelected = true;
        }
        onStop()
        setCurrentSlide(video);
    };


    const onSetRecorder = (result) => {
        if (!result.destination) {
            return;
        }
        const items = reorder(
            mergeVideos,
            result.source.index,
            result.destination.index
        );
        setMergeVideos(items);
    };
    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };


    return (
        <section className="editorReelwrap">
            <div className="editorReel-scroll">
                <DragDropContext onDragEnd={onSetRecorder}>
                    <Droppable droppableId="droppable" direction="horizontal">
                        {(provided) => (
                            <ul
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                            >
                                {mergeVideos.length > 0 ?
                                    mergeVideos.map((video, index) => {
                                        return (
                                            <Draggable
                                                key={index}
                                                draggableId={`id_${index}`}
                                                index={index}
                                            >
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        key={index}
                                                        className={`cursor-pointer editor_main_tiles ${video.isSelected === true ? "active-tile-border" : "deactive-tile-border"} p-0`}
                                                    >
                                                        <div
                                                            style={{
                                                                position: "relative",
                                                                border: "none"
                                                            }}
                                                            className="editorReel-single-li m-0"
                                                        >
                                                            <div className="editorReel-single">
                                                                <img
                                                                    src={video.preview_image}
                                                                    alt=""
                                                                    style={{
                                                                        height: "100%",
                                                                        width: "100%",
                                                                        // objectFit: "contain",
                                                                    }}
                                                                    // className="img-fluid"
                                                                    onClick={() =>
                                                                        activeSlide(video, index)
                                                                    }
                                                                />
                                                                <Trash
                                                                    className="merge-delete"
                                                                    color={themeColor}
                                                                    size={20}
                                                                    onClick={() => onDelete(index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        );
                                    })
                                    :
                                    <li style={{ height: "100%", width: "100%", border: `1px solid ${themeColor}`, borderRadius: "0.5rem" }}>
                                        <div style={{ width: "10.9375rem", height: "5.9375rem" }}>

                                        </div>
                                    </li>
                                }
                                {provided.placeholder}
                            </ul>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <CommonAlert
                show={sweet.enable}
                icon={<Trash />}
                message={"Do you want to remove this video?"}
                confirmButtonColor={""}
                cancelButtonColor={""}
                confirmButtonName={sweet.confirmButtonName}
                cancelButtonName={"Cancel"}
                handleClose={onCancelDelete}
                performDelete={performDelete}
                alertLoader={false}
            />
        </section>
    )
}

export default VideoTiles