import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import syvidIcon from "../../../images/logo-syvideo.svg";
import facebookIcon from "../../../images/logo-facebook.svg";
import youtubeIcon from "../../../images/YT-DEFAULT.png"
import scriptIcon from "../../../images/logo-scriptreel.svg";
import trimIcon from "../../../images/logo-trim.svg";
import liveIcon from "../../../images/logo-livereel.png";
import facebookDisable from "../../../images/FB_DISABLE.png";
import youtubeDisable from "../../../images/YT_DISABLE.png";
import syvidDisable from "../../../images/SYVIDED_DISABLE.png";
import liveDisable from "../../../images/LIVEREEL_DISABLE.png";
import scriptDisable from "../../../images/SCRIPT_DISABLE.png";
import trimDisable from "../../../images/TRIM_DISABLE.png";
import closeModalx from "../../../images/modal-close.png"
import SocialIcons from "./SocialIcons"
import modalCloseIcon from '../../../images/modal-close.png';
import { Modal } from 'react-bootstrap';
import { onPublishVideo } from '../../../Redux/Actions/CommonActions';

const PublishModal = ({ curElem, showModel, setShowModel }) => {
    const socials = useSelector(state => state.social)
    const dispatch = useDispatch()
    const [currSocial, setCurrSocial] = useState([]);
    const [selectIcon, setSelectIcon] = useState(false);
    const [loader, setLoader] = useState(false)
    const [postData, setPostData] = useState({
        title: '',
        description: '',
        video_url: "",
        integration_id: '',
        type: '',
        network: ''
    })


    const currentSocial = (social, type, icon) => {
        console.log(social)
        if (social.length > 0) {
            setCurrSocial(social);
            setSelectIcon(icon);
            setPostData({
                ...postData,
                type: type,
                integration_id: social[0].id,
                network: icon,
            });
        }
    }

    const handleClose = () => {
        setShowModel(false)
    }

    const onInputChange = (e) => {
        setPostData({ ...postData, [e.target.name]: e.target.value });
    }

    const onFormSubmit = (e) => {
        e.preventDefault();
        setLoader(true);
        dispatch(onPublishVideo(postData.type, postData, handleClose, setLoader))
    }

    // useEffect(() => {
    //     if (socialData === false) {
    //         setSocialData(socials)
    //     }
    // }, [socials]);


    useEffect(() => {
        setPostData({
            ...postData,
            video_url: curElem.videoUrl || curElem.output_video
        })
    }, [curElem])

    return (
        <Modal className="VideoModal  white" show={showModel} onHide={handleClose} centered>
            <Modal.Body>
                <div className='p-4'>
                    <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                    <div className="row">
                        <div className="col-sm-12 mb-2 text-center">
                            <h5 className="modal-title" style={{ width: "100%" }}>Publish This Video</h5>

                        </div>
                        <div className="col-sm-12">

                            <div className="icon-response">
                                <ul className="list-inline d-flex  flex-wrap justify-content-center mb-2">
                                    <div
                                        onClick={() => currentSocial(socials.facebook, 'upload-to-facebook', 'facebook', curElem.url)}
                                    >
                                        <SocialIcons
                                            icon={socials.facebook.length > 0 ? facebookIcon : facebookDisable}
                                            status={socials.facebook.length > 0 ? true : false}
                                            name="Facebook"
                                            selectIcon={selectIcon === "facebook" ? "select-social" : ''}

                                        />
                                    </div>
                                    <div
                                        onClick={() => currentSocial(socials.youtube, 'upload-to-youtube', 'youtube', curElem.url)}
                                    >
                                        <SocialIcons
                                            icon={socials.youtube.length > 0 ? youtubeIcon : youtubeDisable}
                                            status={socials.youtube.length > 0 ? true : false}
                                            name="Youtube"
                                            selectIcon={selectIcon === "youtube" ? "select-social" : ''}
                                        />
                                    </div>
                                    <div
                                        onClick={() => currentSocial(socials.syvid, 'push-to-app', 'syvid', curElem.url)}
                                    >
                                        <SocialIcons
                                            icon={socials.syvid.length > 0 ? syvidIcon : syvidDisable}
                                            status={socials.syvid.length > 0 ? true : false}
                                            name="Syvid"
                                            selectIcon={selectIcon === "syvid" ? "select-social" : ''}

                                        />
                                    </div>
                                    <div
                                        onClick={() => currentSocial(socials.scriptreel, 'push-to-app', 'scriptreel', curElem.url)}
                                    >
                                        <SocialIcons
                                            icon={socials.scriptreel.length > 0 ? scriptIcon : scriptDisable}
                                            status={socials.scriptreel.length > 0 ? true : false}
                                            name="ScriptReel"
                                            selectIcon={selectIcon === "scriptreel" ? "select-social" : ''}

                                        />
                                    </div>
                                    <div
                                        onClick={() => currentSocial(socials.trimreel, 'push-to-app', 'trimreel', curElem)}
                                    >
                                        <SocialIcons
                                            icon={socials.trimreel.length > 0 ? trimIcon : trimDisable}
                                            status={socials.trimreel.length > 0 ? true : false}
                                            name="TrimReel"
                                            selectIcon={selectIcon === "trimreel" ? "select-social" : ''}

                                        />
                                    </div>
                                    <div
                                        onClick={() => currentSocial(socials.livereel, 'push-to-app', 'livereel', curElem.url)}
                                    >
                                        <SocialIcons
                                            icon={socials.livereel.length > 0 ? liveIcon : liveDisable}
                                            status={socials.livereel.length > 0 ? true : false}
                                            name="LiveReel"
                                            selectIcon={selectIcon === "livereel" ? "select-social" : ''}
                                        />
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="form-body">
                        <form onSubmit={(e) => onFormSubmit(e)}>
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Title</label>
                                <input type="text" className="form-control text-light" required name="title" onChange={(e) => onInputChange(e)} placeholder="Enter Title" />
                            </div>
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted mb-2">Description</label>
                                <textarea className="form-control text-light publish-discription" required name="description" onChange={(e) => onInputChange(e)} placeholder="Enter Description" />
                            </div>
                            <div className="form-group form-group-alt">
                                <label className="form-text text-muted">Account Type</label>
                                <select
                                    className="form-control"
                                    required
                                    name="integration_id"
                                    value={postData.integration_id}
                                    onChange={(e) => onInputChange(e)}

                                >
                                    <option value={""}>Select Account</option>
                                    {
                                        currSocial.length > 0 ?
                                            currSocial.map((item, index) => {
                                                return (
                                                    <option key={index} style={{ backgroundColor: "#000", color: "#fff" }} value={item.id}>{item?.username}</option>
                                                )
                                            })
                                            : ''
                                    }
                                </select>
                            </div>
                            <div className="text-center mt-4">
                                <button type="submit" className="btn-custom btn-change7" disabled={loader}>
                                    {loader ? <>
                                        Publishing
                                        <i className="fa fa-spinner fa-spin mx-2" />  </> : 'Publish'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default PublishModal