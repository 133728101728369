import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { GiCheckMark } from 'react-icons/gi'
import { onGenerateAi } from '../../../../../Redux/Actions/CommonActions'

const AiImageModal = ({ callbackFun, handleClose, iconIndex }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState("")
    const [checkMedia, setCheckMedia] = useState(-1)
    const [image, setImage] = useState("")
    const [loader, setLoader] = useState({
        generate: false,
        saveLoader: false
    })
    const handleGenerate = () => {
        let data = {
            keyword: keyword
        }
        if (keyword) {
            setLoader({
                ...keyword,
                generate: true
            })
            dispatch(onGenerateAi(data, setImage, loader, setLoader))
        }
    }

    const handleSelect = () => {
        setCheckMedia(image)
    }

    const handleSave = () => {
        let data = {
            url: checkMedia
        }
        callbackFun(data, iconIndex)
        handleClose();
    }

    return (
        <>
            <div className='photo-scroll' style={{ height: "543px" }}>
                <div className="font-block pb-4 mt-3">
                    <h6 className='gradientTxt mb-2'><span>Describe the Image you want</span></h6>

                    <div className='tabSearch'>
                        <textarea style={{ paddingLeft: "0.9375rem" }}
                            className="inp-small mt-2"
                            placeholder="e.g: A rocket launching at sunset."
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </div>

                    <button className="demoLink small m-0 mt-1" onClick={handleGenerate} ><span>{loader.generate ? <>Generating  <i className="fa fa-spinner fa-spin" /></> : "Generate"}</span></button>
                </div>
                {image ?
                    <div className="row sm justify-content-center">
                        <div className="col-4">
                            <div className="photo-single" onClick={handleSelect} style={{ height: 200, cursor: 'pointer' }}>
                                <img alt="" src={image} />
                                {checkMedia === image ?
                                    <div className='select-tile-style'>
                                        <GiCheckMark />
                                    </div> : ""}
                            </div>
                        </div>
                    </div> : ""}

            </div>
            <div className="d-flex align-items-center justify-content-end gap-2 pt-4">
                <button className="demoLink m-0 grey" onClick={handleClose}><span>Cancel</span></button>
                <button className={`demoLink m-0 ${checkMedia !== image ? "grey" : ""}`} disabled={checkMedia !== image} onClick={handleSave}><span>{loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /> </> : "Use"}</span></button>
            </div>
        </>
    )
}

export default AiImageModal
