import React, { useEffect, useRef, useState } from 'react'
import SideNav from '../CommonComponents/Navs/SideNav'
import Footer from '../CommonComponents/Footer/Footer'
import TitleBar from '../CommonComponents/TitleBar'
import Navbar from '../CommonComponents/Navs/Navbar'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import queryString from 'query-string'
import { onEndQuiz, onFetchPublicQuiz } from '../../Redux/Actions/PublicQuizActions'
import QuizQuestionBox from './QuizQuestionBox'
import { useImmer } from "use-immer"
import { Fireworks } from 'fireworks-js'

let interval = false, fireworks = false
const YourQuiz = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const { id, uid, qid } = queryString.parse(location.search)

  const [activeIndex, setActiveIndex] = useState(0)
  const [state, setState] = useState({})
  const [yourQuizData, setYourQuizData] = useImmer([])

  const [start, setStart] = useState("start")
  const [correctAns, setCorrectAns] = useState(0)
  const [second, setSecond] = useState(0)
  const fireworksContainer = useRef(null);
  const [time, setTime] = useState({
    h: 0,
    m: 0,
    s: 0
  })

  const [loader, setLoader] = useState({
    fetch: true,
    start: false
  })


  const handleStart = () => {
    setLoader({
      ...loader,
      start: true
    })
    setTimeout(() => {
      setStart("started")
      setLoader({
        ...loader,
        start: false
      })
    }, 3000)
    if (fireworks) {
      fireworks.stop();
    }

  }


  const onPrev = () => {
    if (activeIndex > 0) {
      setActiveIndex(prev => prev - 1)
    }
  }

  const onNext = () => {
    if (activeIndex < state.quizData.length - 1) {
      setActiveIndex(prev => prev + 1)
    }
  }

  const endQuiz = () => {
    let obj = {
      completionTime: state.quizCustomization.timeLimit * 60 - second,
      id: id,
      responseData: yourQuizData
    }
    dispatch(onEndQuiz(obj))
    setStart("end")
    handleEndTime()

    if (fireworks) {
      fireworks.start();
    }
  }

  const handleStartAgain = () => {
    setSecond(+state.quizCustomization.timeLimit * 60)
    setCorrectAns(0)
    setYourQuizData(state.quizData)
    setStart("start")
    setActiveIndex(0)
  }
  const handleEndTime = () => {
    clearInterval(interval)
    interval = false
  }

  const fetchQuizData = () => {
    let data = { id: qid }
    dispatch(onFetchPublicQuiz(data, setState, loader, setLoader))
  }

  let div = document.getElementById("beacon-container")

  useEffect(() => {
    if (div) {
      div.style.display = "none"
    }

    return () => {
      if (div) {
        div.style.display = "block"
      }
    }
  }, [div])



  useEffect(() => {
    if (start === "started") {
      interval = setInterval(() => {
        setSecond(prev => prev - 1)
      }, 1000)

      return () => clearInterval(interval)
    }
    else if (start === "end" && yourQuizData.length > 0) {
      yourQuizData.forEach((curElem) => {
        if (curElem.answer === curElem.userAnswer) {
          setCorrectAns(prev => prev + 1)
        }
      })
    }
  }, [start])

  useEffect(() => {
    if (second) {
      setTime({
        ...time,
        s: parseInt((second % 60)),
        m: Math.floor((second % 3600 / 60)),
        h: parseInt((second / 3600))
      })
    }
    else {
      if (second === 0 && interval) {
        setTime({
          ...time,
          s: parseInt((second % 60)),
          m: Math.floor((second % 3600 / 60)),
          h: parseInt((second / 3600))
        })
        endQuiz()
      }
    }
  }, [second])

  useEffect(() => {
    if (state && state.quizCustomization) {
      setSecond(+state.quizCustomization.timeLimit * 60)
    }

    if (state && state.quizData) {
      setYourQuizData(state.quizData)
    }
  }, [state])

  useEffect(() => {
    if (qid) {
      fetchQuizData()
    }
  }, [qid])

  useEffect(() => {
    if (fireworksContainer.current) {
      const fireworks = new Fireworks(fireworksContainer.current);
      fireworks.start();

      return () => fireworks.stop();
    }
  }, [start]);




  return (
    loader.fetch ?
      <div className="loader-sec">
        <div className="loader">
        </div>
      </div>
      :
      <>
        <TitleBar title="Quiz" />
        <section className='siteWrap d-flex justify-content-center align-items-center' >
          <div className='container'  >
            <div className='row'>
              <div className='col-md-12'>
                <div className='row pt-5'>
                  <div className='col-sm-12' >
                    <div className={`quiz-prev-outer large`}>
                      <div className={`quiz-prev-wrap large`}>
                        <div className='gradient-box'>
                          {start === "started" && yourQuizData.length > 0 ?
                            yourQuizData.map((curElem, index, arr) => {
                              return (
                                <React.Fragment key={index}>
                                  {activeIndex === index ?
                                    <QuizQuestionBox
                                      curElem={curElem}
                                      onPrev={onPrev}
                                      onNext={onNext}
                                      index={index}
                                      length={arr.length}
                                      setYourQuizData={setYourQuizData}
                                      totalTime={state.quizCustomization.timeLimit}
                                      time={time}
                                    /> : null}
                                </React.Fragment>
                              )
                            })
                            :
                            <div className='quiz-prev-in d-flex justify-content-center align-items-center' style={{ height: 500, position: 'relative' }}>
                              <div className='text-center'>
                                {start === "start" ?
                                  <>
                                    <h2>Welcome to the Quiz</h2>
                                    <h1 className='my-2'>{state.name}</h1>
                                    <button className='demoLink' onClick={handleStart}>{loader.start ? <>Starting <i className="fa fa-spinner fa-spin" /></> : "Start Quiz"}</button>
                                  </>
                                  :
                                  start === "end" ?
                                    <>
                                      <div>
                                        <div
                                          className="fireworks-container"
                                          ref={fireworksContainer}
                                          style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            zIndex: 1
                                          }}
                                        ></div>

                                        <h2 className='mb-2'>Thank You for Completing the Quiz!</h2>
                                        <h2>Your Score</h2>
                                        <div className='score-circle mt-4' style={{ fontWeight: "bold" }}>
                                          {correctAns} / {state.quizData.length}
                                        </div>
                                        <div className='mt-3' style={{position:"relative", zIndex : 99999}}>
                                          <button className='demoLink' onClick={handleStartAgain}>Start Quiz Again</button>
                                        </div>
                                        {/* <h4 className='mt-3'>Total Attempt : {totalAttempt}</h4> */}
                                      </div>
                                    </>
                                    :
                                    ""}
                              </div>
                            </div>
                          }
                        </div>
                        {start === "started" ?
                          <>
                            <div className='quiz-prev-nav' onClick={onPrev}><img src={require('../../images/quiz/nav-prev.svg').default} alt='' /></div>
                            <div className='quiz-prev-nav alt' onClick={onNext}><img src={require('../../images/quiz/nav-next.svg').default} alt='' /></div>
                          </> : ""}
                      </div>
                      {start === "started" ?
                        <div className='mt-2 text-center'>
                          <button className='demoLink' onClick={endQuiz}>End Quiz</button>
                        </div> : ""}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div >
        </section >
      </>
  )
}

export default YourQuiz