import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import AudioFiles from "../AudioFiles";
import { useNavigate } from "react-router";
import CommonAlert from "../../CommonComponents/CommonAlert";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

const Sonority = ({ onSelectAudio, selectedAudio, setSelectedAudio }) => {
  const navigate = useNavigate()
  const socialData = useSelector(state => state.social.sonority);

  const [loader, setLoader] = useState(false);
  const [musicData, setMusicData] = useState(false);
  // const [selectedAudio, setSelectedAudio] = useState(false)
  const [currentUrl, setCurrentUrl] = useState('');


  const [sweet, setSweet] = useState({
    enable: false,
    confirmButtonName: "Redirect",
  })

  const onDelete = () => {
    setSweet({
      ...sweet,
      enable: true,
      confirmButtonName: "Redirect",
    })
  }

  const onCancel = () => {
    setSweet({
      ...sweet,
      enable: false,
      confirmButtonName: "Redirect",
    })
  }


  const performDelete = () => {
    navigate('/integration')
  }


  const fetchSonorityMedia = () => {
    setLoader(true);

    axios({
      method: 'POST',
      url: 'https://regis12.amzitrage.com/fetch-all',
      data: {},
      headers: { 'Content-Type': 'application/json', 'Authorization': socialData[0].token }
    }).then(res => {
      if (res.data.status === true) {
        setMusicData(res.data.data);
      } else {
        // dispatch(setAlert(res.data.message, 'danger'));
      }
      setLoader(false);
    }).catch(err => {
      setLoader(false);
    })
  }

  useEffect(() => {
    if (socialData.length > 0) {
      fetchSonorityMedia();
    }

    return () => {
      setMusicData(false);
    }
  }, [])

  return (
    <>
      <div className="row m-2" style={{ height: "auto" }}>
        {
          socialData.length > 0 ? <div className="alert alert-warning text-center" role="alert">
            This music will apply to the current slide
          </div>
            : <div className="alert alert-warning text-center" role="alert">
              You haven't connected your Sonority account yet. Click <a className="custom-anchor" onClick={() => onDelete()}>Here</a> to connect and use those audios
            </div>
        }
        {
          loader ?
            <div className="col-md-12 text-center">
              <i className={`fa fa-spinner fa-spin`} style={{ color: '#CE88FF', fontSize: '20px' }} />
            </div> : ''
        }
        {
          loader ? '' : musicData !== false ? <h6>Audio Tracks</h6> : ''
        }
        {
          musicData !== false ?
            musicData.merge.map((item, index) => {
              return (
                <AudioFiles
                  name={item.name.length > 20 ? `${item.name.slice(0, 19)}...` : item.name}
                  url={item.output_audio}
                  onSelectAudio={onSelectAudio}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                  id={item.id}
                  key={index}
                />
              )
            })
            : ''
        }
        {
          loader ? '' : musicData !== false ? <h6>Music Tracks</h6> : ''
        }
        {
          musicData !== false ?
            musicData.music.map((item, index) => {
              return (
                <AudioFiles
                  name={item.title}
                  url={item.output_audio}
                  onSelectAudio={onSelectAudio}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                  id={item.id}
                  key={index}
                />
              )
            })
            : ''
        }
        {
          loader ? '' : musicData !== false ? <h6>Voice Tracks</h6> : ''
        }
        {
          musicData !== false ?
            musicData.tts.map((item, index) => {
              return (

                <AudioFiles
                  name={item.title}
                  url={item.output_audio}
                  onSelectAudio={onSelectAudio}
                  selectedAudio={selectedAudio}
                  setSelectedAudio={setSelectedAudio}
                  id={item.id}
                  key={index}
                />
              )
            })
            : ''
        }

      </div>


      <CommonAlert
        show={sweet.enable}
        message={"Please note that navigating from this page will discard all your customizations. Make sure you have saved the customizations."}
        confirmButtonColor={""}
        cancelButtonColor={""}
        confirmButtonName={sweet.confirmButtonName}
        cancelButtonName={"Cancel"}
        handleClose={onCancel}
        alertLoader={sweet.loader}
        performDelete={performDelete}
        icon={<FaExternalLinkSquareAlt />}
      />
    </>
  )
}

export default Sonority;