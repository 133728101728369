import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { onCreatePromoVideo } from '../../../Redux/Actions/VideoActions'
import { Modal } from 'react-bootstrap'
import modalCloseIcon from '../../../images/modal-close.png';
import { BsStars } from 'react-icons/bs'
import { themeColor } from '../../../Global/Global'
import { fetchData } from '../../../Redux/Actions/CommonActions'


const CreateModal = ({ show, handleClose }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [state, setState] = useState({
        name: "",
        sourceType: "course",
    })
    const [loader, setLoader] = useState({
        create: false
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }

    const handleChangeType = (value) => {
        setState({
            ...state,
            sourceType: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let data = { ...state }

        dispatch(onCreatePromoVideo(data, navigate, loader, setLoader))
    }

    // const fetchLanguage = () => {
    //     let data = {}
    //     dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    // }
    // const fetchCountry = () => {
    //     let data = {}
    //     dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    // }

    // useEffect(() => {
    //     fetchLanguage()
    //     fetchCountry()
    // }, [])



    return (
        <Modal className="VideoModal small white" show={show} onHide={handleClose} centered>
            <Modal.Body>
                <div onClick={handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
                <form className="formSec  p-4" onSubmit={handleSubmit}>
                    <div className="inpField">
                        <h5 className='pb-4'>Enter Promo Video Name</h5>
                        <input
                            type="text"
                            className="inpField-inp"
                            placeholder="Promo Video Name Here..."
                            value={state.name}
                            name='name'
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <div className='row justify-content-center mt-4'>
                        <div className='col-lg-12'>
                            <h6>Select a source type</h6>
                            <div className='quiz-source-single mt-2 text-left'>
                                <div className='row align-items-center justify-content-between'>
                                    <div className='col'>
                                        <p>From Course Content</p>
                                    </div>
                                    <div className='col-auto'>
                                        <button type='button' className='btnBlue' onClick={() => handleChangeType("course")} style={state.sourceType === "course" ? { background: themeColor } : {}}>{state.sourceType === "course" ? "Using" : "Use This"}</button>
                                    </div>
                                </div>
                            </div>

                            <div className='quiz-source-single mt-2 text-left'>
                                <div className='row align-items-center justify-content-between'>
                                    <div className='col'>
                                        <p>Custom Input</p>
                                    </div>
                                    <div className='col-auto'>
                                        <button type='button' className='btnBlue' onClick={() => handleChangeType("custom")} style={state.sourceType === "custom" ? { background: themeColor } : {}}>{state.sourceType === "custom" ? "Using" : "Use This"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='text-center'>
                        <button type="submit" className="inpBtn mt-3 create-modal-btn"><BsStars /> Next {loader.create ? <i className="fa fa-spinner fa-spin" /> : ""}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default CreateModal