import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';
import { useLocation, useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { fetchData, onFetchPlatforms } from '../../Redux/Actions/CommonActions';
import { themeColor } from '../../Global/Global';
import { onUpdateLandingPage } from '../../Redux/Actions/LandingActions';
import { setAlert } from '../../Redux/Actions/AlertActions';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

const ContentExtraction = () => {

    const location = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { id } = queryString.parse(location.search)

    const [loader, setLoader] = useState({
        fetch: true,
        next: false
    })
    const [courses, setCourses] = useState([])
    const [language, setLanguage] = useState([])
    const [country, setCountry] = useState([])
    const [platform, setPlatform] = useState(false)
    const [landingPageData, setLandingPageData] = useState(false)


    const [state, setState] = useState({
        id: id,
        cid: "",
        country: "United States",
        language: "English",
        languageCode: "en-US",
        method: "update"
    })

    const handleChange = (e) => {
        let { name, value } = e.target
        setState({
            ...state,
            [name]: value
        })
    }


    const handleSubmit = () => {
        if (state.contentType === "course") {
            if (!state.cid) {
                dispatch(setAlert("Please select the courses first", "danger"))
                return;
            }
            let selectedChapters = courses.filter(val => +val.id === +state.cid)[0]?.chapters
            let selectedCourse = selectedChapters.map(val => { return { id: val.id, cid: val.cid } })
            if (!selectedCourse) {
                dispatch(setAlert("Course data is empty.", "danger"))
                return;
            }

            let data = {
                ...state,
                courseContent: { chapters: selectedCourse }
            }
            setLoader({
                ...loader,
                next: true
            })
            dispatch(onUpdateLandingPage(data, navigate, loader, setLoader))
        } else {
            if (!state.keyword) {
                dispatch(setAlert("Please enter topic to continue", "danger"))
                return;
            }
            let obj = {
                ...state
            }
            let count = country.find(({ country_name }) => country_name === state.country)
            let lang = language.find(({ name }) => name === state.language)
            if (count && lang) {
                obj.languageCode = `${lang.code}-${count.code}`
            }
            setLoader({
                ...loader,
                next: true
            })

            dispatch(onUpdateLandingPage(obj, navigate, loader, setLoader))
        }
    }

    const fetchCourses = () => {
        let data = {}
        dispatch(fetchData("fetch-quiz-courses", data, setCourses, loader, setLoader))
    }

    const fetchLanguage = () => {
        let data = {}
        dispatch(fetchData("fetch-google-language", data, setLanguage, false, false, false, "shortDataLg"))
    }
    const fetchPlatform = () => {
        dispatch(onFetchPlatforms(setPlatform))
    }
    const fetchCountry = () => {
        let data = {}
        dispatch(fetchData("fetch-countries", data, setCountry, false, false, false, "shortDataCt"))
    }

    const [mainLoader, setMainLoader] = useState({
        fetch: true
    })

    const fetchLandingPage = () => {
        let data = {
            id: id
        }
        dispatch(fetchData("fetch-landing-page", data, setLandingPageData, mainLoader, setMainLoader))
    }

    useEffect(() => {
        if (id) {
            fetchLandingPage()
            fetchCourses()
            fetchLanguage()
            fetchPlatform()
            fetchCountry()
        }
    }, [id])

    useEffect(() => {
        let data = { ...landingPageData[0] }
        setState({
            ...state,
            ...data,
            cid: data?.courseContent?.chapters[0]?.cid || courses[0]?.id,
            country: "United States",
            language: "English",
            languageCode: "en-US",
            method: "update"
        })
    }, [landingPageData[0], courses])

    return (
        mainLoader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Quiz" />
                <Navbar />
                <section className="siteWrap w1">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className="col-auto">
                                        <h2 className='pageTitle-2'>{landingPageData[0]?.name}</h2>
                                    </div>
                                    <div className="col-auto">
                                        <div className="projectTitle-right">
                                            <button class="demoLink grey" onClick={() => navigate(`/landing-page/generate-landing-page?id=${id}`)}><span><BsArrowLeft /> Back</span></button>
                                            <button class="demoLink" onClick={handleSubmit}><span>{loader.next ? <> Next <i className="fa fa-spinner fa-spin mr-2" /></> : <> Next <BsArrowRight /></>}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className='quiz-wrap'>
                                    {state.contentType === "course" ?
                                        <h2 className='text-center gradientTxt'><span>Provide input from course materials</span></h2>
                                        :
                                        <h2 className='text-center gradientTxt'><span>Provide Custom Materials</span></h2>
                                    }

                                    <div className='quiz-card mt-5'>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='gradient-box p-0 no-bg'>
                                                    {state.contentType === "course" ?
                                                        <>
                                                            <div className='gradient-box-head'>
                                                                <p style={{ color: '#FFF', textTransform: "capitalize" }}>All Your Courses</p>
                                                                <p style={{ fontSize: '13px' }}>Select The <span className='text-capitalize'>Course</span> you would like AI to Identify key highlights from</p>
                                                            </div>
                                                            <div className='gradient-box-in'>
                                                                <div className='question-type'>
                                                                    <ul>
                                                                        {courses.length > 0 ?
                                                                            courses.map((curElem, index) => {
                                                                                return (
                                                                                    <li key={index}>
                                                                                        <div className='question-type-single'>
                                                                                            <div className='d-flex'>
                                                                                                <div className='col-auto'>
                                                                                                    <div className='radio-style'></div>
                                                                                                </div>
                                                                                                <div className='col'>
                                                                                                    <h6>{curElem.projectName}</h6>
                                                                                                </div>
                                                                                            </div>
                                                                                            <input
                                                                                                type="radio"
                                                                                                name='cid'
                                                                                                checked={+curElem.id === +state.cid}
                                                                                                value={curElem.id}
                                                                                                onChange={handleChange}
                                                                                            />
                                                                                        </div>
                                                                                    </li>)
                                                                            }) :
                                                                            <li className='text-center' style={{ width: "100%" }}>
                                                                                {loader.fetch ?
                                                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: themeColor }} />
                                                                                    : "You do not have courses created!"}
                                                                            </li>

                                                                        }

                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='row'>
                                                                <div className='col-12 p-3'>
                                                                    <label>Topic Name</label>
                                                                    <input
                                                                        type='text'
                                                                        className='input'
                                                                        name='keyword'
                                                                        placeholder='Enter topic name'
                                                                        value={state.keyword}
                                                                        onChange={handleChange}
                                                                        required
                                                                    />
                                                                </div>
                                                                <div className='col-6 p-3'>
                                                                    <label>Country</label>
                                                                    <select
                                                                        className='input'
                                                                        name='country'
                                                                        value={state.country}
                                                                        onChange={handleChange}
                                                                        required
                                                                    >
                                                                        <option value={""}>Select Country</option>
                                                                        {country.length > 0 ?
                                                                            country.map((curElem, index) => {
                                                                                return (
                                                                                    <option key={index} value={curElem.country_name}>{curElem.country_name}</option>
                                                                                )
                                                                            })
                                                                            : ""}
                                                                    </select>

                                                                </div>
                                                                <div className='col-6 p-3'>
                                                                    <label>Language</label>
                                                                    <select
                                                                        className='input'
                                                                        name='language'
                                                                        value={state.language}
                                                                        onChange={handleChange}
                                                                    >
                                                                        {language.length > 0 ?
                                                                            language.map((curElem, index) => {
                                                                                return (
                                                                                    <option key={index} value={curElem.name}>{curElem.name}</option>
                                                                                )
                                                                            })
                                                                            : ""}
                                                                    </select>

                                                                </div>
                                                                {/* <div className='col-6 p-3'>
                                                                <label>Plateform</label>
                                                                <select
                                                                    className='input'
                                                                    name='dimension'
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value={"1920x1080"}>{"Standard YouTube Videos"}</option>
                                                                    {platform ?
                                                                        <>
                                                                            <Platform
                                                                                data={platform.Facebook}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Instagram}
                                                                            />
                                                                            <Platform
                                                                                data={platform.LinkedIn}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Pinterest}
                                                                            />
                                                                            <Platform
                                                                                data={platform.Twitter}
                                                                            />

                                                                        </>
                                                                        : ""}
                                                                </select>

                                                            </div> */}
                                                                <div className='col-6 p-3'>
                                                                    <label>Style</label>
                                                                    <select
                                                                        className='input'
                                                                        onChange={handleChange}
                                                                        name='style'
                                                                        // required
                                                                        value={state.style}
                                                                    >
                                                                        {/* <option value={""}>Select Style</option> */}
                                                                        <option value={"controversial"}>Controversial</option>
                                                                        <option value={"engaging"}>Engaging</option>
                                                                        <option value={"fun"}>Fun</option>
                                                                        <option value={"informative"}>Informative</option>
                                                                        <option value={"news"}>News</option>
                                                                        <option value={"promo"}>Promo</option>
                                                                        <option value={"Product review"}>Product review</option>
                                                                        <option value={"VSL"}>VSL</option>

                                                                    </select>

                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>


                </section>

                <Footer />
            </>
    )
}

export default ContentExtraction;