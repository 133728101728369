import React from "react";
import imac from "../../images/IMAC.png"
import mac from "../../images/comp-1.png"
import mac2 from "../../images/comp-2.png"
import mac3 from "../../images/comp-3.png"
import mac4 from "../../images/comp-4.png"
import { useSelector } from "react-redux";
import { appName } from "../../Global/Global";


const DashboardAbout = () => {
    const rebrand = useSelector(state => state.rebrand.data)
    return (
        <>
            <section className="featureSec">
                <div className="container">
                    <div className="featureSec-wrap">
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 order-1">
                                    <div className="featureSec-left">
                                        {/* <h6>GET STARTED WITHOUT A PLAN</h6> */}
                                        <h2>AI-Driven Personalization for Course Videos:</h2>
                                        <p>Create course videos like never before with your own face as the talking head and your own voice as the voiceover. Engage your audience with a personal touch that builds trust and keeps them coming back for more.</p>
                                        {/* <div className="textL">See how you can use {rebrand? rebrand.name : appName } to <span>create your first course</span></div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 order-2">
                                    <div className="featureSec-right"><img src={mac} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-left">
                                        {/* <h6>Polish your videos effortlessly</h6> */}
                                        <h2>Interactive Quizzes and Ebook Integration:</h2>
                                        <p>Enhance learning with web-style interactive quizzes built directly into your courses. Convert your course content into shareable ebook formats for expanded reach and added value to your audience.</p>
                                        {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-right"><img src={mac2} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6 order-1">
                                    <div className="featureSec-left">
                                        {/* <h6>TAKE A BACKSEAT</h6> */}
                                        <h2>Automated Marketing Assets:</h2>
                                        {/* <p>With {rebrand? rebrand.name : appName } Professional, share access with teams to create videos and courses for you. Provide reviews and comments to ensure everyone is on the same page.</p> */}
                                        <p>Effortlessly market your courses with ready-made sales and landing pages crafted to convert. Focus on teaching while {rebrand? rebrand.name : appName } handles the heavy lifting of promotion and sales.</p>
                                        {/* <div className="textL">Find out how the <span>Team Members Access</span> feature works</div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 order-2">
                                    <div className="featureSec-right"><img src={mac3} /></div>
                                </div>
                            </div>
                        </div>
                        <div className="featureSec-single">
                            <div className="row align-items-center">
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-left">
                                        {/* <h6>Polish your videos effortlessly</h6> */}
                                        <h2>New Trinity AI for Human-Like Content:</h2>
                                        <p>Leverage our proprietary Trinity AI for creating human-like, engaging course content. With unmatched precision and realism, this advanced technology ensures your courses are truly one-of-a-kind in the e-learning market.</p>
                                        {/* <div className="textL">Watch our <span>video editing tutorial</span> on how to save time while editing your videos</div> */}
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="featureSec-right"><img src={mac4} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DashboardAbout;