import React, { useEffect, useState } from 'react'
import TitleBar from '../CommonComponents/TitleBar';
import Navbar from '../CommonComponents/Navs/Navbar';
import SideNav from '../CommonComponents/Navs/SideNav';
import Footer from '../CommonComponents/Footer/Footer';
import queryString from 'query-string';
import { Link, useLocation, useNavigate } from 'react-router';
import { fetchData } from '../../Redux/Actions/CommonActions';
import { useDispatch } from 'react-redux';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import { FaSpinner } from 'react-icons/fa';
import { setAlert } from '../../Redux/Actions/AlertActions';
import { onUpdateLandingPage, onUpdateTemplate } from '../../Redux/Actions/LandingActions';
import { themeColor } from '../../Global/Global';


const GenerateLandingPage = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation();
    const { id } = queryString.parse(location.search)

    const [landingPageData, setLandingPageData] = useState(false)
    const [state, setState] = useState({
        contentType: "course"
    })
    const [loader, setLoader] = useState({
        next: false
    })
    const [mainLoader, setMainLoader] = useState({
        fetch: true
    })

    const fetchLandingPage = () => {
        let data = {
            id: id
        }
        dispatch(fetchData("fetch-landing-page", data, setLandingPageData, mainLoader, setMainLoader))
    }

    const handleChange = (type) => {
        setState({
            ...state,
            contentType: type
        })
    };


    const handleSubmit = () => {
        let data = {
            ...landingPageData[0],
            id: id,
            contentType: state.contentType,
            method: "update"
        }

        setLoader({
            ...loader,
            next: true
        })
        dispatch(onUpdateLandingPage(data, navigate, loader, setLoader, `/landing-page/content-extraction?id=${id}`))
    }

    useEffect(() => {
        fetchLandingPage()
    }, [])

    useEffect(() => {
        setState({
            ...state,
            contentType: landingPageData[0]?.contentType || "course"
        })
    }, [landingPageData[0]?.contentType])

    return (
        mainLoader.fetch ?
            <div className="loader-sec" >
                <div className="loader">
                </div>
            </div>
            :
            <>
                <TitleBar title="Landing Page" />
                <Navbar />
                <section className="siteWrap">

                    <div className="cont_wrap">
                        <div className="side_bar">
                            <SideNav />
                        </div>
                        <div className="cont_area">

                            <div className="projectTitle-wrap">
                                <div className="projectTitle row">
                                    <div className="col-auto">
                                        <h2 className='pageTitle-2'>{landingPageData[0]?.name}</h2>
                                    </div>

                                    <div className="col-auto">
                                        <div className="projectTitle-right">
                                            <button class="demoLink grey" onClick={() => navigate("/landing-page")}><span><BsArrowLeft /> Back</span></button>
                                            <button class="demoLink" onClick={handleSubmit}><span>{loader.next ? <> Next <i className="fa fa-spinner fa-spin mr-2" /></> : <> Next <BsArrowRight /></>}</span></button>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="container">
                                <div className='quiz-wrap'>
                                    <h2 className='text-center gradientTxt mt-4'><span>Choose How You Want To Generate Your Landing Page</span></h2>
                                    <h6 className='text-center pt-2'>👇 Select how you want to Generate your Landing Page 👇</h6>

                                    <div className='choose-landing mt-5'>
                                        <span onClick={() => { handleChange("course") }} style={{ cursor: "pointer" }}>
                                            <div className='landing-single' style={{ border: `${state.contentType === "course" ? "0.0625rem solid #AF00FF" : ""}` }}>
                                                <div className='landing-img'><img src={require('../../images/dashboard/img-1.png')} alt='' /></div>
                                                <div className='landing-info'>
                                                    <h5 className='gradientTxt'><span>Content Extraction</span></h5>
                                                    <p className='pt-2'>The AI analyzes course data to extract key elements for the landing page.</p>
                                                </div>
                                            </div>
                                        </span>
                                        <span onClick={() => { handleChange("custom") }} style={{ cursor: "pointer" }} >
                                            <div className='landing-single' style={{ border: `${state.contentType === "custom" ? "0.0625rem solid #AF00FF" : ""}` }}>
                                                <div className='landing-img'><img src={require('../../images/dashboard/img-2.png')} alt='' /></div>
                                                <div className='landing-info'>
                                                    <h5 className='gradientTxt'><span>Pre-filled Sections</span></h5>
                                                    <p className='pt-2'>The Landing Page Builder automatically fills in relevant sections.</p>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                </div>
                            </div>


                        </div>
                    </div>


                </section>

                <Footer />

            </>
    )
}

export default GenerateLandingPage;