import React, { useEffect, useState } from 'react'
import { Modal, Nav, Tab } from 'react-bootstrap'
import { BsCheckCircle } from 'react-icons/bs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch } from 'react-redux';
import UploadImage from './UploadImage';
import { onfetchImages } from '../../../../../Redux/Actions/ProjectActions';
import { AiOutlineClose } from 'react-icons/ai';

import modalCloseIcon from '../../../../../images/modal-close.png';
import AiImageModal from './AiImageModal';

const ImagesModal = (props) => {
    const dispatch = useDispatch()
    const [imageList, setImageList] = useState([])
    const [selected, setSelected] = useState({
        data: {},
        index: -1
    })
    const [msg, setMsg] = useState("")
    const [image, setImage] = useState({
        keyword: "",
        page_number: 1,
        search_type: "image"
    })
    const [loader, setLoader] = useState({
        saveLoader: false,
        fetchLoader: false
    })

    const handleSearch = (val) => {
        setImage({
            ...image,
            keyword: val
        })
        if (imageList.length > 0) {
            setImageList([])
        }
        if (msg !== "") {
            setMsg("")
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        fetchImages()
    }

    const fetchImages = (page = 1) => {
        let data = { ...image }
        data.page_number = page
        setLoader({
            ...loader,
            fetchLoader: true
        })
        setImage({
            ...image,
            page_number: page,
        })
        dispatch(onfetchImages(data, imageList, setImageList, setMsg, loader, setLoader))
    }

    const onChooseImage = (curElem, index) => {
        setSelected({
            ...selected,
            data: curElem,
            index: index
        })
    }

    const handleSave = () => {
        setLoader({
            ...loader,
            saveLoader: true
        })
        setTimeout(() => {
            if (props.iconIndex !== undefined) {
                props.callbackFun(selected.data, props.iconIndex)
            } else {
                props.callbackFun(selected.data)
            }
            props.handleClose()
            setLoader({
                ...loader,
                saveLoader: false
            })
        }, 500);

    }

    useEffect(() => {
        return () => {
            setImageList([])
            setImage({
                ...image,
                keyword: "",
                page_number: 1,
                search_type: "image"
            })
        }
    }, [props.handleClose])

    return (
        <Modal className="theme-modal VideoModal xl" show={props.show} centered>
            {/* <div className='modalClose' onClick={props.handleClose}><AiOutlineClose /></div> */}
            <div onClick={props.handleClose} className="vidClose"><img src={modalCloseIcon} /></div>
            <Modal.Body>
                <div className="imgUp">
                    <Tab.Container defaultActiveKey="first">
                        <Nav variant="h">
                            <Nav.Item>
                                <Nav.Link eventKey="first">Stock images</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="second">Upload</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="third">AI images</Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className='pt-4'>
                            <Tab.Pane eventKey="first">
                                <div className="imgUp-main">

                                    <div className='project-input-box'>
                                        <div className='project-input-in'>
                                            <form className="img-search" onSubmit={handleSubmit}>
                                                <input
                                                    type="text"
                                                    className='inp'
                                                    placeholder="Search Image"
                                                    value={image.keyword}
                                                    onChange={(e) => handleSearch(e.target.value)}
                                                />
                                            </form>
                                        </div>
                                    </div>

                                    <InfiniteScroll
                                        dataLength={imageList.length} //This is important field to render the next data
                                        next={() => fetchImages(image.page_number + 1)}
                                        hasMore={true}
                                        scrollableTarget={`scrollableImageModal`}
                                    >
                                        <div className="img-search-scroll" style={{ minHeight: "350px", width: "100%" }} id='scrollableImageModal'>
                                            <ul >
                                                {imageList.length > 0 ?
                                                    imageList.map((curElem, index) => {
                                                        return (
                                                            <li key={index}>
                                                                <div
                                                                    className="photo-single"
                                                                    style={{ border: selected.index === index ? "2px solid #AF00FF" : "", cursor: "pointer" }}
                                                                    onClick={() => onChooseImage(curElem, index)}
                                                                >
                                                                    <img src={curElem.thumbnail} alt={` ${image.keyword} Image ${index}`} />
                                                                    {selected.index === index ?
                                                                        <div className="imgStat"><span><BsCheckCircle /></span></div>
                                                                        :
                                                                        ""
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    : <div className='text-center pt-3' style={{ margin: "auto" }}>
                                                        {msg}
                                                    </div>}
                                            </ul>
                                            {loader.fetchLoader ?
                                                <div className='text-center mt-3'>
                                                    <i className="fa fa-spinner fa-spin" style={{ fontSize: "30px", color: "#3f429b" }} />
                                                </div>
                                                : ""
                                            }

                                        </div>
                                    </InfiniteScroll>

                                </div>
                                <div className="d-flex align-items-center justify-content-end gap-2 pt-4">
                                    <button className="demoLink m-0 grey" onClick={props.handleClose}><span>Cancel</span></button>
                                    <button className={`demoLink m-0 ${selected.index === -1 ? "grey" : ""}`} disabled={selected.index === -1} onClick={handleSave}><span> {loader.saveLoader ? <>Using <i className="fa fa-spinner fa-spin mx-1" /></> : "Use"}</span></button>
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                                <UploadImage
                                    handleClose={props.handleClose}
                                    callbackFun={props.callbackFun}
                                    iconIndex={props.iconIndex}
                                    aId={props.aId}
                                    cId={props.cId}
                                />
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                                <AiImageModal
                                    handleClose={props.handleClose}
                                    callbackFun={props.callbackFun}
                                    iconIndex={props.iconIndex}
                                />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default ImagesModal